import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import Helmet from "react-helmet";

import classes from "./Replica.module.scss";

import Counter from "../../components/Replica/Public/Counter/Counter";
import DeleteReplic from "../../components/Replica/Public/DeleteReplic/DeleteReplic";
import Filter from "../../components/Replica/Public/Filter/Filter";
import ReplicaList from "../../components/Replica/Public/ReplicaList/ReplicaList";
import ReplicaTabs from "../../components/Replica/Public/ReplicaTabs/ReplicaTabs";
import Search from "../../components/Replica/Public/Search/Search";
import ConfirmationAction from "../../components/Replica/Public/TopReplicSelector/ConfirmationAction/ConfirmationAction";
import TopReplicSelector from "../../components/Replica/Public/TopReplicSelector/TopReplicSelector";
import Loading from "../../components/UI/Loading/Loading";
import Pagination from "../../components/UI/Pagination/Pagination";
import PaginationSettings from "../../components/UI/PaginationSettings/PaginationSettings";
import config from "../../config/config";
import { PopupContext } from "../../context/popup/popupContext";

const COUNT_DEFAULT_ITEM = 20;

const Replica = () => {
  const stgDefaultItem = localStorage.getItem("replica-view");
  const countDefaultItem = stgDefaultItem ? +stgDefaultItem : COUNT_DEFAULT_ITEM;

  // const history = useHistory()
  const popupContextData = useContext(PopupContext);
  const [message, setMessage] = useState("");
  const [isShowRejectModal, setIsShowRejectModal] = useState(false);
  const [dataRejects, setDataRejects] = useState([]);

  const sortNameDefault = "date";
  const sortDirectionDefault = "desc";

  const [sort, setSort] = useState({
    name: localStorage.getItem("replica-sort-name")
      ? localStorage.getItem("replica-sort-name")
      : sortNameDefault,
    direction: localStorage.getItem("replica-sort-direction")
      ? localStorage.getItem("replica-sort-direction")
      : sortDirectionDefault,
  });

  const initialState = {
    company: {
      name: "company",
      value: localStorage.getItem("filter-replica-company")
        ? localStorage.getItem("filter-replica-company")
        : "",
      options: [],
      optionDefault: "Компания",
      disabled: false,
    },
    project: {
      name: "project",
      value: localStorage.getItem("filter-replica-project")
        ? localStorage.getItem("filter-replica-project")
        : "",
      options: [],
      optionDefault: "Проект",
      disabled: false,
    },
    category: {
      name: "category",
      value: localStorage.getItem("filter-replica-category")
        ? localStorage.getItem("filter-replica-category")
        : "",
      options: [],
      optionDefault: "Категория",
    },
    platform: {
      name: "platform",
      value: localStorage.getItem("filter-replica-platform")
        ? localStorage.getItem("filter-replica-platform")
        : "",
      options: [],
      optionDefault: "Площадка",
    },
    status: {
      name: "status",
      value: localStorage.getItem("filter-replica-status")
        ? localStorage.getItem("filter-replica-status")
        : "",
      options: [],
      optionDefault: "Статус",
    },
    dateFrom: {
      type: "text",
      name: "dateFrom",
      value: localStorage.getItem("filter-replica-dateFrom")
        ? localStorage.getItem("filter-replica-dateFrom")
        : "",
      placeholder: "__.__.____",
      label: "Дата",
    },
    dateTo: {
      type: "text",
      name: "dateTo",
      value: localStorage.getItem("filter-replica-dateTo")
        ? localStorage.getItem("filter-replica-dateTo")
        : "",
      placeholder: "__.__.____",
      label: "Дата",
    },
    search: {
      type: "text",
      name: "search",
      value: localStorage.getItem("filter-replica-search")
        ? localStorage.getItem("filter-replica-search")
        : "",
      placeholder: "Введите запрос и нажмите Enter",
      label: "Поиск",
    },
    filterByDateChangeStatus: {
      name: "filterByDateChangeStatus",
      value: localStorage.getItem("f-status-change"),
    },
  };

  const [filter, setFilter] = useState(initialState);
  const accessLevel = parseInt(localStorage.getItem("accessLevel"));
  const [replica, setReplica] = useState({});
  const [replicaOptions, setReplicaOptions] = useState({});
  const [replicaPage, setReplicaPage] = useState(1);
  const [replicaType, setReplicaType] = useState(
    localStorage.getItem("replica-type") &&
      (localStorage.getItem("replica-type") === "1" || localStorage.getItem("replica-type") === "2")
      ? parseInt(localStorage.getItem("replica-type"))
      : null,
  );
  const [replicaChildrenSelect, setReplicaChildrenSelect] = useState({});
  const [replicaChildrenUpdate, setReplicaChildrenUpdate] = useState(false);
  const [tabsShow, setTabsShow] = useState(true);
  const [blackout, setBlackOut] = useState(false);
  const [replicaChildrenOpenForBlackout, setReplicaChildrenOpenForBlackout] = useState(false);
  const [arraySelectedItems, setArraySelectedItems] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [telegram, setTelegram] = useState(null);
  const [selectedReplicaForSavePopup, setSelectedReplicaForSavePopup] = useState(null);

  const replicaDataDefault = {
    replicaCheckArrayCount: { checkStatus: false, statusArray: [] },
    deleteValue: false,
    rejectValue: false,
    approveValue: false,
    confirm: false,
    btnActive: {
      deleteActive: false,
      rejectActive: false,
      approveActive: false,
    },
    msgApprove: "",
    msgReject: "",
    buttons: {
      approve: true,
      reject: true,
      delete: true,
    },
    openSettings: false,
  };

  const onChangeSelectedReplicaForSavePopup = (obj) => {
    setSelectedReplicaForSavePopup(obj);
  };

  //переменная для хранения статусов выбранных реплик
  const [replicaData, setReplicaData] = useState(replicaDataDefault);
  const [isShowResetDate, setIsShowResetDate] = useState(
    localStorage.getItem("filter-replica-dateFrom") !== "" ||
      localStorage.getItem("filter-replica-dateTo") !== "",
  );

  // Выбираем реплики
  const getReplica = async (page = 1, savePage = false, type = null) => {
    try {
      popupContextData.setPreloader(true);

      const filterData = [];

      // Кол-ва элементов на странице
      filterData.push(`view=${countDefaultItem}`);

      // Название сортировки
      filterData.push(
        `sortName=${localStorage.getItem("replica-sort-name") ? localStorage.getItem("replica-sort-name") : sortNameDefault}`,
      );

      // Направление сортировки
      filterData.push(
        `sortDirection=${
          localStorage.getItem("replica-sort-direction")
            ? localStorage.getItem("replica-sort-direction")
            : sortDirectionDefault
        }`,
      );

      //filterByDateChangeStatus
      // f-status-change

      if (filter.filterByDateChangeStatus.value) {
        filterData.push(`f-status-change=Y`);
      }

      setIsReplicaChange(false);

      if (savePage && page && replicaPage && replicaPage > page) {
        // filterData.push(`pageStart=${replicaPage}`)
        page = replicaPage;
        setReplicaPage(replicaPage);
      } else {
        setReplicaPage(page);
      }

      filterData.push(`type=${type}`);

      Object.keys(filter).map((filterId) => {
        const filterItem = filter[filterId];

        if (filterItem.name && filterItem.value) {
          return filterData.push(`f-${filterItem.name}=${filterItem.value}`);
        } else {
          return true;
        }
      });

      const token = localStorage.getItem("token");

      const response = await axios.get(
        `${config.SERVER_BASE_URL}/replica?token=${token}&page=${page && parseInt(page) ? parseInt(page) : 1}${
          filterData.length ? "&" + filterData.join("&") : ""
        }`,
      );

      const responseData = response.data;

      const replicaData = {};
      const filterCopy = { ...filter };

      filterCopy.platform.options = [];

      if (responseData.data.length) {
        responseData.data.map((replica) => {
          replica.checked = false;
          return (replicaData[replica._id.toString()] = replica);
        });
        setReplica(replicaData);
      } else {
        setReplica([]);
      }

      console.log("responseData.meta", responseData.meta);

      if (responseData.meta) {
        setReplicaOptions(responseData.meta);
        responseData.meta.forPlatform.map((platform) => {
          return filterCopy.platform.options.push({
            id: platform.id,
            name: platform.id,
            count: platform.count,
          });
        });
      } else {
        setReplicaOptions({});
      }

      // Проверяем активность чекбокса "выбрать все реплики на странице"
      const contains = () => {
        for (let i = 0; i < Object.keys(replicaData).length; i++) {
          if (!arraySelectedItems.find((item) => item.id === Object.keys(replicaData)[i])) {
            return false;
          } else {
            return true;
          }
        }
      };

      const isSelected = contains();

      if (!isSelected) {
        setIsAllSelected(false);
      } else {
        setIsAllSelected(true);
      }

      setFilter(filterCopy);
      setTelegram(response?.data?.telegram);
    } catch (error) {
      console.error(`Error: ${error?.message || error.toString()}`);
    } finally {
      popupContextData.setPreloader(false);
    }
  };

  const getFilter = async () => {
    try {
      popupContextData.setPreloader(true);
      const token = localStorage.getItem("token");
      const filterCopy = { ...filter };

      const filterData = [];

      // const dateFrom = !!filter.dateFrom.value ? `&f-dateFrom=${filter.dateFrom.value}` : ''
      // const dateTo = !!filter.dateTo.value ? `&f-dateTo=${filter.dateTo.value}` : ''

      Object.keys(filter).map((filterId) => {
        const filterItem = filter[filterId];

        if (filterItem.name && filterItem.value) {
          return filterData.push(`f-${filterItem.name}=${filterItem.value}`);
        } else {
          return true;
        }
      });

      // popupContextData.setPreloader(true)

      const rptype = replicaType ? `&type=${replicaType}` : ``;
      const statusChange =
        filter.filterByDateChangeStatus.value === "true" ||
        filter.filterByDateChangeStatus.value === true
          ? `&f-status-change=Y`
          : ``;

      if (accessLevel === 2) {
        filterCopy.project.options = await getDataForFilter(
          `${config.SERVER_BASE_URL}/replica/projects?token=${token}${statusChange}${rptype}${
            filterData.length ? "&" + filterData.join("&") : ""
          }`,
        );
      } else {
        const response = await getDataForFilter(
          `${config.SERVER_BASE_URL}/replica/companies?token=${token}${statusChange}${rptype}${
            filterData.length ? "&" + filterData.join("&") : ""
          }`,
        );
        filterCopy.company.options = response;
        if (localStorage.getItem("filter-replica-company")) {
          const dateFromValue = localStorage.getItem("filter-replica-dateFrom");
          const dateToValue = localStorage.getItem("filter-replica-dateTo");

          const dateFrom = dateFromValue ? `&f-dateFrom=${dateFromValue}` : "";
          const dateTo = dateToValue ? `&f-dateTo=${dateToValue}` : "";
          const status = filter.status.value ? `&f-status=${filter.status.value}` : ``;
          const category = filter.category.value ? `&f-category=${filter.category.value}` : ``;
          const platform = filter.platform.value ? `&f-platform=${filter.platform.value}` : ``;
          const search = filter.search.value ? `&f-search=${filter.search.value}` : "";

          filterCopy.project.options = await getDataForFilter(
            `${config.SERVER_BASE_URL}/replica/projects?token=${token}${platform}${statusChange}${rptype}${dateFrom}${dateTo}${status}${category}${search}&companyId=${localStorage.getItem(
              "filter-replica-company",
            )}`,
          );
        }
      }
      const type = replicaType ? `&type=${replicaType}` : ``;

      filterCopy.category.options = await getDataForFilter(
        `${config.SERVER_BASE_URL}/categories?token=${token}${statusChange}${type}${
          filterData.length ? "&" + filterData.join("&") : ""
        }`,
      );

      filterCopy.status.options = await getDataForFilter(
        `${config.SERVER_BASE_URL}/statuses?token=${token}${statusChange}${type}${
          filterData.length ? "&" + filterData.join("&") : ""
        }`,
      );

      setFilter(filterCopy);
    } catch (e) {
      console.error(`Error getFilter: ${e}`);
    }
  };

  const getDataForFilter = async (url) => {
    const data = [];
    const response = await axios.get(url);
    const responseData = response.data;

    if (responseData.status === "success" && responseData.data.length) {
      Object.keys(responseData.data).map((idx) => {
        return data.push({
          id: responseData.data[idx].id,
          name: responseData.data[idx].name,
          count: responseData.data[idx].count,
        });
      });
    }

    return data;
  };

  const replicaSelect = (event, id, status = {}) => {
    const replicaCopy = { ...replica };

    if (!replicaCopy[id]) {
      const replicaChildrenSelectCopy = { ...replicaChildrenSelect };

      if (replicaChildrenSelectCopy[id]) {
        replicaChildrenSelectCopy[id].checked = !replicaChildrenSelectCopy[id].checked;
      } else {
        replicaChildrenSelectCopy[id] = {
          id: id,
          checked: true,
          status: status,
        };
      }

      setReplicaChildrenSelect(replicaChildrenSelectCopy);

      const newItem = arraySelectedItems.find((item) => item.id === id);

      if (!newItem) {
        setArraySelectedItems((prev) => [...prev, { id, status }]);
      } else {
        const newArr = arraySelectedItems.filter((item) => item.id !== id);
        setArraySelectedItems(newArr);
      }

      replicaCheckStatus(false, replicaChildrenSelectCopy[id]);
    } else {
      replicaCopy[id].checked = event.target.checked;
      setReplica(replicaCopy);

      const newItem = arraySelectedItems.find((item) => item.id === id);

      if (!newItem) {
        setArraySelectedItems((prev) => [...prev, { id, status }]);
      } else {
        const newArr = arraySelectedItems.filter((item) => item.id !== id);
        setArraySelectedItems(newArr);
      }

      replicaCheckStatus();
    }
  };

  const replicaCheckStatus = (hide = false, data = {}) => {
    const replicaDataCopy = { ...replicaData };
    const statuses = {};
    const accessLevel = localStorage.getItem("accessLevel");
    const dataFinal = {};

    replicaDataCopy.replicaCheckArrayCount.statusArray = [];
    replicaDataCopy.replicaCheckArrayCount.checkStatus = false;
    replicaDataCopy.buttons.approve = false;
    replicaDataCopy.buttons.reject = false;
    replicaDataCopy.buttons.delete = false;

    if (!hide) {
      if (replica && Object.keys(replica).length) {
        for (const id of Object.keys(replica)) {
          if (replica[id].checked) {
            dataFinal[id] = replica[id].status.name;
          }
        }
      }

      if (replicaChildrenSelect && Object.keys(replicaChildrenSelect).length) {
        for (const id of Object.keys(replicaChildrenSelect)) {
          if (replicaChildrenSelect[id].checked) {
            dataFinal[id] = replicaChildrenSelect[id].status.name;
          }
        }
      }

      if (data && data.checked) {
        dataFinal[data.id] = data.status.name;
      }

      if (dataFinal && Object.keys(dataFinal).length) {
        for (const id of Object.keys(dataFinal)) {
          replicaDataCopy.replicaCheckArrayCount.statusArray.push([id, dataFinal[id]]);
        }
      }

      if (replicaDataCopy.replicaCheckArrayCount.statusArray.length) {
        for (const data of replicaDataCopy.replicaCheckArrayCount.statusArray) {
          statuses[data[1]] = true;
        }

        if (statuses && Object.keys(statuses).length === 1) {
          for (const status of Object.keys(statuses)) {
            if (accessLevel === "1") {
              replicaDataCopy.buttons.delete = true;
              replicaDataCopy.replicaCheckArrayCount.checkStatus = true;
            }

            if (status === "Ожидает согласования") {
              replicaDataCopy.buttons.approve = true;
              replicaDataCopy.buttons.reject = true;
              replicaDataCopy.replicaCheckArrayCount.checkStatus = true;
            }
          }
        }
      }
    }

    setReplicaData(replicaDataCopy);
  };

  const exportReplicaList = async (typeDownload) => {
    const type = typeDownload.value;
    const token = localStorage.getItem("token");
    const url = `${config.SERVER_BASE_URL}/replica/export-v3?token=${token}`;

    if (type === "filter") {
      const params = [`type=filter`];

      if (filter.company.value) {
        params.push(`company=${filter.company.value}`);
      }

      if (filter.project.value) {
        params.push(`project=${filter.project.value}`);
      }

      if (filter.category.value) {
        params.push(`category=${filter.category.value}`);
      }

      if (filter.platform.value) {
        params.push(`platform=${filter.platform.value}`);
      }

      if (filter.dateFrom.value) {
        params.push(`dateFrom=${filter.dateFrom.value}`);
      }

      if (filter.dateTo.value) {
        params.push(`dateTo=${filter.dateTo.value}`);
      }

      if (filter.status.value) {
        params.push(`status=${filter.status.value}`);
      }

      if (filter.search.value) {
        params.push(`search=${filter.search.value}`);
      }

      if (replicaType) {
        params.push(`typeId=${replicaType}`);
      }

      if (
        filter.filterByDateChangeStatus.value === "true" ||
        filter.filterByDateChangeStatus.value === true
      ) {
        params.push(`f-status-change=Y`);
      }

      window.open(`${url}&${params.join("&")}`);
    } else if (type === "project" && filter.project.value) {
      window.open(`${url}&type=project&projectId=${filter.project.value}`);
    } else if (type === "select" && arraySelectedItems && arraySelectedItems.length > 0) {
      const replicaIdx = arraySelectedItems.map((item) => item.id);

      if (replicaIdx && replicaIdx.length) {
        window.open(`${url}&type=select&replica=${replicaIdx.join(";")}`);
      }
    } else if (type === "company" && filter.company.value) {
      window.open(`${url}&type=company&companyId=${filter.company.value}`);
    }
  };

  const onChangeStatusReplica = (newStatus, prevStatus) => {
    const options = filter?.status.options.map((item) => {
      if (item.id === newStatus) {
        return { ...item, count: +item.count + 1 };
      }
      if (item.id === prevStatus) {
        return { ...item, count: +item.count > 0 ? +item.count - 1 : 0 };
      }
      return item;
    });

    const status = { ...filter?.status, options };

    const allstatus = {
      ...replicaOptions.counters.status,
      [prevStatus]: +replicaOptions.counters?.status[prevStatus] - 1,
      [newStatus]: +replicaOptions.counters?.status[newStatus] + 1,
    };

    const newData = {
      ...replicaOptions,
      ...{ counters: { status: allstatus } },
    };

    setReplicaOptions(newData);
    setFilter({ ...filter, status });
  };

  const filterSelectChange = async (value, name) => {
    try {
      popupContextData.setPreloader(true);

      setArraySelectedItems([]);

      if (["dateTo", "dateFrom"].includes(name)) setIsShowResetDate(true);

      setFilter((prev) => {
        const news = { ...prev };
        news[name].value = value;
        return news;
      });

      const filterCopy = { ...filter };
      filterCopy[name].value = value;

      if (name === "company") {
        const token = localStorage.getItem("token");

        filterCopy.project.value = "";
        localStorage.removeItem("filter-replica-project");

        // filterCopy.category.value = ''
        // localStorage.removeItem('filter-replica-category')
        //
        // filterCopy.platform.value = ''
        // localStorage.removeItem('filter-replica-platform')
        //
        // filterCopy.status.value = ''
        // localStorage.removeItem('filter-replica-status')
        //
        // filterCopy.search.value = ''
        // localStorage.removeItem('filter-replica-search')

        filterCopy.project.options = await getDataForFilter(
          `${config.SERVER_BASE_URL}/replica/projects?token=${token}&companyId=${value}`,
        );
      }

      if (name === "filterByDateChangeStatus") {
        if (value) {
          localStorage.setItem("f-status-change", true);
          filterCopy.filterByDateChangeStatus.value = true;
        } else {
          localStorage.removeItem("f-status-change");
          filterCopy.filterByDateChangeStatus.value = false;
        }
      }

      setFilter(filterCopy);

      if (value) {
        localStorage.setItem("filter-replica-" + name, value);
      } else {
        localStorage.removeItem("filter-replica-" + name);
      }
      await getFilter();

      await getReplica(1, false, replicaType);
    } catch (e) {
      console.error("filter select change", e);
    } finally {
      popupContextData.setPreloader(false);
    }
  };

  const onResetDate = async (dateFrom, dateTo) => {
    setArraySelectedItems([]);
    setIsShowResetDate(false);
    const news = { ...filter };
    news[dateFrom].value = "";
    news[dateTo].value = "";

    setFilter(news);

    localStorage.removeItem("filter-replica-" + dateFrom);
    localStorage.removeItem("filter-replica-" + dateTo);
    await getReplica(1, false, replicaType);
    await getFilter();
    popupContextData.setPreloader(false);
  };

  const defaultFilter = async () => {
    try {
      popupContextData.setPreloader(true);
      const filterCopy = { ...filter };

      Object.keys(filterCopy).map((item) => {
        localStorage.removeItem("filter-replica-" + filterCopy[item].name);
        return (filterCopy[item].value = "");
      });

      filterCopy.project.options = [];

      setFilter(filterCopy);
      await getFilter();
      await getReplica(1, false, replicaType);
      setIsShowResetDate(false);
      localStorage.setItem("replica-type", String(replicaType));
      setReplicaChildrenUpdate(!replicaChildrenUpdate);
      clearReplicaChildrenSelect();
      replicaCheckStatus(true);
    } catch (e) {
      console.log(e);
    }
  };

  const isButtonDefaultExists = (filterState) => {
    const result = Object.keys(filterState).filter((item) => filterState[item].value !== "");

    // return result.length ? true : false
    return !!result.length;
  };

  const addCheckboxAllReplica = () => {
    const replicaCopy = { ...replica };

    let checked = false;

    Object.keys(replicaCopy).map((replicaId) => {
      if (arraySelectedItems.find((item) => item.id === replicaId)) {
        checked = true;
      }
      return true;
    });

    Object.keys(replicaCopy).map((replicaId) => {
      if (!checked) {
        return setArraySelectedItems((prev) => [
          ...prev,
          {
            id: replicaId,
            status: replicaCopy[replicaId].status,
          },
        ]);
      }

      return setArraySelectedItems((prev) => [...prev.filter((item) => item.id !== replicaId)]);
    });

    if (checked) {
      setIsAllSelected(false);
    } else {
      setIsAllSelected(true);
    }

    replicaCheckStatus();
    setReplica(replicaCopy);
  };

  // Функция для вызова плашки удаления реплик
  const clickDelete = () => {
    let replicaDataCopy = { ...replicaData };
    replicaDataCopy.deleteValue
      ? (replicaDataCopy.deleteValue = false)
      : (replicaDataCopy.deleteValue = true);
    setReplicaData(replicaDataCopy);
  };

  // Функция для отмены вызова плашки удаления реплик
  const clickCancel = () => {
    let replicaDataCopy = { ...replicaData };
    replicaDataCopy.deleteValue = false;
    setReplicaData(replicaDataCopy);
  };

  // Функция для вызова плашки отклонения реплик
  const clickReject = () => {
    setIsShowRejectModal(true);

    let replicaDataCopy = { ...replicaData };

    replicaDataCopy.rejectValue
      ? (replicaDataCopy.rejectValue = false)
      : (replicaDataCopy.rejectValue = true);

    if (replicaDataCopy.rejectValue) {
      replicaDataCopy.btnActive.rejectActive = false;
      replicaDataCopy.btnActive.approveActive = true;
      replicaDataCopy.btnActive.deleteActive = true;
    } else {
      replicaDataCopy.btnActive.rejectActive = false;
      replicaDataCopy.btnActive.approveActive = false;
      replicaDataCopy.btnActive.deleteActive = false;
      replicaDataCopy.confirm = false;
    }

    setReplicaData(replicaDataCopy);
  };

  // Функция для вызова плашки одобрения реплик
  const clickApprove = () => {
    setIsShowRejectModal(false);

    let replicaDataCopy = { ...replicaData };

    replicaDataCopy.approveValue
      ? (replicaDataCopy.approveValue = false)
      : (replicaDataCopy.approveValue = true);

    if (replicaDataCopy.approveValue) {
      replicaDataCopy.btnActive.rejectActive = true;
      replicaDataCopy.btnActive.approveActive = false;
      replicaDataCopy.btnActive.deleteActive = true;
    } else {
      replicaDataCopy.btnActive.rejectActive = false;
      replicaDataCopy.btnActive.approveActive = false;
      replicaDataCopy.btnActive.deleteActive = false;
      replicaDataCopy.confirm = false;
    }

    setReplicaData(replicaDataCopy);
  };

  // Функция для вызова финальной плашки плашки одобрения реплик
  const clickFinish = () => {
    let replicaDataCopy = { ...replicaData };
    replicaDataCopy.confirm ? (replicaDataCopy.confirm = false) : (replicaDataCopy.confirm = true);
    setReplicaData(replicaDataCopy);
  };

  // Удалить реплики (массово и штучно)
  const onClickReplicaDeleteMany = async (id = null) => {
    const ids = [];

    if (id !== null) {
      ids.push(id);
    } else if (arraySelectedItems.length) {
      for (const data of arraySelectedItems) {
        ids.push(data.id);
      }
    }

    if (ids.length) {
      setReplicaData(replicaDataDefault);
      setReplicaChildrenSelect({});
      setReplicaChildrenUpdate(!replicaChildrenUpdate);
      setArraySelectedItems([]);
      setIsAllSelected(false);

      await axios.delete(`${config.SERVER_BASE_URL}/replica/many`, {
        data: {
          token: localStorage.getItem("token"),
          ids,
        },
      });

      await getReplica(1, true, replicaType);
    }
  };

  // одобрить реплику по id
  const onApproveReplicaById = async (id) => {
    try {
      popupContextData.setPreloader(true);

      const ids = [];

      if (id) {
        ids.push(id);
      }

      if (ids.length) {
        const replicaDataCopy = { ...replicaData };
        replicaDataCopy.confirm = !replicaDataCopy.confirm;
        setReplicaData(replicaDataCopy);

        setTimeout(async () => {
          await axios.post(`${config.SERVER_BASE_URL}/replica/approveMany`, {
            token: localStorage.getItem("token"),
            msg: replicaData.msgApprove,
            ids,
          });

          setReplicaData(replicaDataDefault);
          setReplicaChildrenSelect({});
          setReplicaChildrenUpdate(!replicaChildrenUpdate);
          setSelectedReplicaForSavePopup(null);

          await getReplica(1, true, replicaType);
        }, 2000);
      }
    } catch (e) {
      console.error("on approve", e);
    } finally {
      popupContextData.setPreloader(false);
    }
  };

  // Одобрить реплики (массово и штучно)
  const onClickReplicaApproveMany = async (id = null) => {
    const ids = [];

    if (id !== null) {
      ids.push(id);
    } else if (arraySelectedItems.length) {
      for (const data of arraySelectedItems) {
        ids.push(data.id);
      }
    }

    if (ids.length) {
      const replicaDataCopy = { ...replicaData };
      replicaDataCopy.confirm = !replicaDataCopy.confirm;
      setReplicaData(replicaDataCopy);

      setTimeout(async () => {
        await axios.post(`${config.SERVER_BASE_URL}/replica/approveMany`, {
          token: localStorage.getItem("token"),
          msg: replicaData.msgApprove,
          ids,
        });

        setReplicaData(replicaDataDefault);
        setReplicaChildrenSelect({});
        setReplicaChildrenUpdate(!replicaChildrenUpdate);
        setArraySelectedItems([]);

        await getReplica(1, true, replicaType);

        setIsAllSelected(false);
      }, 2000);
    }
  };

  // Отклонить реплики (массово и штучно)
  const onClickReplicaRejectMany = async (data = []) => {
    const ids = [];

    if (data.length > 0) {
      for (const id of data) {
        ids.push(id);
      }
    } else if (arraySelectedItems.length) {
      for (const data of arraySelectedItems) {
        ids.push(data.id);
      }
    }

    if (ids.length) {
      if (!!message || !!replicaData.msgReject) {
        const replicaDataCopy = { ...replicaData };
        replicaDataCopy.confirm = !replicaDataCopy.confirm;
        setReplicaData(replicaDataCopy);

        setTimeout(async () => {
          await axios.post(`${config.SERVER_BASE_URL}/replica/rejectMany`, {
            token: localStorage.getItem("token"),
            msg: message !== null ? message : replicaData.msgReject,
            ids,
          });

          setReplicaData(replicaDataDefault);
          setReplicaChildrenSelect({});
          setReplicaChildrenUpdate(!replicaChildrenUpdate);
          setArraySelectedItems([]);

          await getReplica(1, true, replicaType);
          setIsAllSelected(false);
        }, 2000);
      } else {
        alert("Укажите причину отклонения");
      }
    }

    setIsShowRejectModal(false);
    setMessage("");
    setDataRejects([]);
  };

  // Изменить комментарий в плашке с массовым действием для реплик
  const onChangeMsgReplicaMany = (value, type) => {
    const replicaDataCopy = { ...replicaData };

    if (type === "approve") {
      replicaDataCopy.msgApprove = value;
    } else {
      replicaDataCopy.msgReject = value;
    }

    setReplicaData(replicaDataCopy);
  };

  // Смена табов реплик (списком, инициирование, реагирование)
  const onClickReplicaTab = (type) => {
    setReplicaType(type);
  };

  // Очистить дочерние галочки
  const clearReplicaChildrenSelect = () => {
    const replicaChildrenSelectCopy = { ...replicaChildrenSelect };
    const replicaDataCopy = { ...replicaData };

    if (replicaChildrenSelectCopy && Object.keys(replicaChildrenSelectCopy).length) {
      for (const id in replicaChildrenSelectCopy) {
        replicaChildrenSelectCopy[id].checked = false;

        if (replicaDataCopy.replicaCheckArrayCount.statusArray.length) {
          const sameValue = replicaDataCopy.replicaCheckArrayCount.statusArray.findIndex(
            (elem) => elem[0] === id,
          );
          replicaDataCopy.replicaCheckArrayCount.statusArray.splice(sameValue, 1);
        }
      }
    }

    setReplicaChildrenSelect(replicaChildrenSelectCopy);
    setReplicaData(replicaDataCopy);

    replicaCheckStatus();
  };

  // Смена страницы
  const changePage = async (page) => {
    const pageCurrent = replicaOptions.currentPage ? parseInt(replicaOptions.currentPage) : false;
    const pageNext = page ? parseInt(page) : false;

    if (pageCurrent && pageNext && pageCurrent !== pageNext) {
      // history.push(`/replica/?page=${pageNext}`)

      // window.scrollTo({
      //     top: 0,
      //     behavior: 'smooth',
      // })

      await getReplica(pageNext, false, replicaType);
    }
  };

  // Смена кол-ва реплик на странице
  const onChangeView = async (value) => {
    localStorage.setItem("replica-view", value.value);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    await getReplica(1, false, replicaType);

    setIsAllSelected(false);
    setArraySelectedItems([]);
  };

  // Изменяем сортировку
  const changeSort = async (name, direction) => {
    localStorage.setItem("replica-sort-name", name);
    localStorage.setItem("replica-sort-direction", direction);

    setSort({
      name,
      direction,
    });

    await getReplica(1, false, replicaType);
  };

  // Собираем список пунктов для экспорта реплик
  const exportSelectOptions = [
    { value: "filter", label: "Все реплики по фильтру", count: replicaOptions.items },
  ];

  const replicaCountProject = useMemo(
    () =>
      filter.project.options.length > 0
        ? filter.project.options.reduce((a, b) => ({ x: a.count + b.count }))
        : 0,
    [filter.project.options],
  );

  if (filter.project.value) {
    exportSelectOptions.push({
      value: "project",
      label: "Все реплики в проекте",
      count: replicaCountProject,
    });
  }

  // Только выбранные реплики, доработать
  if (arraySelectedItems && arraySelectedItems.length > 0) {
    exportSelectOptions.push({
      value: "select",
      label: "Только выбранные реплики",
      count: arraySelectedItems.length,
    });
  }

  const replicaCountCompany = useMemo(
    () =>
      filter.company.options.length > 0
        ? filter.company.options.reduce((a, b) => ({ x: a.count + b.count }))
        : 0,
    [filter.company.options],
  );

  if (filter.company.value) {
    exportSelectOptions.push({
      value: "company",
      label: "Все реплики Компании",
      count: replicaCountCompany,
    });
  }

  const [isReplicaChange, setIsReplicaChange] = useState(false);

  const replicaChange = (id, field, value) => {
    if (id && field && replica && replica[id]) {
      const replicaCopy = { ...replica };

      replicaCopy[id][field] = value;
      setIsReplicaChange(true);
      setReplica(replicaCopy);
    }
  };

  function declOfNum(number, words) {
    return words[
      number % 100 > 4 && number % 100 < 20
        ? 2
        : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? number % 10 : 5]
    ];
  }

  // useEffect(() => {
  //     (async ()=> {
  //         await getFilter()
  //         await getReplica(getUrlParams('page'), false, replicaType)
  //     })()
  //     // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    (async () => {
      try {
        await defaultFilter();
      } catch (error) {
        console.log("replica type", error);
      }
    })();
  }, [replicaType]);

  return (
    <>
      <Helmet>
        <title>Реплики</title>
      </Helmet>

      <div className="brightless" data-active={blackout ? "Y" : null} />
      {arraySelectedItems.length ? (
        <TopReplicSelector
          // num={replicaData.replicaCheckArrayCount.statusArray.length}
          num={arraySelectedItems.length}
          clickDelete={clickDelete}
          clickReject={clickReject}
          clickApprove={clickApprove}
          clickFinish={clickFinish}
          rejectValue={replicaData.rejectValue}
          approveValue={replicaData.approveValue}
          btnDisabled={replicaData.btnActive}
          confirm={replicaData.confirm}
          clickApproveFinal={onClickReplicaApproveMany}
          clickRejectFinal={onClickReplicaRejectMany}
          onChangeMsg={(value, type) => onChangeMsgReplicaMany(value, type)}
          msgReject={replicaData.msgReject}
          msgApprove={replicaData.msgApprove}
          showButtonApprove={replicaData.buttons.approve}
          showButtonReject={replicaData.buttons.reject}
          showButtonDelete={replicaData.buttons.delete}
          setArraySelectedItems={setArraySelectedItems}
          arraySelectedItems={arraySelectedItems}
          accessLevel={accessLevel}
          setIsAllSelected={setIsAllSelected}
        />
      ) : null}
      {isShowRejectModal && (
        <ConfirmationAction
          text="Укажите причину отклонения!"
          subtext="Без указания причины выбранные реплики не могут быть отклонены!"
          dataSmallTextArea="S"
          dataColorTextArea="lightred"
          buttonText="Подтвердить отклонение"
          buttonColor="red"
          textFinish={declOfNum(1, [
            "выбранная реплика отклонена",
            "выбранных реплики отклонены",
            "выбранных реплик отклонено",
          ])}
          num={1}
          clickFinish={() => onClickReplicaRejectMany(dataRejects)}
          confirm={replicaData.confirm}
          onChangeMsg={(e) => setMessage(e.target.value)}
          msg={message}
        />
      )}
      {replicaData.deleteValue ? (
        <DeleteReplic clickDelete={onClickReplicaDeleteMany} clickCancel={clickCancel} />
      ) : null}

      <div className="replic">
        <div className="replic__wrap">
          <div className="replic__top">
            <h1 className="replic__h1">Реплики</h1>

            <Counter
              all={replicaOptions.items}
              isLoading={popupContextData.preloader}
              refused={
                filter.status.options && filter.status.options.length > 0
                  ? filter.status.options.find((item) => item.id === "5f12dd3ba2585a4b908cb608")
                      .count
                  : 0
              }
              replicaType={replicaType}
            />
          </div>

          <Filter
            filter={filter}
            isShowResetDate={isShowResetDate}
            accessLevel={accessLevel}
            filterSelectChange={(value, name) => filterSelectChange(value, name)}
            onResetDate={onResetDate}
            filterDefaultAction={defaultFilter}
            buttonDefault={isButtonDefaultExists(filter)}
            replicaType={replicaType}
            counters={replicaOptions ? replicaOptions : null}
          />

          <Search
            isReplicaPage={true}
            replicaCount={Object.keys(replica).length}
            companies={filter.company}
            search={filter.search}
            replicaData={replicaData}
            setReplicaData={setReplicaData}
            exportReplicaList={exportReplicaList}
            updateReplicaList={getReplica}
            accessLevel={accessLevel}
            filterInputChange={(value, name) => filterSelectChange(value, name)}
            replicaType={replicaType}
            replicaChildrenUpdateStart={() => setReplicaChildrenUpdate(!replicaChildrenUpdate)}
            setTabsShow={setTabsShow}
            blackout={blackout}
            setBlackOut={setBlackOut}
            replicaChildrenOpenForBlackout={replicaChildrenOpenForBlackout}
            exportSelectOptions={exportSelectOptions}
          />

          {tabsShow ? (
            <ReplicaTabs
              type={replicaType}
              onClick={(type) => onClickReplicaTab(type)}
              onClickPlus={addCheckboxAllReplica}
              changeSort={changeSort}
              sortName={sort.name}
              sortDirection={sort.direction}
              isAllSelected={isAllSelected}
              // defaultFilter={defaultFilter}
            />
          ) : null}

          <div className="replic__top_col-rev">
            {replica && Object.keys(replica).length ? (
              <>
                <ReplicaList
                  replica={replica}
                  onChangeStatusReplica={onChangeStatusReplica}
                  replicaSelect={(event, id, status) => replicaSelect(event, id, status)}
                  accessLevel={accessLevel}
                  updateReplicaList={() => getReplica(1, true)}
                  addCheckboxAllReplica={addCheckboxAllReplica}
                  replicaChildrenSelect={replicaChildrenSelect}
                  replicaChildrenUpdate={replicaChildrenUpdate}
                  replicaChildrenUpdateStart={() =>
                    setReplicaChildrenUpdate(!replicaChildrenUpdate)
                  }
                  clearReplicaChildrenSelect={() => clearReplicaChildrenSelect()}
                  blackout={blackout}
                  setBlackout={setBlackOut}
                  replicaChildrenOpenForBlackout={replicaChildrenOpenForBlackout}
                  setReplicaChildrenOpenForBlackout={setReplicaChildrenOpenForBlackout}
                  replicaType={replicaType}
                  changeSort={changeSort}
                  sortName={sort.name}
                  sortDirection={sort.direction}
                  arraySelectedItems={arraySelectedItems}
                  isAllSelected={isAllSelected}
                  replicaChange={replicaChange}
                  isReplicaChange={isReplicaChange}
                  setIsReplicaChange={setIsReplicaChange}
                  onApproveReplicaById={onApproveReplicaById}
                  telegram={telegram}
                  projectId={filter.project.value}
                  onChangeSelectedReplicaForSavePopup={onChangeSelectedReplicaForSavePopup}
                  selectedReplicaForSavePopup={selectedReplicaForSavePopup}
                  btnDisabled={replicaData.btnActive}
                  onTapRemove={onClickReplicaDeleteMany}
                  onTapReject={(id) => {
                    setDataRejects((prev) => [...prev, id]);
                    setIsShowRejectModal(true);
                  }}
                  onTapApprove={onApproveReplicaById}
                  // clickFinish={clickFinish}
                />

                <div className={classes.PaginationWrap}>
                  <Pagination
                    totalPages={replicaOptions.pages}
                    currentPage={replicaOptions.currentPage}
                    separator="..."
                    href={"replica"}
                    onClick={changePage}
                  />

                  <PaginationSettings
                    className={classes.PaginationSettings}
                    options={[10, 20, 50, 100].map((item) => ({
                      value: item,
                      label: item,
                    }))}
                    onChange={onChangeView}
                    value={{
                      value: countDefaultItem,
                      label: countDefaultItem,
                    }}
                  />
                </div>
              </>
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Replica;
