import React from "react";

import classes from "./FinishConfirmationAction.module.scss";

export default function FinishConfirmationAction(props) {
  return (
    <div className={classes.FinishConfirmationAction}>
      <span className={classes.Text} data-color={props.dataColorTextArea}>
        {props.num} {props.text}
      </span>
    </div>
  );
}
