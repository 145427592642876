import React, { useContext } from "react";

import classes from "./PopupSaveReplica.module.scss";

import { PopupContext } from "../../../../context/popup/popupContext";
import IconInfo from "../../../../icons/IconInfo";

function PopupSaveReplica({ company, project, date, platform, onApproveReplicaById }) {
  const { onCloseModal } = useContext(PopupContext);

  return (
    <div className={classes.Wrapper}>
      <div className={classes.Inner}>
        <div className={classes.Header}>
          <IconInfo />
          Реплика успешно сохранена
        </div>

        <div className={classes.Content}>
          {platform && date && (
            <div className={classes.ContentRow}>
              <span className={`${classes.ContentTitle} ${classes.Date}`}>{date}</span>
              {platform}
            </div>
          )}

          {company?.name && (
            <div className={classes.ContentRow}>
              <span className={classes.ContentTitle}>Клиент:</span>
              {company.name}
            </div>
          )}

          {project?.name && (
            <div className={classes.ContentRow}>
              <span className={classes.ContentTitle}>Проект:</span>
              {project.name}
            </div>
          )}
        </div>

        <div className={classes.Control}>
          <button
            onClick={() => {
              onApproveReplicaById();
              onCloseModal();
            }}>
            Отправить на размещение
          </button>
          <button onClick={onCloseModal}>Пропустить</button>
        </div>
      </div>
    </div>
  );
}

export default PopupSaveReplica;
