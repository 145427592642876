import axios from "axios";
import { saveAs } from "file-saver";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as XLSX from "xlsx";

import Row from "./Row";
import classes from "./Search.module.scss";
import { headers } from "./constants";

import { apiCheckTaskId } from "../../api/search";
import { FindButton } from "../../components/Search/FindButton/FindButton";
import { SearchModal } from "../../components/Search/SearchModal/SearchModal";
import { HistoryButton } from "../../components/SearchHistory";
import config from "../../config/config";
import IconNegative from "../../containers/Analise/icons/IconNegative";
import IconNeutral from "../../containers/Analise/icons/IconNeutral";
import IconPending from "../../containers/Analise/icons/IconPending";
import IconPositive from "../../containers/Analise/icons/IconPositive";
import { PopupContext } from "../../context/popup/popupContext";
import { useInterval } from "../../hooks/useInterval";

export const tonality = [
  { name: <IconNeutral />, id: "neutral", count: 1 },
  { name: <IconNegative />, id: "negative", count: 1 },
  { name: <IconPositive />, id: "positive", count: 1 },
  { name: <IconPending />, id: "pending", count: 1 },
];

const Search = () => {
  const [result, setResult] = useState([]);
  const [isShowFindModal, setIsShowFindModal] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);

  const [brandName, setBrandName] = useState("");
  const [findSystem, setFindSystem] = useState(1);
  const [region, setRegion] = useState("");
  const [regionId, setRegionId] = useState(null);
  const [deep, setDeep] = useState(10);
  const [suggestions, setSuggestions] = useState([]);

  const { setPreloader, setPopupNotice } = useContext(PopupContext);

  const getTaskInfo = useCallback(async () => {
    if (isLoading) return;
    setError(null);
    setIsLoading(true);
    localStorage.setItem("taskId", JSON.stringify(taskId));

    try {
      const result = await apiCheckTaskId(taskId);

      if (result) {
        setPreloader(true);

        if (+result.progress === 100) {
          const params = {
            taskId,
          };

          const response = await axios.get(
            `${config.SERVER_BASE_URL}/search-third-service/search-result`,
            { params },
          );

          if (response?.status === 200) {
            if (response?.data?.result) {
              setResult(response?.data?.tasks);
            }

            setPreloader(false);
          }

          if (response?.status === 400) {
            if (response?.data?.message) {
              setError(
                `Произошла ошибка! Создайте запрос заново. Описание ошибки: ${response.data?.message}`,
              );
            }

            setPreloader(false);
          }
        }
        setProgress(+result.progress);
      }
    } catch (error) {
      setPreloader(false);
      setError(error.message);
    } finally {
      setIsLoading(false);
      localStorage.removeItem("taskId");
    }
  });

  const handleCreateTaskId = async (id) => {
    localStorage.removeItem("taskId");
    setIsLoading(false);
    setError(null);
    setTaskId(id);
  };

  const renderTableHeader = (headers) => {
    return (
      <tr>
        {headers.map((header) => (
          <th
            style={{ padding: 10, fontWeight: "bold", texAligh: "left" }}
            key={`${header}-${Math.random()}`}>
            {header}
          </th>
        ))}
      </tr>
    );
  };
  const renderTableRow = (task, rowIndex) => {
    return (
      <Row
        result={result}
        setResult={setResult}
        data={{
          host: task.host,
          url: task.url,
          scale: task.scale,
          rating: task.rating,
          header: task.header,
          description: task.description,
          tonality: task.tonality,
        }}
        rowIndex={rowIndex}
        onTapUrl={onTapUrl}
        onChangeTonality={handleChangeTonality}
        onChangeRating={handleChangeRating}
      />
    );
  };

  const handleChangeRating = (block, rowIndex, scale) => {
    const arrayToUpdate = result.slice();

    arrayToUpdate[rowIndex].rating = block.name;
    arrayToUpdate[rowIndex].scale =
      block.scale === "scale5" ? "5 балльная" : block.scale === "scale10" ? "10 балльная" : "-";

    setResult(arrayToUpdate);
  };

  const handleChangeTonality = useCallback(
    (event, rowIndex) => {
      result[rowIndex].tonality = event.target.value;
    },
    [result],
  );

  const onTapUrl = (event, url) => {
    event.preventDefault();

    navigator.clipboard
      .writeText(url)
      .then(() => {
        console.log("URL copied to clipboard:", url);
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });

    window.open(url, "_blank");
  };

  const saveToExcel = (data, fileName) => {
    const list = [
      {
        host: "Домен",
        url: "URL",
        scale: "Шкала",
        rating: "Рейтинг",
        header: "Заголовок",
        description: "Описание",
        tonality: "Тональность",
      },
    ];

    data.forEach((x) => {
      list.push({
        host: x.host,
        url: x.url,
        scale: typeof x.scale === "string" && x.rating ? x.scale : "-",
        rating: x.rating || "-",
        header: x.header,
        description: x.description,
        tonality: x.tonality,
      });
    });

    const worksheet = XLSX.utils.json_to_sheet(list, { skipHeader: true });
    const workbook = XLSX.utils.book_new();
    worksheet["!cols"] = [
      { wpx: 100 },
      { wpx: 100 },
      { wpx: 70 },
      { wpx: 70 },
      { wpx: 100 },
      { wpx: 100 },
      { wpx: 100 },
    ];
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, `${fileName}.xlsx`);
  };

  const saveToCSV = () => {
    const top30 = result.length > 30 ? result.slice(0, 30) : result;

    let value = "";
    value += "Домен,URL,Шкала,Рейтинг,Заголовок,Описание,Тональность\n";
    top30.forEach((x) => {
      value += `"${x.host}",`;
      value += `"${x.url}",`;
      value += `"${typeof x.scale === "string" && x.rating ? x.scale : "-"}",`;
      value += `"${x.rating || "-"}",`;
      value += `"${x.header}",`;
      value += `"${x.description}",`;
      value += `"${x.tonality}"\n`;
    });
    const blob = new Blob([value], { type: "text/csv" });
    window.open(URL.createObjectURL(blob));
  };

  useInterval(
    async () => {
      await getTaskInfo(taskId);
    },
    taskId != null && progress < 100 ? 6000 : null,
  );

  useEffect(() => {
    const storageTaskId = localStorage.getItem("taskId");
    if (storageTaskId) {
      setTaskId(storageTaskId);
      setProgress(100);
      getTaskInfo();
    }
  }, []);

  const showModal = () => {
    setProgress(0);
    setBrandName("");
    setTaskId(null);
    setSuggestions([]);
    setResult([]);
    setRegion("");
    setRegionId(null);
    setIsShowFindModal(true);
  };

  const history = useHistory();
  const onClickHistoryButton = () => {
    history.push("/search/history");
  };

  const handleSaveXlsx = () => {
    const top30 = result.length > 30 ? result.slice(0, 30) : result;
    saveToExcel(top30, "result");
  };

  const handleSaveCsv = () => {
    saveToCSV();
  };

  const handleSaveToDB = async () => {
    try {
      setPreloader(true);
      const preparedData = result.map((old) => {
        return {
          ...old,
          scale: typeof old.scale === "string" && old.rating ? old.scale : "-",
          rating: old.rating || "-",
        };
      });

      await axios.put(`${config.SERVER_BASE_URL}/search-third-service/update-results`, {
        data: preparedData,
      });

      setPopupNotice({
        state: true,
        title: "Данные успешно сохранены",
      });
    } catch (error) {
      setPopupNotice({
        state: true,
        title: "Произошла ошибка, попробуйте позднее",
      });
    } finally {
      setPreloader(false);
    }
  };

  return (
    <div className={`replic ${classes.Desktop}`}>
      <div className={classes.SearchButtons}>
        <FindButton onClick={showModal} />
        <HistoryButton onClick={onClickHistoryButton} />
      </div>
      {!isShowFindModal && (
        <div className={classes.DesktopWrapper}>
          <h1 className={classes.Title}>Поисковая выдача {taskId ? `${progress}%` : ""}</h1>
          {!isLoading && error && <h1 className={classes.loadingTitle}>{error}</h1>}
          {!isLoading && result.length > 0 ? (
            <div className={classes.TableWrapper}>
              <table style={{ width: "100%" }}>
                <thead className={classes.TableHead}>{headers && renderTableHeader(headers)}</thead>
                <tbody>{result.map((task, rowIndex) => renderTableRow(task, rowIndex))}</tbody>
              </table>
            </div>
          ) : null}

          {!isLoading && result.length > 0 && (
            <div className={classes.buttonsWrapper}>
              <button onClick={handleSaveXlsx}>Сохранить в XLSX</button>
              <button onClick={handleSaveCsv}>Сохранить в CSV</button>
              <button onClick={handleSaveToDB}>Сохранить</button>
            </div>
          )}
        </div>
      )}

      <SearchModal
        deep={deep}
        brandName={brandName}
        regionId={regionId}
        region={region}
        findSystem={findSystem}
        isVisible={isShowFindModal}
        suggestions={suggestions}
        setTaskId={handleCreateTaskId}
        onClose={() => setIsShowFindModal(false)}
        onRegionName={setRegion}
        onRegionId={setRegionId}
        onBrandName={setBrandName}
        onSuggestions={setSuggestions}
        onFindSystem={setFindSystem}
        onDeep={setDeep}
      />
    </div>
  );
};

export default Search;
