import React from "react";

function IconCheck({ className }) {
  return (
    <svg
      className={className}
      width={21}
      height={20}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.800049 10C0.800049 15.5228 5.2772 20 10.8 20C16.3229 20 20.8 15.5228 20.8 10C20.8 4.47715 16.3229 0 10.8 0C5.2772 0 0.800049 4.47715 0.800049 10ZM18.7711 10.0001C18.7711 14.4023 15.2024 17.9711 10.8001 17.9711C6.39784 17.9711 2.8291 14.4023 2.8291 10.0001C2.8291 5.59779 6.39784 2.02905 10.8001 2.02905C15.2024 2.02905 18.7711 5.59779 18.7711 10.0001Z"
        fill="#119E00"
      />
      <path
        d="M6.80005 10L9.80005 13L14.8 8"
        stroke="#119E00"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </svg>
  );
}

export default IconCheck;
