import React from "react";

function IconArrowDownMini() {
  return (
    <svg width={17} height={16} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.41 3.48H3.77L5.82 1.42L4.41 0L0.74 3.68L0 4.48L0.74 5.21L4.41 8.86L5.82 7.48L3.82 5.48H10.41C12.6191 5.48 14.41 7.27086 14.41 9.48C14.41 11.6891 12.6191 13.48 10.41 13.48H7.41V15.48H10.41C13.7237 15.48 16.41 12.7937 16.41 9.48C16.41 6.16629 13.7237 3.48 10.41 3.48Z"
        fill="white"
      />
    </svg>
  );
}

export default IconArrowDownMini;
