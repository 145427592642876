import moment from "moment";
import React from "react";

import "./ReplicaAddMain.scss";

import Input from "../../../../UI/Input/Input";
import Textarea from "../../../../UI/Textarea/Textarea";

const ReplicaAddMain = (props) => {
  const dateValue = moment(props.date.value).format("DD.MM.YYYY");

  return (
    <div className="add-edit-replic__main-data">
      <div className="add-edit-replic__main-data-header-bar">
        <div className="add-edit-replic__main-data-header-bar-cell">{props.date.label}</div>
        <div className="add-edit-replic__main-data-header-bar-cell add-edit-replic__main-data-header-bar-cell_width_201px">
          {props.platform.label}
        </div>
        <div className="add-edit-replic__main-data-header-bar-cell add-edit-replic__main-data-header-bar-cell_width_155px">
          {props.msg.label}
        </div>
      </div>

      <div className="add-edit-replic__main-data-unit">
        <div className="add-edit-replic__main-data-unit-date">
          <div className="add-edit-replic__main-data-unit-field-date">
            {dateValue !== "Invalid date" ? dateValue : ""}
          </div>
        </div>

        <div className="add-edit-replic__main-data-unit-area">
          <Input
            type={props.platform.type}
            name={props.platform.name}
            value={props.platform.value}
            placeholder={props.platform.label}
            disabled={true}
            onChange={(event) => props.fieldChange(event, props.platform.name)}
          />
        </div>

        <div className="add-edit-replic__main-data-unit-message">
          <div className="add-edit-replic__main-data-unit-message-type-mobile">
            {props.msg.label}
          </div>

          <div className="add-edit-replic__main-data-unit__row">
            <span>Клиент:</span>
            {props?.company?.name}
          </div>

          <div className="add-edit-replic__main-data-unit__row">
            <span>Проект:</span>
            {props?.project?.name}
          </div>

          <Textarea
            name={props.msg.name}
            value={props.msg.value}
            onChange={(event) => props.fieldChange(event, props.msg.name)}
          />

          {props?.noteSystem?.value && props.id && (
            <div className="add-edit-replic__main-data-unit-url-input  is-edit">
              <div className="add-edit-replic__main-data-unit-url-type">
                {props.noteSystem.label}
                <span>A</span>
              </div>

              <Input
                type={props.noteSystem.type}
                name={props.noteSystem.name}
                value={props.noteSystem.value}
                placeholder={props.noteSystem.placeholder}
                onChange={(event) => props.fieldChange(event, props.noteSystem.name)}
              />
            </div>
          )}
        </div>
      </div>

      {!props.id ? (
        <>
          <div className="add-edit-replic__main-data-unit">
            <div className="add-edit-replic__main-data-unit-url">{props.url.label}</div>

            <div className="add-edit-replic__main-data-unit-url-input">
              <div className="add-edit-replic__main-data-unit-url-type">{props.url.label}</div>

              <Input
                type={props.url.type}
                name={props.url.name}
                value={props.url.value}
                placeholder={props.url.placeholder}
                onChange={(event) => props.fieldChange(event, props.url.name)}
              />
            </div>
          </div>

          <div className="add-edit-replic__main-data-unit">
            <div className="add-edit-replic__main-data-unit-url">{props.agentName.label}</div>

            <div className="add-edit-replic__main-data-unit-url-input">
              <div className="add-edit-replic__main-data-unit-url-type">
                {props.agentName.label}
              </div>

              <Input
                type={props.agentName.type}
                name={props.agentName.name}
                value={props.agentName.value}
                placeholder={props.agentName.placeholder}
                onChange={(event) => props.fieldChange(event, props.agentName.name)}
              />
            </div>
          </div>

          <div className="add-edit-replic__main-data-unit noteSystem">
            <div className="add-edit-replic__main-data-unit-url">
              {props.noteSystem.label}
              <span>A</span>
            </div>

            <div className="add-edit-replic__main-data-unit-url-input">
              {/* <div className='add-edit-replic__main-data-unit-url-type'>
                                {props.noteSystem.label}
                                <span>A</span>
                            </div> */}

              <Input
                type={props.noteSystem.type}
                name={props.noteSystem.name}
                value={props.noteSystem.value}
                placeholder={props.noteSystem.placeholder}
                onChange={(event) => props.fieldChange(event, props.noteSystem.name)}
              />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ReplicaAddMain;
