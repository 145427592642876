import axios from "axios";
import cn from "classnames";
import { Russian } from "flatpickr/dist/l10n/ru";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";

import classes from "./ReplicaItem.module.scss";

import config from "../../../../config/config";
import Checkbox from "../../../UI/Checkbox/Checkbox";
import ReplicaAdd from "../ReplicaAdd/ReplicaAdd";
import ReplicaDelete from "../ReplicaDelete/ReplicaDelete";
import ReplicaDetail from "../ReplicaDetail/ReplicaDetail";
import ReplicaListChildren from "../ReplicaListChildren/ReplicaListChildren";
import UrlDropDown from "../UrlDropDown/UrlDropDown";

const ReplicaItem = (props) => {
  const token = localStorage.getItem("token");

  const [replicaChildrenOpen, setReplicaChildrenOpen] = useState(false);
  const [replicaEdit, setReplicaEdit] = useState(false);
  const [replicaDelete, setReplicaDelete] = useState(false);
  const [flagOpenReplica, setFlagOpenReplica] = useState(false); // начальное значение переменной, которое отвечает за раскрытие текста

  const [selectedItem, setSelectedItem] = useState(null);
  const [isOpenDetail, setIsOpenDetail] = useState(false);

  const onChangeSelectedItem = (value) => {
    setSelectedItem(value);
  };

  const replicaItemClasses = cn({
    "replic__replic-unit": true,
    "replic__replic-unit_open": isOpenDetail === props.id,
  });

  function dropDownText(e) {
    if (flagOpenReplica === false) {
      e.target.style.transform = "rotate(180deg)";
      e.target.parentElement.parentElement.style.height = "auto"; //раскрываем поле текста
      let heightElement = window
        .getComputedStyle(e.target.parentElement.parentElement, null)
        .getPropertyValue("height"); //
      e.target.nextSibling.style.height = heightElement.split("px")[0] - 20 + "px"; // - 20 означает насколько укорачиваем линию
      setFlagOpenReplica(true);
    } else {
      e.target.style.transform = "rotate(0deg)";
      e.target.parentElement.parentElement.style.height = "24px";
      e.target.nextSibling.style.height = "5px";
      setFlagOpenReplica(false);
    }
  }

  function changeTextLong() {
    if (window.innerWidth > 1280 && props.msg && props.msg.length < 82) return "Y";
    else if (window.innerWidth < 768 && props.msg && props.msg.length < 36) return "Y";
    else if (
      window.innerWidth > 767 &&
      window.innerWidth < 1279 &&
      props.msg &&
      props.msg.length < 44
    )
      return "Y";
  }

  function blackout() {
    !props.blackout ? props.setBlackOut(true) : props.setBlackOut(false);
  }

  async function onChangeDateForReplic(newDate, replicaId) {
    try {
      console.log(`[DEV] #onChangeDateForReplic date: ${newDate} and replicaId: ${replicaId}`);
      const statusChange = moment(newDate).format("DD.MM.YYYY");
      console.log(`[DEV] #onChangeDateForReplic statusChange: ${statusChange}`);

      props.onChangeDate(replicaId, statusChange);
      const response = await axios.put(
        `${config.SERVER_BASE_URL}/replica/statusChange/${replicaId}`,
        {
          statusChange,
          token,
        },
      );

      if (response.status === 200) {
        console.log(`Результат обновления даты: ${JSON.stringify(response.data)}`);
      }
    } catch (error) {
      console.error(`Error update date for replic. Message: ${error?.message ?? error.toString()}`);
    }
  }

  // Если изменить таб с типом реплик - скрываем все открытые списки дочерних реплик
  useEffect(() => {
    if (isOpenDetail) {
      setIsOpenDetail(false);
    }

    if (replicaChildrenOpen) {
      setReplicaChildrenOpen(false);
    }

    if (replicaEdit) {
      setReplicaEdit(false);
    }

    if (replicaDelete) {
      setReplicaDelete(false);
    }

     
  }, [props.replicaChildrenUpdate]);

  const statusReplica =
    props.status.code === "waiting-agreement" && window.innerWidth < 768
      ? `${props.status.name.substring(0, 16)}.`
      : props.status.name;

  const statusReplicaWith =
    props.statusChange &&
    (props.status.code === "waiting-agreement" || props.status.code === "for-posting")
      ? "с "
      : "";

  const statusReplicaWithDate = props.statusChange
    ? moment(props.statusChange).format("DD.MM.YYYY")
    : null;

  return (
    <div className={classes.ReplicaItem} data-active={replicaChildrenOpen ? "Y" : null}>
      {window.innerWidth < 768 && props.type && !props.children ? (
        <div className={classes.MobileDropDown} data-active={replicaChildrenOpen ? "Y" : null}>
          {(props.type === 1 || props.type === 2) && !props.children ? (
            <div className={classes.Icon} data-icon={props.type} data-status={props.status.code} />
          ) : null}
          {(props.type === 1 || props.type === 2) && !props.children ? (
            <div
              className={classes.ArrowBtn}
              data-active={replicaChildrenOpen ? "Y" : null}
              onClick={() => {
                setReplicaChildrenOpen(!replicaChildrenOpen);
                blackout();
                props.setReplicaChildrenOpenForBlackout(!props.replicaChildrenOpenForBlackout);
              }}
            />
          ) : null}
        </div>
      ) : null}
      <div
        className={replicaItemClasses}
        data-status={props.status.code}
        data-active={replicaChildrenOpen ? "Y" : null}>
        {props.children ? (
          <div>
            <div className="add-edit-replic-vertical-line" data-line={props.dataline} />
            <div className="add-edit-replic-horizont-line" />
          </div>
        ) : null}

        <div className="replic__replic-unit-wrap">
          {/* <div
                        className="replic__replic-unit-button-close-mobile"
                        data-posted={props.status.code === "posted" ? "Y" : null}
                        onClick={() => setReplicaOpen(false)}
                    /> */}

          <div className={classes.Checkbox}>
            <Checkbox
              id={`checkbox-${props.id}`}
              name="replica-select"
              value={props.id}
              // checked={props.checked}
              checked={props?.arraySelectedItems?.find((item) => item.id === props.id) || false}
              onChange={(event) => props.replicaSelect(event, props.id, props.status)}
            />

            {(props.type === 1 || props.type === 2) && !props.children ? (
              <div
                className={classes.Icon}
                data-icon={props.type}
                data-status={props.status.code}
              />
            ) : null}
          </div>

          {props?.dateEdit && (
            <div className={`${classes.InfoLastEdit} ${classes.Mobail}`}>
              <p>Последнее редактирование</p>

              <div className={classes.InfoLastEditDate}>
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2Z"
                    fill="#94A2B6"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7 17.4H11.4488L18.4 10.4488L13.9512 6L7 12.9512V17.4ZM10.8927 16.0098H8.39024V13.5074L10.8927 16.0098ZM9.36335 12.5342L11.8658 15.0366L14.7158 12.1866L12.2133 9.68418L9.36335 12.5342ZM15.689 11.2134L13.1865 8.71101L13.9512 7.94638L16.4536 10.4488L15.689 11.2134Z"
                    fill="#E00C3C"
                  />
                </svg>
                {`${moment(props.dateEdit).format("DD.MM.YYYY")}`}
              </div>
            </div>
          )}

          <div className={classes.Date}>{moment(props.date).format("DD.MM.YYYY")}</div>

          <div className={classes.ContentWrapper}>
            <UrlDropDown
              isOpen={props.id === selectedItem}
              href={props.url}
              onClose={() => setSelectedItem(null)}
              onChangeUrlInReplica={props.onChangeUrlInReplica}
              onChangeSelectedItem={onChangeSelectedItem}
              replicaId={props.id}
            />

            <div className={classes.Site}>
              <span onClick={() => onChangeSelectedItem(props.id)}>{props.platform}</span>
            </div>

            <div className={classes.Content}>
              <div className={classes.Info}>
                {props?.company?.name && (
                  <div className={classes.InfoRow}>
                    <span className={classes.InfoTitle}>Клиент:</span>
                    {props.company.name}
                  </div>
                )}

                {props?.project?.name && (
                  <div className={classes.InfoRow}>
                    <span className={classes.InfoTitle}>Проект:</span>
                    {props.project.name}
                  </div>
                )}
              </div>

              <div className={classes.Text}>
                {props.msg}

                <div
                  className={classes.IconDropDownTextWrapper}
                  data-ellipsys={flagOpenReplica}
                  data-show={changeTextLong()}>
                  <div className={classes.IconDropDownText} onClick={(e) => dropDownText(e)} />

                  <div className={classes.LineDrop} />
                </div>
              </div>

              {props.fields.length
                ? props.fields.map((field, index) => {
                    if (!field.field.name && field.field.type === "url") {
                      field.field.name = "URL";
                    }

                    if (field.field.viewReplica) {
                      const view = !(
                        field.field.type === "note" &&
                        localStorage.getItem("accessLevel") === "2" &&
                        field.field.name === "agency"
                      );

                      if (view) {
                        return (
                          <div className="replic__replic-unit-replic-note" key={index}>
                            <div className="replic__replic-unit-replic-note-title">
                              {field.field.type === "note" ? "Примечание" : field.field.name}
                            </div>

                            <div className="replic__replic-unit-replic-note-text">
                              {field.value
                                ? field.value
                                : field.valueOptions && field.valueOptions.value
                                  ? field.valueOptions.value
                                  : ""}
                            </div>
                          </div>
                        );
                      } else {
                        return true;
                      }
                    } else {
                      return true;
                    }
                  })
                : null}

              {props.textSomeone ? (
                <div className="replic__replic-unit-replic-note replic__replic-unit-replic-note_screen-shot-link">
                  <div className="replic__replic-unit-replic-note-title">Чужая реплика</div>
                  <div className="replic__replic-unit-replic-note-text">{props.textSomeone}</div>
                </div>
              ) : null}

              {props.category ? (
                <div className="replic__replic-unit-replic-note replic__replic-unit-replic-note_screen-shot-link">
                  <div className="replic__replic-unit-replic-note-title">Категория</div>
                  <div className="replic__replic-unit-replic-note-text">{props.category.name}</div>
                </div>
              ) : null}

              {props.agentName &&
              (props.accessLevel === 1 || props.accessLevel === 4 || props.accessLevel === 5) ? (
                <div className="replic__replic-unit-replic-note replic__replic-unit-replic-note_screen-shot-link">
                  <div className="replic__replic-unit-replic-note-title">Имя агента</div>
                  <div className="replic__replic-unit-replic-note-text">{props.agentName}</div>
                </div>
              ) : null}

              {props.noteSystem &&
              (props.accessLevel === 1 || props.accessLevel === 4 || props.accessLevel === 5) ? (
                <div className="replic__replic-unit-replic-note replic__replic-unit-replic-note_screen-shot-link">
                  <div className={classes.NoteSystem + " replic__replic-unit-replic-note-title  "}>
                    Примечание
                    <span>A</span>
                  </div>

                  <div className="replic__replic-unit-replic-note-text">{props.noteSystem}</div>
                </div>
              ) : null}

              {props.comment ? (
                <div className="replic__replic-unit-replic-note replic__replic-unit-replic-note_last-comment">
                  <div className="replic__replic-unit-replic-note-title">
                    Последний комментарий клиента
                  </div>
                  <div className="replic__replic-unit-replic-note-text">{props.comment}</div>
                </div>
              ) : null}

              {props.screenshot ? (
                <div className="replic__replic-unit-replic-note replic__replic-unit-replic-note_screen-shot-link">
                  <div className="replic__replic-unit-replic-note-title">Скриншот</div>

                  <div className="replic__replic-unit-replic-note-text">
                    <a href={props.screenshot} target="_blank" rel="noopener noreferrer">
                      {props.screenshot}
                    </a>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className={classes.StatusUnit + " replic__replic-unit-status "}>
            {!props.isOpenedReplic ? (
              <div
                className={classes.StatusText + " replic__replic-unit-status-text "}
                data-posted={props.type === 2 && !props.children ? "Y" : null}>
                <span>
                  {statusReplica}
                  <b>
                    {statusReplicaWith}
                    {props.statusChange ? moment(props.statusChange).format("DD.MM.YYYY") : null}
                  </b>
                </span>
              </div>
            ) : (
              <Flatpickr
                options={{
                  dateFormat: "d.m.Y",
                  locale: Russian,
                  lang: "ru",
                  disableMobile: true,
                  showMonths: true,
                }}
                render={({ defaultValue, value, ...props }, ref) => {
                  return (
                    <div
                      ref={ref}
                      className={classes.StatusText + " replic__replic-unit-status-text "}
                      data-posted={props.type === 2 && !props.children ? "Y" : null}>
                      <span>
                        {statusReplica}
                        <b>
                          {statusReplicaWith}
                          {statusReplicaWithDate}
                        </b>
                      </span>{" "}
                    </div>
                  );
                }}
                onDayCreate={[
                  function (dObj, dStr, fp, dayElem) {
                    let date = dayElem.dateObj;

                    if (date.getDay() === 0 || date.getDay() === 6) {
                      dayElem.className += " weekday";
                    }
                  },
                ]}
                onChange={async ([date]) => {
                  await onChangeDateForReplic(date, props.id);
                }}
              />
            )}

            {props?.dateEdit && (
              <div className={classes.InfoLastEdit}>
                <p>Последнее редактирование</p>

                <div className={classes.InfoLastEditDate}>
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2Z"
                      fill="#94A2B6"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7 17.4H11.4488L18.4 10.4488L13.9512 6L7 12.9512V17.4ZM10.8927 16.0098H8.39024V13.5074L10.8927 16.0098ZM9.36335 12.5342L11.8658 15.0366L14.7158 12.1866L12.2133 9.68418L9.36335 12.5342ZM15.689 11.2134L13.1865 8.71101L13.9512 7.94638L16.4536 10.4488L15.689 11.2134Z"
                      fill="#E00C3C"
                    />
                  </svg>
                  {`${moment(props.dateEdit).format("DD.MM.YYYY")}`}
                </div>
              </div>
            )}

            {!props.isOpenedReplic && (
              <div
                className="replic__replic-unit-status-botton-agreement"
                data-posted={props.type ? "Y" : null}
                data-mobile={props.status.code === "posted" && window.innerWidth < 768 ? "Y" : null}
                onClick={
                  props.type === 2 && !props.children
                    ? () => {
                        setIsOpenDetail(false);

                        setReplicaEdit(false);
                        setReplicaDelete(true);
                      }
                    : () => {
                        setIsOpenDetail((prevState) => !prevState);
                        props.openPopupDetail(props.item);
                      }
                }>
                {props.type === 2 && !props.children ? (
                  <div className="replic__replic-unit-status-botton-agreement-text">
                    Удалить
                    <br /> реплику
                  </div>
                ) : isOpenDetail ? (
                  <div className="replic__replic-unit-status-botton-agreement-text up">
                    Скрыть согласование
                  </div>
                ) : (
                  <div className="replic__replic-unit-status-botton-agreement-text">
                    Открыть согласование
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <ReplicaDetail
          onSuccesUpdateRepica={props.onSuccesUpdateRepica}
          onRejectReplica={props.onRejectReplica}
          isOpenedReplic={props.isOpenedReplic}
          isOpenDetail={isOpenDetail}
          accessLevel={props.accessLevel}
          id={props.id}
          company={props.company}
          onlyView={props.onlyView}
          project={props.project}
          category={props.category}
          date={props.date}
          platform={props.platform}
          url={props.url}
          msg={props.msg}
          comment={props.comment}
          status={props.status}
          fields={props.fields}
          screenshot={props.screenshot}
          agentName={props.agentName}
          noteSystem={props.noteSystem}
          updateReplicaList={props.updateReplicaList}
          onEdit={() => {
            setIsOpenDetail(false);
            setReplicaEdit(true);
            setReplicaDelete(false);
          }}
          onDelete={() => {
            setIsOpenDetail(false);
            setReplicaEdit(false);
            setReplicaDelete(true);
          }}
          close={() => {
            setIsOpenDetail(false);
            setReplicaEdit(false);
            setReplicaDelete(false);
          }}
          replicaType={props.type}
          replicaChange={props.replicaChange}
        />
      </div>

      {(props.type === 1 || props.type === 2) && !props.children && window.innerWidth > 767 ? (
        <div
          className={classes.ArrowBtn}
          data-active={replicaChildrenOpen ? "Y" : null}
          onClick={() => setReplicaChildrenOpen(!replicaChildrenOpen)}
        />
      ) : null}

      {replicaEdit ? (
        <ReplicaAdd
          id={props.id}
          company={props.company}
          onlyView={props.onlyView}
          project={props.project}
          category={props.category}
          date={props.date}
          platform={props.platform}
          url={props.url}
          msg={props.msg}
          noteSystem={props.noteSystem}
          comment={props.comment}
          status={props.status}
          fields={props.fields}
          updateReplicaList={props.updateReplicaList}
          close={() => setReplicaEdit(false)}
          replicaType={props.type}
          replicaChildrenUpdateStart={props.replicaChildrenUpdateStart}
          accessLevel={props.accessLevel}
        />
      ) : null}

      {replicaDelete ? (
        <ReplicaDelete
          id={props.id}
          close={() => setReplicaDelete(false)}
          updateReplicaList={props.updateReplicaList}
        />
      ) : null}

      {replicaChildrenOpen && !props.children ? (
        <ReplicaListChildren
          id={props.id}
          type={props.type}
          onlyView={props.onlyView}
          updateReplicaList={props.updateReplicaList}
          accessLevel={props.accessLevel}
          replicaSelect={(event, id, status) => props.replicaSelect(event, id, status)}
          company={props.company}
          project={props.project}
          replicaChildrenSelect={props.replicaChildrenSelect}
          replicaChildrenUpdate={props.replicaChildrenUpdate}
          replicaChildrenUpdateStart={props.replicaChildrenUpdateStart}
          clearReplicaChildrenSelect={props.clearReplicaChildrenSelect}
          arraySelectedItems={props.arraySelectedItems}
          openPopupDetail={props.openPopupDetail}
          closePopupDetail={() => setIsOpenDetail(false)}
          isOpenDetail={isOpenDetail}
          replicaChange={props.replicaChange}
          noteSystem={props.noteSystem}
          onChangeUrlInReplica={props.onChangeUrlInReplica}
        />
      ) : null}
    </div>
  );
};

export default ReplicaItem;
