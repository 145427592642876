import cn from "classnames";
import React, { useContext } from "react";

import classes from "./ModalTabs.module.scss";

import { AnaliseContext } from "../../../../../context/analise";
import MobailModal from "../../../UI/MobailModal/MobailModal";
import IconCheckedBig from "../../../icons/IconCheckedBig";

function ModalTabs({ controlModal }) {
  const { state, actions } = useContext(AnaliseContext);

  return (
    <MobailModal title="Что смотрим" controlModal={controlModal}>
      <div className={classes.Wrap}>
        <button
          className={cn(classes.Btn, { [classes.Active]: state.activeTabScreen === "search" })}
          onClick={() => {
            actions.onChangeState("activeTabScreen", "search");
            controlModal.onCloseModal();
          }}>
          Поисковая выдыча
          <IconCheckedBig />
        </button>

        <button
          className={cn(classes.Btn, { [classes.Active]: state.activeTabScreen === "tonality" })}
          onClick={() => {
            actions.onChangeState("activeTabScreen", "tonality");
            controlModal.onCloseModal();
          }}>
          Анализ тональности
          <IconCheckedBig />
        </button>

        <button
          className={cn(classes.Btn, { [classes.Active]: state.activeTabScreen === "rating" })}
          onClick={() => {
            actions.onChangeState("activeTabScreen", "rating");
            controlModal.onCloseModal();
          }}>
          Анализ рейтинга площадок
          <IconCheckedBig />
        </button>
      </div>
    </MobailModal>
  );
}

export default ModalTabs;
