import React from "react";

function IconCopy({ className }) {
  return (
    <svg
      className={className}
      width={21}
      height={21}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5382 0H7.31438C6.50826 0 5.85263 0.6559 5.85263 1.46179V13.6857C5.85263 14.4916 6.50826 15.1475 7.31438 15.1475H19.5382C20.3444 15.1475 21 14.4916 21 13.6857V1.46179C21 0.6559 20.3444 0 19.5382 0ZM19.0934 13.2408H7.75927V1.90669H19.0934V13.2408ZM1.90664 19.0933H13.2407V15.8891H15.1474V19.5382C15.1474 20.3441 14.4917 21 13.6856 21H1.46176C0.65563 21 0 20.3441 0 19.5382V7.31431C0 6.50841 0.65563 5.85251 1.46176 5.85251H5.11107V7.7592H1.90664V19.0933Z"
        fill="#2E2382"
      />
    </svg>
  );
}

export default IconCopy;
