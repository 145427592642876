import cn from "classnames";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import "./Nav.scss";

const Nav = (props) => {
  const [searchOpen, setSearchOpen] = useState(false);

  const searchOpenMobile = () => {
    const search = document.querySelector(".search-and-settings__search");
    if (search.classList.contains("search-and-settings__search_open-mobile")) {
      search.classList.remove("search-and-settings__search_open-mobile");
    } else {
      search.classList.add("search-and-settings__search_open-mobile");
    }
    setSearchOpen(!searchOpen);
  };

  const triggerSearchMobile = cn({
    "search-and-settings__search-mobile": true,
    "search-and-settings__search-mobile_active": searchOpen,
  });

  return (
    <div className="search-and-settings__settings search-and-settings__settings_company-mode">
      <div onClick={searchOpenMobile} className={triggerSearchMobile} />

      <div className="search-and-settings__download">
        <NavLink to="/admin/client" className="search-and-settings__download-text">
          <span>Пользователи</span>
        </NavLink>
      </div>

      <div className="search-and-settings__add-edit-replic">
        <NavLink to="/admin" className="search-and-settings__add-edit-replic-text">
          <span>Компании и проекты</span>
        </NavLink>
      </div>

      <div
        className="search-and-settings__settings-button search-and-settings__settings-button_add-mode"
        onClick={props.companyAdd}
      />
    </div>
  );
};

export default Nav;
