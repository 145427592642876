import React, { useEffect, useState } from "react";
import Select from "react-select";

import classes from "./SelectStyle.module.scss";

const SelectStyle = (props) => {
  const [options, setOptions] = useState([]);
  const [select, setSelect] = useState({});

  useEffect(() => {
    const optionsData = [];

    const defaultActive = {
      value: false,
      label: false,
    };

    if (props.optionDefault) {
      optionsData.push({
        value: "",
        label: props.optionDefault,
      });
    }

    if (props.options && props.options.length) {
      for (const option of props.options) {
        optionsData.push({
          value: option.id,
          label: option.name,
        });
      }
    }

    if (props.options && !props.options.length && Object.keys(props.options).length) {
      Object.keys(props.options).map((id) => {
        return optionsData.push({
          value: props.options[id].id,
          label: cutMax(props.options[id].name, 30),
        });
      });
    }

    if (!props.value && optionsData.length) {
      defaultActive.value = optionsData[0].value;
      defaultActive.label = cutMax(optionsData[0].label, 30);
    } else if (props.value && optionsData.length) {
      optionsData.map((data) => {
        if (data.value === props.value) {
          defaultActive.value = data.value;
          defaultActive.label = cutMax(data.label, 30);
        }

        return true;
      });
    }

    if (!defaultActive.value && !defaultActive.label && props.optionDefault) {
      defaultActive.value = "";
      defaultActive.label = cutMax(props.optionDefault, 30);
    }

    setOptions(optionsData);
    setSelect(defaultActive);
  }, [props]);

  const marginOption = props.name === "status" && window.innerWidth > 1280 ? "-60px" : "0";

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#ffffff" : "#94a2b6",
      padding: "0 30px 0 30px",
      backgroundColor: state.isSelected ? "#e00c3c" : "#ffffff",
      transition: "all .2s ease",
      cursor: "pointer",
      textAlign: "left",
      height: 53,
      display: "flex",
      // borderBottom: '1px solid #f1f1f1',
      alignItems: "center",
      fontSize: "13px",
      lineHeight: "20px",
      position: "relative",
      whiteSpace: "nowrap",
      "&:hover": {
        color: "#ffffff",
        backgroundColor: "#e00c3c",
      },
      "&:last-child": {
        borderBottom: "none",
      },
      "&:after": {
        position: "absolute",
        content: '""',
        top: "0px",
        left: "30px",
        right: "30px",
        height: "1px",
        width: "auto",
        backgroundColor: "#f1f1f1",
        transition: "all .2s ease",
      },
      "&:hover:after": {
        backgroundColor: "#e00c3c",
      },
      "&:nth-of-type(1):after": {
        position: "absolute",
        content: '""',
        top: "0px",
        left: "30px",
        right: "30px",
        height: "1px",
        width: "0px",
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      border: "none",
      margin: 0,
      padding: 0,
      boxShadow: "none",
      borderRadius: 0,
      width: "auto",
      minWidth: "100%",
      "@media only screen and (max-width: 1200px)": {
        width: "100%",
      },
    }),
    menuList: (provided, state) => ({
      ...provided,
      border: "none",
      margin: 0,
      marginLeft: marginOption,
      padding: 0,
      maxHeight: "400px",
      boxShadow: "0px 10px 24px #F2F0FF",
    }),
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      height: "100%",
      minHeight: 47,
      border: "none",
      borderRadius: 0,
      backgroundColor: "#fffffff",
      display: "flex",
      alignItems: "center",
      outline: "none",
      margin: 0,
      padding: 0,
      cursor: "pointer",
      boxShadow: "none",
      "&:hover": {
        border: "none",
      },
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      margin: 0,
      padding: 0,
      height: "100%",
      display: "flex",
      alignItems: "center",
      overflow: "visible",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "16px",
      lineHeight: "19px",
      color: "#94a2b6",
      fontWeight: "500",
      margin: 0,
      padding: "0",
      opacity: state.isDisabled ? ".5" : "1",
      whiteSpace: "normal",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      paddingRight: props.paddingRight ? props.paddingRight : null,
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    container: (provided, state) => ({
      ...provided,
      height: "100%",
      overflow: "visible",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      // '& svg': {background: 'url(../../../images/icons/arrow-select-correct.svg) no-repeat right center'}
      color: "#ff4763",
      "&:hover": { color: "#2E2382" },
    }),
  };
  const cutMax = (string, length) => {
    return string.length > length ? string.substring(0, length) + "..." : string;
  };

  return (
    <div className={classes.Select}>
      <Select
        classNamePrefix="select"
        className="select-style"
        instanceId="select-style"
        value={select}
        isDisabled={!Object.keys(props.options).length}
        onChange={(active) => {
          const newValue = { ...active, label: cutMax(active.label, 30) };
          setSelect(newValue);

          props.onChange({
            target: {
              value: active.value,
            },
          });
        }}
        options={options}
        isSearchable={false}
        styles={customStyles}

        // onMenuOpen={() => {
        //     setTimeout(() => {
        //         const element = document.querySelector('.select__control--menu-is-open').parentNode.parentNode.parentNode
        //         element.setAttribute('data-zIndex', 'Y')
        //         element.style.zIndex = 10
        //     }, 10)
        // }}
        // onMenuClose={() => {
        //     document.querySelector('[data-zIndex="Y"]').removeAttribute('data-zIndex').style.zIndex = 1
        // }}
      />
    </div>

    // <div className={container}>
    //     <select
    //         name={props.name}
    //         onChange={props.onChange}
    //         value={selectedValue}
    //         disabled={!Object.keys(props.options).length}
    //         className={classes.select}
    //         location={''}
    //     >
    //         {props.optionDefault ? (
    //             <option value="">{props.optionDefault}</option>
    //         ) : null}
    //
    //         {Object.keys(props.options).length ?
    //             Object.keys(props.options).map((optionId, index) => {
    //                 const option = props.options[optionId]
    //
    //                 return (
    //                     <option
    //                         key={index}
    //                         value={option.id}
    //                     >{option.name}</option>
    //                 )
    //             })
    //             : null}
    //     </select>
    //
    //     <div className={bar} onClick={(event => openCloseSelectList(event))}>
    //         <div className={classes.selectBarText}>{selectedName}</div>
    //     </div>
    //
    //     <div className={classes.selectListContainer}>
    //         <ul className={classes.selectList}>
    //             {props.optionDefault ? (
    //                 <li onClick={(event) => onClickListItem(event)} datavalue="" className={classes.listItem}>{props.optionDefault} </li>
    //             ) : null}
    //
    //             {
    //                 _.isArray(optionTags) ? renderOptions(optionTags) : renderOptions(Object.keys(optionTags).map(item => optionTags[item]))
    //             }
    //         </ul>
    //     </div>
    // </div>
  );
};

export default SelectStyle;
