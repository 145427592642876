import cn from "classnames";
import { Russian } from "flatpickr/dist/l10n/ru";
import React from "react";
import Flatpickr from "react-flatpickr";

import classes from "./DateField.module.scss";

import IconCalendar from "../../../../icons/IconCalendar";

import "flatpickr/dist/flatpickr.min.css";

function DateField({
  rowId,
  value,
  onChangeFieldsRow,
  className,
  name,
  minDate,
  onSavePlan,
  typePlan,
}) {
  return (
    <div className={cn(classes.Wrap, className)}>
      <Flatpickr
        options={{
          dateFormat: "d.m.Y",
          locale: Russian,
          lang: "ru",
          minDate: new Date(minDate).fp_incr(1),
          disableMobile: true,
        }}
        value={value}
        onChange={([date]) => {
          onChangeFieldsRow(rowId, name, date, typePlan);
        }}
        placeholder={"__.__.____"}
        onDayCreate={[
          function (dObj, dStr, fp, dayElem) {
            let date = dayElem.dateObj;

            if (date.getDay() === 0 || date.getDay() === 6) {
              dayElem.className += " weekday";
            }
          },
        ]}
      />

      <IconCalendar />
    </div>
  );
}

export default DateField;
