import axios from "axios";
import React, { useContext, useState } from "react";

import "./ReplicaApprove.scss";

import config from "../../../../config/config";
import { PopupContext } from "../../../../context/popup/popupContext";
import Textarea from "../../../UI/Textarea/Textarea";

const ReplicaApprove = (props) => {
  const popupContextData = useContext(PopupContext);
  const [msg, setMsg] = useState("");

  const publicComment = async () => {
    popupContextData.setPreloader(true);

    const replicaId = props.id;
    const token = localStorage.getItem("token");

    const response = await axios.post(`${config.SERVER_BASE_URL}/replica/approve/${replicaId}`, {
      token,
      msg,
    });

    const responseData = response.data;

    if (responseData.status === "fail") {
      popupContextData.setPreloader(false);
      alert(responseData.errorText);
    } else {
      props.onSuccesUpdateRepica(replicaId, responseData.data);
      props.getMsg();
      popupContextData.setPreloader(false);
      props.close();
    }
  };

  return (
    <div className="replic__replic-unit-ticket-item replic__replic-unit-ticket-item_bg-white">
      <div className="replic__replic-unit-ticket-item-left-side">
        <div className="replic__replic-unit-ticket-item-left-side-text replic__replic-unit-ticket-item-left-side-text_green">
          Комментарий <br />
          клиента
        </div>
      </div>

      <div className="replic__replic-unit-ticket-item-right-side">
        <div className="replic__replic-unit-ticket-write-client-comment green">
          <div className="replic__replic-unit-ticket-write-client-comment-type-mobile">
            Комментарии клиента
          </div>

          <Textarea name="msg" value={msg} onChange={(event) => setMsg(event.target.value)} />

          <div className="replic__replic-unit-ticket-write-client-comment-buttons">
            <div className="replic__replic-unit-ticket-write-client-comment-button replic__replic-unit-ticket-write-client-comment-button_public_2">
              <button onClick={publicComment}>Одобрить</button>
            </div>

            <div className="replic__replic-unit-ticket-write-client-comment-button replic__replic-unit-ticket-write-client-comment-button_cancel">
              <button onClick={props.close}>Отменить</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReplicaApprove;
