import { Player } from "@lottiefiles/react-lottie-player";
import React from "react";

import "./Preloader.css";

const Preloader = () => {
  return (
    <div className="preloader">
      <div className="loadingio-spinner-spinner-xs9p966h829">
        <div className="ldio-tp6fo6z44zh">
          <Player
            src="https://assets7.lottiefiles.com/packages/lf20_sxc4z9tu.json"
            speed={1}
            style={{ width: "100px", height: "100px", background: "transparent" }}
            loop={true}
            controls={true}
            autoplay={true}></Player>
        </div>
      </div>
    </div>
  );
};

export default Preloader;
