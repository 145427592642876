// @ts-nocheck

import React, { useContext, useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { CSSTransition } from "react-transition-group";

import { apiUserCheckToken } from "./api/users";
import PopupNotice from "./components/PopupNotice/PopupNotice";
import Preloader from "./components/Preloader/Preloader";
import AdminClient from "./containers/AdminClient/AdminClient";
import AdminCompany from "./containers/AdminCompany/AdminCompany";
import AdminManager from "./containers/AdminManager/AdminManager";
import Analise from "./containers/Analise/Analise";
import Authorization from "./containers/Authorization/Authorization";
import Desktop from "./containers/Desktop/Desktop";
import Logout from "./containers/Logout/Logout";
import Replica from "./containers/Replica/Replica";
import Search from "./containers/Search/Search";
import { SearchHistory } from "./containers/SearchHistory";
import { SearchHistoryResults } from "./containers/SearchHistoryResults";
import { AuthContext } from "./context/auth/authContext";
import { PopupContext } from "./context/popup/popupContext";
import Layout from "./hoc/Layout/Layout";
import "./style/admin-company.scss";
import "./style/fonts.css";
import "./style/main.css";
import "./style/normalize.css";
import "./style/replica.scss";
import "./style/selectWithCounter.scss";
import { ROLE_ACCESS_LEVELS } from './constants/roles'
import { PERMITTED_COMPANIES_IDS } from './constants/permitted-companies-ids'

const checkAuth = () => {
  const token = localStorage.getItem("token");
  const tokenExp = new Date(localStorage.getItem("tokenExp"));

  if (token && tokenExp && tokenExp > new Date()) {
    return true;
  } else {
    return false;
  }
};

const logout = () => {
  localStorage.clear();
};

const App = () => {
  const popupContextData = useContext(PopupContext);
  const authContextData = useContext(AuthContext);
  const authResult = checkAuth();
  const [pageView, setPageView] = useState(false);
  const accessLevel = localStorage.getItem("accessLevel");

  const permittedCompaniesIds = [PERMITTED_COMPANIES_IDS.PIONEER, PERMITTED_COMPANIES_IDS.ALL_INSTRUMENTS_RU, PERMITTED_COMPANIES_IDS.BAIKAL_SERVICE_2023, PERMITTED_COMPANIES_IDS.GEMOTEST];

  if (authResult) {
    authContextData.auth = true;
  } else {
    logout();
  }

  const location = useLocation();

  useEffect(() => {
    if(location.pathname === '/search' || location.pathname === '/analise') {
      if(localStorage.getItem("user-company-id") === null) {
        logout();
        authContextData.setAuth(false);
        document.location.href = "/";
      }
    }
  });

  useEffect(() => {
    const checkTokenOnBack = async () => {
      const token = localStorage.getItem("token");

      if (token) {
        const result = await apiUserCheckToken(token);

        if (result.status === "success") {
          localStorage.setItem("user-company-id", result.companyId)
          authContextData.setAuth(true);
          setPageView(true);
        } else {
          logout();
          authContextData.setAuth(false);
          document.location.href = "/";
        }
      } else {
        logout();
        authContextData.setAuth(false);
        setPageView(true);
      }
    };

    checkTokenOnBack();

  }, []);

  let routes = (
    <Switch>
      <Route path={"/"} component={Authorization} exact />
      <Redirect to={"/"} exact />
    </Switch>
  );

  if (authContextData.auth) {
    if (accessLevel === String(ROLE_ACCESS_LEVELS.ADMINISTRATOR)) {
      routes = (
        <Switch>
          <Route path={"/desktop"} component={Desktop} />
          <Route path={"/replica"} component={Replica} />
          <Route path={"/analise"} component={Analise} />
          <Route path={"/search/history/:id"} component={SearchHistoryResults} />
          <Route path={"/search/history"} component={SearchHistory} />
          <Route path={"/search"} component={Search} />
          {/*<Route path={'/monitoring'} component={Monitoring} />*/}
          <Route path={"/admin/manager"} component={AdminManager} />
          <Route path={"/admin/client"} component={AdminClient} />
          <Route path={"/admin"} component={AdminCompany} update={"1"} />
          <Route path={"/logout"} component={Logout} />
          <Redirect to={"/desktop"} exact />
        </Switch>
      );
    } else if (accessLevel === String(ROLE_ACCESS_LEVELS.PROJECT_MANAGER)) {
      routes = (
        <Switch>
          <Route path={"/desktop"} component={Desktop} />
          <Route path={"/replica"} component={Replica} />
          <Route path={"/analise"} component={Analise} />
          <Route path={"/search/history/:id"} component={SearchHistoryResults} />
          <Route path={"/search/history"} component={SearchHistory} />
          <Route path={"/search"} component={Search} />
          {/*<Route path={'/monitoring'} component={Monitoring} />*/}
          <Route path={"/admin/client"} component={AdminClient} />
          <Route path={"/admin"} component={AdminCompany} update={"1"} />
          <Route path={"/logout"} component={Logout} />
          <Redirect to={"/desktop"} exact />
        </Switch>
      );
    } else if (accessLevel === String(ROLE_ACCESS_LEVELS.BRAND_MANAGER)) {
      routes = (
        <Switch>
          <Route path={"/desktop"} component={Desktop} />
          <Route path={"/replica"} component={Replica} />
          {permittedCompaniesIds.includes(localStorage.getItem("user-company-id")) && <Route path={"/search/history/:id"} component={SearchHistoryResults} />}
          {permittedCompaniesIds.includes(localStorage.getItem("user-company-id")) && <Route path={"/search/history"} component={SearchHistory} />}
          {permittedCompaniesIds.includes(localStorage.getItem("user-company-id")) && <Route path={"/search"} component={Search} />}
          {/*<Route path={'/monitoring'} component={Monitoring} />*/}
          <Route path={"/logout"} component={Logout} />
          <Redirect to={"/desktop"} exact />
        </Switch>
      );
    } else {
      routes = (
        <Switch>
          <Route path={"/desktop"} component={Desktop} />
          <Route path={"/replica"} component={Replica} />
          <Route path={"/analise"} component={Analise} />
          <Route path={"/search/history/:id"} component={SearchHistoryResults} />
          <Route path={"/search/history"} component={SearchHistory} />
          <Route path={"/search"} component={Search} />
          {/*<Route path={'/monitoring'} component={Monitoring} />*/}
          <Route path={"/logout"} component={Logout} />
          <Redirect to={"/desktop"} exact />
        </Switch>
      );
    }
  }

  return (
    <Layout>
      {pageView ? routes : null}

      <CSSTransition
        in={popupContextData.popupNotice.state}
        timeout={200}
        mountOnEnter={true}
        unmountOnExit={true}>
        {(state) => <PopupNotice className={state} />}
      </CSSTransition>

      <CSSTransition
        in={popupContextData.preloader}
        timeout={200}
        mountOnEnter={true}
        unmountOnExit={true}>
        {(state) => <Preloader className={state} />}
      </CSSTransition>
    </Layout>
  );
};

export default App;
