import React from "react";

import classes from "./TextTile.module.scss";

import InputEditText from "../../InputEditText/InputEditText";

export const TextTile = ({ activeEdit, onChangeStatusEdit, description, onChange, onBlur }) => {
  const isDescriptionContainsSymbols = ![...description].every((item) =>
    ["", " ", "\n"].includes(item),
  );
  return (
    <>
      <p className={classes.TileText}>
        {isDescriptionContainsSymbols || activeEdit === "description" ? null : (
          <p
            onClick={() => onChangeStatusEdit("description")}
            style={{
              fontWeight: 400,
              fontSize: 16,
              color: "#94A2B6",
              margin: 0,
            }}>
            Редактировать описание
          </p>
        )}

        {activeEdit === "description" ? (
          <InputEditText
            id={"description"}
            onChange={onChange}
            min={0}
            max={255}
            style={{
              fontWeight: 400,
              fontSize: 16,
              color: "#94A2B6",
              width: "100%",
            }}
            value={description}
            onBlur={onBlur}
          />
        ) : (
          <a
            onClick={() => {
              onChangeStatusEdit("description");
            }}
            className={classes.description}>
            {description}
          </a>
        )}
      </p>
    </>
  );
};
