import axios from "axios";
import React, { useContext, useState } from "react";

import "./ReplicaReject.scss";

import config from "../../../../config/config";
import { PopupContext } from "../../../../context/popup/popupContext";
import Textarea from "../../../UI/Textarea/Textarea";

const ReplicaReject = (props) => {
  const popupContextData = useContext(PopupContext);
  const [msg, setMsg] = useState("");

  const publicComment = async () => {
    if (msg.length === 0) {
      alert("Введите комментарий");
    } else {
      popupContextData.setPreloader(true);

      const replicaId = props.id;
      const token = localStorage.getItem("token");

      const response = await axios.post(`${config.SERVER_BASE_URL}/replica/reject/${replicaId}`, {
        token,
        msg,
      });

      const responseData = response.data;

      if (responseData.status === "fail") {
        popupContextData.setPreloader(false);
        alert(responseData.errorText);
      } else {
        popupContextData.setPreloader(false);
        props.onRejectReplica(replicaId, responseData.data);
        props.getMsg();
        props.close();
      }
    }
  };

  return (
    <div className="replic__replic-unit-ticket-item replic__replic-unit-ticket-item_bg-white">
      <div className="replic__replic-unit-ticket-item-left-side">
        <div className="replic__replic-unit-ticket-item-left-side-text replic__replic-unit-ticket-item-left-side-text_red">
          Комментарий <br />
          клиента
        </div>
      </div>

      <div className="replic__replic-unit-ticket-item-right-side">
        <div className="replic__replic-unit-ticket-write-client-comment">
          <div className="replic__replic-unit-ticket-write-client-comment-type-mobile">
            Комментарии клиента
          </div>

          <Textarea name="msg" value={msg} onChange={(event) => setMsg(event.target.value)} />

          <div className="replic__replic-unit-ticket-write-client-comment-buttons">
            <div className="replic__replic-unit-ticket-write-client-comment-button replic__replic-unit-ticket-write-client-comment-button_public">
              <button onClick={publicComment}>Опубликовать комментарий</button>
            </div>

            <div className="replic__replic-unit-ticket-write-client-comment-button replic__replic-unit-ticket-write-client-comment-button_cancel">
              <button onClick={props.close}>Отменить</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReplicaReject;
