import React, { useState } from "react";

import { AuthContext } from "./authContext";

export const AuthState = ({ children }) => {
  const [auth, setAuth] = useState(false);

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
