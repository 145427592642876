import axios from "axios";
import React, { useContext, useEffect, useState } from "react";

import List from "./List/List";
import classes from "./ScreenRating.module.scss";

import config from "../../../../config/config";
import { AnaliseContext } from "../../../../context/analise";
import NegativeBlockChart from "../../DesctopVersion/TabScreenRating/NegativeBlockChart/NegativeBlockChart";
import IconChecked from "../../icons/IconChecked";
import IconNegative from "../../icons/IconNegative";
import IconNeutral from "../../icons/IconNeutral";
import IconPending from "../../icons/IconPending";
import IconPositive from "../../icons/IconPositive";

function ScreenRating() {
  const { state, actions } = useContext(AnaliseContext);

  const [data, setData] = useState({});

  const handlerChangeActiveCharts = (name, value) => {
    const copyObj = { ...state.activeCharts, [name]: value };
    actions.onChangeState("activeCharts", copyObj);
  };

  const onGetData = async () => {
    try {
      const token = localStorage.getItem("token");

      const { data } = await axios.get(`${config.SERVER_BASE_URL}/analysis/rating`, {
        params: {
          token: token,
          query: state.filter.query,
          dateFrom: state.dateFrom,
          dateTo: state.dateTo,
        },
      });

      if (data?.status === "fail") {
        alert(data?.errorText?.split(",")?.join("\n"));
        return;
      }

      if (data?.status === "success") {
        setData(data);
      }
    } catch (err) {
      console.log("onGetData", err);
    }
  };

  useEffect(() => {
    if (state.filter.query) {
      onGetData();
    } else {
      setData({});
    }
  }, [state.dateFrom, state.dateTo, state.filter]);

  return (
    <section className={classes.Section}>
      <div className={classes.Checkboks}>
        <label htmlFor="positive" className={classes.CheckboksField}>
          <IconPositive className={classes.CheckboksIcon} />
          <input
            type="checkbox"
            checked={state.activeCharts.positive}
            name="positive"
            id="positive"
            onChange={(e) => handlerChangeActiveCharts("positive", e.currentTarget.checked)}
          />
          <IconChecked className={classes.CheckboksCheck} />
        </label>

        <label htmlFor="negative" className={classes.CheckboksField}>
          <IconNegative className={classes.CheckboksIcon} />
          <input
            type="checkbox"
            checked={state.activeCharts.negative}
            name="negative"
            id="negative"
            onChange={(e) => handlerChangeActiveCharts("negative", e.currentTarget.checked)}
          />
          <IconChecked className={classes.CheckboksCheck} />
        </label>

        <label htmlFor="neutral" className={classes.CheckboksField}>
          <IconNeutral className={classes.CheckboksIcon} />
          <input
            type="checkbox"
            checked={state.activeCharts.neutral}
            name="neutral"
            id="neutral"
            onChange={(e) => handlerChangeActiveCharts("neutral", e.currentTarget.checked)}
          />
          <IconChecked className={classes.CheckboksCheck} />
        </label>

        <label htmlFor="pending" className={classes.CheckboksField}>
          <IconPending className={classes.CheckboksIcon} />
          <input
            type="checkbox"
            checked={state.activeCharts.pending}
            name="pending"
            id="pending"
            onChange={(e) => handlerChangeActiveCharts("pending", e.currentTarget.checked)}
          />
          <IconChecked className={classes.CheckboksCheck} />
        </label>
      </div>

      <div className={classes.Inner}>
        <div className={classes.Header}>
          {/* TODO: сдедать компонент */}
          <div className={classes.Colors}>
            <p data-type="pending">Управляемое</p>

            <p data-type="positive">Позитив</p>

            <p data-type="neutral">Нерелевант</p>

            <p data-type="negative">Негатив</p>
          </div>
        </div>
      </div>

      <div className={classes.MainBlock}>
        {state.dateFrom && state.dateTo && data?.contentGraph?.length > 0 ? (
          <NegativeBlockChart data={data?.contentGraph || []} />
        ) : (
          <div className={classes.MainBlockText}>Данные отсутствуют</div>
        )}
      </div>

      {data?.content?.length > 0 && <List list={data?.content} data={data} onGetData={onGetData} />}
    </section>
  );
}

export default ScreenRating;
