import axios from "axios";

import config from "../config/config";

export const AUTOSAGGEST_URL = "https://api.test-orm.ru/analysis/search";

const systems = {
  1: "yandex",
  2: "yandex",
  3: "yandex",
  11: "google",
  12: "google",
};

export const apiCreateTaskId = async (toolName, brandName, findSystem, regionId, deep, region) => {
  const id = localStorage.getItem("id");

  const data = {
    toolName,
    brandName,
    findSystem,
    regionId,
    deep,
    id,
    regionName: region,
  };

  return await axios.post(`${config.SERVER_BASE_URL}/search-third-service/create-task-id`, data, {
    timeout: 60000,
  });
};

export const apiCheckTaskId = async (taskId) => {
  try {
    const params = {
      taskId,
    };

    const response = await axios.get(
      `${config.SERVER_BASE_URL}/search-third-service/check-task-id`,
      { params },
    );

    return response?.data?.data;
  } catch (e) {
    return null;
  }
};

export const apiGetAutoSaggest = async (searchRequest, searchSystem) => {
  const response = await axios.get(
    `${AUTOSAGGEST_URL}?name=${searchRequest}&system=${systems[searchSystem]}`,
  );
  if (response.status === 200) {
    return response.data.data;
  }
};
