import React from "react";

function IconChevronTop() {
  return (
    <svg width={12} height={8} viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.31802 3.73223L10.5858 8L12 6.58579L7.05025 1.63604L7.02513 1.66116L6.29289 0.928932L0.63604 6.58579L2.05025 8L6.31802 3.73223Z"
        fill="white"
      />
    </svg>
  );
}

export default IconChevronTop;
