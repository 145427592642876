import cn from "classnames";
import React, { useState } from "react";

import classes from "./Row.module.scss";

import DateField from "../../UI/DateField/DateField";
import RowInner from "../RowInner/RowInner";

function Row({
  item,
  isLast,
  onAddInnerRow,
  onDeleteInnerRow,
  onChangeFieldsInnerRow,
  onChangeFieldsRow,
  prevDateEnd,
}) {
  const [activeRow, setActiveRow] = useState(null);
  const [activeModalId, setActiveModalId] = useState(null);

  const onChangeActiveRow = (id) => {
    if (id === activeRow) {
      return setActiveRow(null);
    }

    setActiveRow(id);
  };

  return (
    <>
      <div
        className={cn(classes.Item, { [classes.Active]: activeRow === item.id })}
        data-id={item.id}>
        {/*<span className={classes.ItemNumber}>{num}.</span>*/}

        <div className={classes.Columns}>
          <div className={`${classes.ItemCol} ${classes.Date}`}>
            <div className={classes.DateField}>
              <DateField
                value={item.dateStart}
                onChangeFieldsRow={onChangeFieldsRow}
                rowId={item.id}
                name="dateStart"
                minDate={isLast ? prevDateEnd : null}
              />
            </div>

            <div className={classes.DateField}>
              <DateField
                value={item.dateEnd}
                onChangeFieldsRow={onChangeFieldsRow}
                rowId={item.id}
                name="dateEnd"
                minDate={item.dateStart}
              />
            </div>
          </div>
        </div>

        {item?.sites?.length > 0 && (
          <div className={classes.ItemControl} onClick={() => onChangeActiveRow(item.id)}>
            <span />
            <span />
            <span />
          </div>
        )}
      </div>

      {item?.sites?.length > 0 && (
        <div className={classes.ItemInner}>
          {item.sites.map((itemInner, index) => (
            <RowInner
              rowId={item.id}
              onActiveModalId={setActiveModalId}
              activeModalId={activeModalId}
              item={itemInner}
              key={index}
              isLast={item.sites.length - 1 === index}
              onAddInnerRow={onAddInnerRow}
              onDeleteInnerRow={onDeleteInnerRow}
              onChangeFieldsInnerRow={onChangeFieldsInnerRow}
            />
          ))}
        </div>
      )}
    </>
  );
}

export default Row;
