import React, { useContext, useState } from "react";

import "./ProjectAdd.scss";

import { apiProjectAdd, apiProjectAddPlan } from "../../../../../api/projects";
import { PopupContext } from "../../../../../context/popup/popupContext";
import DesktopPlanStart from "../../../../Desktop/DesktopPlanStart/DesktopPlanStart";

const ProjectAdd = (props) => {
  const popupContextData = useContext(PopupContext);
  const [projectName, setProjectName] = useState("");
  const [projectId, setProjectId] = useState(null);
  const [isShowPlan, setIsShowPlan] = useState(false);

  const projectAdd = async (event, companyId, projectName) => {
    event.preventDefault();

    popupContextData.setPreloader(true);
    const data = await apiProjectAdd(companyId, projectName);

    if (data.status === "fail") {
      popupContextData.setPreloader(false);
      alert(data.errorText);
    } else {
      props.getProjects();
      setProjectId(data.data._id);
      setIsShowPlan(true);
      popupContextData.setPreloader(false);
    }
  };

  const onCreatePlan = async (arr) => {
    if (projectId) {
      popupContextData.setPreloader(true);

      const newArr = arr.map((item) => {
        return {
          ...item,
          data: item.data.map((innerItem) => {
            return {
              ...innerItem,
              dateStart: innerItem.dateStart ? innerItem.dateStart.toLocaleDateString("ru-RU") : "",
              dateEnd: innerItem.dateEnd ? innerItem.dateEnd.toLocaleDateString("ru-RU") : "",
            };
          }),
        };
      });

      const data = await apiProjectAddPlan(projectId, JSON.stringify(newArr));

      if (data && data.status === "fail") {
        popupContextData.setPreloader(false);

        if (data?.errorText) {
          alert(data.errorText);
        }
      } else {
        props.getProjects();
        setProjectId(null);
        setIsShowPlan(false);
        props.close();
        popupContextData.setPreloader(false);
      }
    }
  };

  return (
    <div className="admin-company__project-manipulation admin-company__project-manipulation_creating">
      <div className="admin-company__project-manipulation-top-side">
        <div className="admin-company__project-manipulation-top-side-name">
          Создание нового проекта
        </div>
        <div
          className="admin-company__project-manipulation-top-side-button"
          onClick={props.close}
        />
      </div>

      <div className="admin-company__project-manipulation-content">
        <form action="#">
          <div className="admin-company__project-manipulation-input-container">
            <label htmlFor="">Название проекта:</label>
            <input
              type="text"
              id=""
              name="project-name"
              value={projectName}
              onChange={(event) => setProjectName(event.target.value)}
            />
          </div>

          {!isShowPlan && (
            <div className="admin-company__project-manipulation-buttons">
              <div className="admin-company__project-manipulation-button admin-company__project-manipulation-button_width-100 admin-company__project-manipulation-button_blue">
                <button onClick={(event) => projectAdd(event, props.companyId, projectName)}>
                  Создать
                </button>
              </div>
            </div>
          )}
        </form>
      </div>

      {isShowPlan && <DesktopPlanStart title="План" onCreatePlan={onCreatePlan} />}
    </div>
  );
};

export default ProjectAdd;
