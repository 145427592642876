import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import "./SearchHistory.styles.scss";

import {
  ISearchingQueriesParams,
  ISearchingQuery,
  fetchSearchingQueries,
} from "../../api/searching-queries";
import {
  BackButton,
  ResetButton,
  SearchHistoryEmpty,
  SearchHistoryInputs,
  SearchHistorySelector,
  SearchHistoryTable,
} from "../../components/SearchHistory";
import Pagination from "../../components/UI/Pagination/Pagination";
import { PopupContext } from "../../context/popup/popupContext";

export const SearchHistory = () => {
  const history = useHistory();
  const popupContextData = useContext(PopupContext);

  const [tableRowData, setTableRowData] = useState<ISearchingQuery[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [brandNameSearch, setBrandNameSearch] = useState<string>("");
  const [regionNameSearch, setRegionNameSearch] = useState<string>("");
  const [findSystem, setFindSystem] = useState<number | null>(null);
  const [deepSearch, setDeepSearch] = useState<number | null>(null);
  const [isResetButtonClicked, setResetButtonClicked] = useState<boolean>(false);
  const [isNoData, setIsNoData] = useState<boolean>(false);

  const handleOnClickBackButton = () => {
    history.push("/search");
  };

  const handleOnFetchingQueries = async (page: number) => {
    try {
      setIsNoData(false);
      popupContextData.setPreloader(true);

      const userId = localStorage.getItem("id");

      const params: ISearchingQueriesParams = {
        page: page || currentPage,
        userId: userId || "",
        limit: 10,
        brandName: brandNameSearch,
        regionName: regionNameSearch,
        findSystem: findSystem,
        deep: deepSearch,
      };

      const fetchQueries = await fetchSearchingQueries(params);

      if (fetchQueries) {
        setTableRowData(fetchQueries.searchingQueries);
        setTotalPages(fetchQueries.totalPages);
        setCurrentPage(fetchQueries.currentPage);
      }

      if (fetchQueries?.searchingQueries.length === 0) {
        setIsNoData(true);
      }
    } catch (error) {
      console.log(error);
      setIsNoData(true);
    } finally {
      popupContextData.setPreloader(false);
    }
  };

  const onChangePage = async (page: number) => {
    const pageCurrent = currentPage ? parseInt(String(currentPage)) : false;
    const pageNext = page ? parseInt(String(page)) : false;

    if (pageCurrent && pageNext && pageCurrent !== pageNext) {
      await handleOnFetchingQueries(page);
    }
  };

  const handleOnResetAllFilters = () => {
    setBrandNameSearch("");
    setRegionNameSearch("");
    setFindSystem(null);
    setDeepSearch(null);
    setResetButtonClicked(true);
  };

  useEffect(() => {
    (async () => {
      await handleOnFetchingQueries(1);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (isResetButtonClicked) {
        await handleOnFetchingQueries(1);
        setResetButtonClicked(false);
      }
    })();
  }, [isResetButtonClicked]);

  return (
    <div className="search-history">
      <div className="search-history-wrapper">
        <div>
          <BackButton onClick={handleOnClickBackButton} />
        </div>
        <div className="search-history-title">История запросов</div>
        <div className="search-history-all-filter-wrapper">
          <SearchHistoryInputs
            brandNameSearch={brandNameSearch}
            setBrandNameSearch={setBrandNameSearch}
            regionNameSearch={regionNameSearch}
            setRegionNameSearch={setRegionNameSearch}
            handleOnFetchingQueries={handleOnFetchingQueries}
          />
          <div className="search-history-selectors-wrapper">
            <SearchHistorySelector
              optionDefault="Система"
              value={findSystem}
              setValue={setFindSystem}
              handleOnFetchingQueries={handleOnFetchingQueries}
            />
            <SearchHistorySelector
              optionDefault="Глубина"
              value={deepSearch}
              setValue={setDeepSearch}
              handleOnFetchingQueries={handleOnFetchingQueries}
            />
            {(brandNameSearch || regionNameSearch || findSystem || deepSearch) && (
              <ResetButton onClick={handleOnResetAllFilters} />
            )}
          </div>
        </div>
        {isNoData && <SearchHistoryEmpty />}
        {!popupContextData.preloader && tableRowData.length !== 0 && (
          <div>
            <SearchHistoryTable tableRowData={tableRowData} />
            <div className="search-history-pagination">
              {/*
                            // @ts-ignore */}
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                separator="..."
                href={"search/history"}
                onClick={onChangePage}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
