export default {
  url: {
    id: "url",
    name: "URL",
    settings: {
      fieldType: "url",
      fieldTypeName: "URL",
      placeholder: "http://",
      disabled: true,
    },
  },
  select: {
    id: "select",
    name: "Список",
    settings: {
      fieldType: "select",
      fieldTypeName: "Список",
      placeholder: "Введите название списка",
      disabled: false,
    },
  },
  text: {
    id: "text",
    name: "Свободное поле",
    settings: {
      fieldType: "text",
      fieldTypeName: "Свободное поле",
      placeholder: "Введите название поля",
      disabled: false,
    },
  },
  note: {
    id: "note",
    name: "Примечание",
    settings: {
      fieldType: "note",
      fieldTypeName: "Примечание",
      placeholder: false,
      disabled: false,
    },
  },
};
