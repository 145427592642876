import anime from "animejs";
import axios from "axios";
import cn from "classnames";
import React, { useContext, useState } from "react";
import Select, { components } from "react-select";

import IconClose from "./IconClose";
import IconGeo from "./IconGeo";
import IconSearch from "./IconSearch";
import classes from "./MenuDesktop.module.scss";

import config from "../../../../config/config";
import { AnaliseContext } from "../../../../context/analise";
import {
  customStyles,
  customStyles2,
  customStyles3,
  customStyles4,
} from "../../../../utils/searchSelectStyle";
import QueryForm from "../../QueryForm/QueryForm";
import IconAdd from "../../icons/IconAdd";
import IconCheckedBig from "../../icons/IconCheckedBig";
import IconDownLoad from "../../icons/IconDownLoad";
import IconGoogle from "../../icons/IconGoogle";
import IconQuery from "../../icons/IconQuery";
import IconSearchSelect from "../../icons/IconSearchSelect";
import IconYandex from "../../icons/IconYandex";

const MenuDesktop = () => {
  const { state, actions } = useContext(AnaliseContext);
  const { onChangeFilter } = actions;
  const { filter } = state;

  const token = localStorage.getItem("token");

  const urlExportData = `${config.SERVER_BASE_URL}/analysis/search/export`;
  const urlExportDataParams = `?token=${token}&query=${filter.query}&dateFrom=${state.dateFrom}&dateTo=${state.dateTo}`;
  const urlExportDataJSX = urlExportData + urlExportDataParams;

  const isHiddenFrequency = state.projects?.find(
    (item) => item.id === filter.project?.value,
  )?.frequencyHide;

  const isShowFrequency = state.accessLevel !== 2 ? true : !isHiddenFrequency;

  const isDisabledTwoMenuRow =
    state.accessLevel === 2 ? filter?.company || filter?.project : filter?.project;

  const optionsCompanies = state.companies.map(({ id, name }) => {
    return { value: id, label: name };
  });

  const optionsProects = state.projects.map(({ id, name }) => {
    return { value: id, label: name };
  });

  const optionsSearch = state.queries.map(({ id, name, frequency }) => {
    return {
      value: id,
      label: (
        <div className={classes.SelectStaleBlock}>
          <div className={classes.SelectorOptionSearchSelect}>
            <div className={classes.Text}>{name}</div>
            <div className={classes.Frequency}>{isShowFrequency && frequency}</div>
          </div>
        </div>
      ),
    };
  });

  const optionsSearchTonality = [
    {
      value: "all",
      label: (
        <div className={classes.SelectStaleBlock}>
          <div className={classes.SelectorOptionSearchSelect}>
            <div className={classes.Text}>Все</div>
            <div className={classes.Frequency}></div>
          </div>
        </div>
      ),
    },
    ...optionsSearch,
  ];

  const optionsSearchTonalityResult =
    state.activeTabScreen === "tonality" ? optionsSearchTonality : optionsSearch;

  const optionsTop = [
    {
      value: "top10",
      label: (
        <div className={classes.TopSelector}>
          <div className={classes.Text} data-select-option="top-text">
            TOP:
          </div>

          <div className={classes.Num}>10</div>
        </div>
      ),
    },
    {
      value: "top20",
      label: (
        <div className={classes.TopSelector}>
          <div className={classes.Text} data-select-option="top-text">
            TOP:
          </div>

          <div className={classes.Num}>20</div>
        </div>
      ),
    },
    {
      value: "top30",
      label: (
        <div className={classes.TopSelector}>
          <div className={classes.Text} data-select-option="top-text">
            TOP:
          </div>

          <div className={classes.Num}>30</div>
        </div>
      ),
    },
  ];

  const optionsSystems = [
    {
      value: "google",
      label: (
        <div className={classes.SelectorOption}>
          <div className={classes.SelectorOptionIcon}>
            <IconGoogle />
          </div>
          Google
        </div>
      ),
    },
    {
      value: "yandex",
      label: (
        <div className={classes.SelectorOption}>
          <div className={classes.SelectorOptionIcon}>
            <IconYandex />
          </div>
          Яндекс
        </div>
      ),
    },
  ];

  const optionsSystemsTonality = [
    {
      value: "all",
      label: <div className={classes.SelectorOption}>Все</div>,
    },
    {
      value: "google",
      label: (
        <div className={classes.SelectorOption}>
          <div className={classes.SelectorOptionIcon}>
            <IconGoogle />
          </div>
          Google
        </div>
      ),
    },
    {
      value: "yandex",
      label: (
        <div className={classes.SelectorOption}>
          <div className={classes.SelectorOptionIcon}>
            <IconYandex />
          </div>
          Яндекс
        </div>
      ),
    },
  ];

  const optionsSystemsResult =
    state.activeTabScreen === "tonality" ? optionsSystemsTonality : optionsSystems;

  const [isOpenMenuRegion, setIsOpenMenuRegion] = useState(false);
  const [isOpenQueryForm, setIsOpenQueryForm] = useState(false);

  const [regionQuerySearch, setRegionQuerySearch] = useState("");
  const [resultRegionSearch, setResultRegionSearch] = useState([]);

  const resultRegionSearchJSX = regionQuerySearch ? resultRegionSearch : state.regions;

  const onChangeRegionQuerySearch = (e) => {
    setRegionQuerySearch(e.currentTarget.value);

    const newArr = state.regions.filter(({ name }) =>
      name.toLowerCase().includes(e?.currentTarget?.value?.toLowerCase()),
    );

    if (e.currentTarget?.value?.trim()) {
      setResultRegionSearch(newArr);
    } else {
      setResultRegionSearch(state.regions);
    }
  };

  const onToggleMenuRegion = () => {
    setIsOpenMenuRegion((prev) => !prev);

    const menu = document.querySelector(".js-analise-regionmenu");
    const body = document.querySelector("body");

    if (!isOpenMenuRegion) {
      anime({
        targets: menu,
        duration: 650,
        easing: "cubicBezier(0.25, 0.1, 0.25, 1)",
        right: ["-400px", 0],
      });

      menu.style.pointerEvents = "visible";
      body.classList.add("no-scroll");
    } else {
      anime({
        targets: menu,
        duration: 650,
        easing: "cubicBezier(0.25, 0.1, 0.25, 1)",
        right: [0, "-400px"],
      });

      menu.style.pointerEvents = "none";
      body.classList.remove("no-scroll");
    }
  };

  const onChangeIsOpenQueryForm = (value) => {
    setIsOpenQueryForm(value);
  };

  const halperChangeRegion = (value) => {
    if (value === state.filter.region) {
      onToggleMenuRegion();
    } else {
      onChangeFilter("region", value);
      onToggleMenuRegion();
    }
  };

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className={classes.NoOptionsMessage}>Список запросов пуст</span>
      </components.NoOptionsMessage>
    );
  };

  const onChangeIsHiddenFrequency = async (value) => {
    try {
      const newValue = !isHiddenFrequency ? "on" : "off";

      const { data } = await axios.post(`${config.SERVER_BASE_URL}/analysis/frequency/`, {
        token: token,
        project: filter.project?.value,
        action: newValue,
      });

      if (data?.status === "fail") {
        alert(data?.errorText?.split(",")?.join("\n"));
        return;
      }

      if (data?.status === "success") {
        actions.onGetData();
      }
    } catch (err) {
      console.log("onChangeIsHiddenFrequency", err);
    }
  };

  return (
    <div className={classes.MenuDesktop}>
      <div
        className={cn(classes.Overlay, { [classes.Active]: isOpenQueryForm || isOpenMenuRegion })}
      />

      <div className={classes.FilterRowOne}>
        {state.accessLevel !== 2 && (
          <div className={classes.CompanySelector}>
            <Select
              placeholder="Компания"
              options={optionsCompanies}
              value={state.filter.company}
              isSearchable={false}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "inherit",
                  primary: "inherit",
                },
              })}
              styles={customStyles}
              onChange={(obj) => {
                onChangeFilter("company", obj);
              }}
            />
          </div>
        )}

        <div className={classes.ProjectSelector}>
          <Select
            options={optionsProects}
            value={filter.project}
            isSearchable={false}
            isDisabled={!filter?.company && state.accessLevel !== 2}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: "inherit",
                primary: "inherit",
              },
            })}
            styles={customStyles}
            onChange={(obj) => {
              onChangeFilter("project", obj);
            }}
            placeholder="Проект"
          />
        </div>

        <a
          href={urlExportDataJSX}
          target="_blank"
          rel="noopener noreferrer"
          className={cn(classes.Unloading, {
            [classes.Active]:
              state?.activeTabScreen === "search" && state?.contentScreenSearch?.length,
          })}>
          <IconDownLoad />
          <span>Выгрузить данные</span>
        </a>
      </div>

      <div className={cn(classes.FilterRowTwo, { [classes.Active]: isDisabledTwoMenuRow })}>
        {isOpenQueryForm && <QueryForm onChangeIsOpenQueryForm={onChangeIsOpenQueryForm} />}

        <div className={classes.DeepLevelSelector}>
          <Select
            options={optionsTop}
            isSearchable={false}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: "inherit",
                primary: "inherit",
              },
            })}
            styles={customStyles2}
            classNamePrefix="analise-select-top"
            value={optionsTop.find((item) => item.value === filter.deepLevel) || optionsTop[0]}
            onChange={({ value }) => {
              onChangeFilter("deepLevel", value);
            }}
          />
        </div>

        <div className={classes.AllSystemsSelector}>
          <Select
            options={optionsSystemsResult}
            isSearchable={false}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: "inherit",
                primary: "inherit",
              },
            })}
            styles={customStyles4}
            value={optionsSystemsResult.find((item) => item.value === filter.searchSystem) || false}
            onChange={({ value }) => {
              onChangeFilter("searchSystem", value);
            }}
            placeholder={
              <div className={classes.SelectPlaceholder}>
                <IconSearchSelect />
                Поисковая система
              </div>
            }
          />
        </div>

        <div className={classes.RegionBtn} onClick={onToggleMenuRegion}>
          <IconGeo className={classes.IconGeo} />
          <span className={classes.RegionValue}>{filter?.region || "Регион"}</span>

          <div className={classes.RegionDots}>
            <span />
            <span />
            <span />
          </div>
        </div>

        <div
          className={cn(classes.RegionMenu, " js-analise-regionmenu ", {
            [classes.Active]: isOpenMenuRegion,
          })}>
          <div className={classes.RegionMenuHeader}>
            <IconGeo className={classes.RegionMenuIconGeo} />
            <span className={classes.RegionMenuTitle}>Регион</span>

            <button className={classes.RegionMenuClose} onClick={onToggleMenuRegion}>
              <IconClose />
            </button>
          </div>

          <div className={classes.RegionMenuSearch}>
            <IconSearch />
            <input
              type="text"
              value={regionQuerySearch}
              onChange={onChangeRegionQuerySearch}
              placeholder="Введите название"
            />
          </div>

          <ul className={classes.RegionMenuList}>
            {resultRegionSearchJSX.map((item, index) => (
              <li
                className={cn({ [classes.Active]: item.name === state.filter.region })}
                onClick={() => {
                  halperChangeRegion(item.name);
                }}
                key={index}>
                {item.name}
                {item.name === state.filter.region && <IconCheckedBig />}
              </li>
            ))}
          </ul>
        </div>

        <div className={classes.AllSearchInquiryWrapper}>
          <Select
            options={state.queries?.length > 0 ? optionsSearchTonalityResult : []}
            isSearchable={false}
            // isDisabled={isDisabledQuerySelector}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: "inherit",
                primary: "inherit",
              },
            })}
            components={{ NoOptionsMessage }}
            styles={customStyles3}
            value={optionsSearchTonalityResult.find((item) => item.value === filter.query) || false}
            onChange={({ value }) => {
              onChangeFilter("query", value);
            }}
            placeholder={
              <div className={classes.AllSearchSelectPlaceholder}>
                <IconQuery />
                Поисковые запросы
              </div>
            }
          />

          <div className={classes.Frequency}>
            {isShowFrequency &&
              (state.queries?.find((item) => item.id === filter?.query)?.frequency || "Частотн.")}
          </div>
        </div>

        {state.accessLevel !== 2 && (
          <div
            className={classes.ButtonShow}
            data-active={isHiddenFrequency ? "Y" : null}
            onClick={onChangeIsHiddenFrequency}
          />
        )}

        {state.accessLevel !== 2 && (
          <div className={classes.AddBtn} onClick={() => onChangeIsOpenQueryForm(true)}>
            <IconAdd />

            <span>
              Поисковые <br /> запросы
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default MenuDesktop;
