import React from "react";

import classes from "./ProjectSettingsFieldNote.module.scss";

export default function ProjectSettingsFieldNote(props) {
  return (
    <div className={classes.ProjectSettingsFieldNote}>
      <div className={classes.FieldCheckbox}>
        <input
          type="checkbox"
          id={`in-replica-${props.id}`}
          value="Y"
          onChange={props.changeCheckbox}
          checked={props.viewReplica}
        />

        <label htmlFor={`in-replica-${props.id}`} />
      </div>

      <div className={classes.FieldType} data-agent={props.value}>
        {props.fieldTypeName}
        {/*класс ниже не используется*/}
        <span className="search-and-settings__field-type-icon" data-icon={props.value} />
      </div>

      <div className={classes.FieldValue}>
        <div className={classes.FieldMobile}>{props.fieldTypeName}</div>

        <div className={classes.FieldText}>
          <input
            type="checkbox"
            name={props.name}
            value={props.value}
            onChange={props.change}
            checked={props.checked}
            id={`checked-${props.id}`}
          />

          <label htmlFor={`checked-${props.id}`} data-agent={props.value}>
            <div className={classes.Client}>Для Клиента</div>
            <div className={classes.Icon}></div>
            <div className={classes.Agent}>Для Агентства</div>
          </label>

          <div
            className={`${classes.FieldButtonRemove} search-and-settings__field-button-remove_alone`}
            onClick={props.delete}
          />
        </div>
      </div>
    </div>
  );
}
