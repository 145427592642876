import React from "react";

import classes from "./Analise.module.scss";
import DesctopVersion from "./DesctopVersion/DesctopVersion";
import MobileVersion from "./MobileVersion/MobileVersion";

import { AnaliseProvider } from "../../context/analise";

const Analise = () => {
  return (
    <div className={classes.Analise}>
      <AnaliseProvider>
        <div className={classes.Inner}>
          <div className={classes.Title}>Анализ поисковой выдачи</div>

          {window?.innerWidth > 767 && <DesctopVersion />}
          {window?.innerWidth < 768 && <MobileVersion />}
        </div>
      </AnaliseProvider>
    </div>
  );
};

export default Analise;
