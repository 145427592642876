import React from "react";

import "./Select.scss";

const Select = (props) => {
  const options =
    props.options && props.options !== undefined && Object.keys(props.options).length
      ? props.options
      : {};

  return (
    <select
      name={props.name}
      onChange={props.onChange}
      value={props.value}
      disabled={(options && !Object.keys(options).length) || props.disabled}>
      {props.optionDefault ? <option value="">{props.optionDefault}</option> : null}

      {Object.keys(options).length
        ? Object.keys(options).map((optionId, index) => {
            const option = options[optionId];

            return (
              <option key={index} value={option.id}>
                {option.name}
              </option>
            );
          })
        : null}
    </select>
  );
};

export default Select;
