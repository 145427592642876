import React from "react";

function IconGeo({ className }) {
  return (
    <svg
      className={className}
      width={22}
      height={26}
      viewBox="0 0 22 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.3111 5.14509L17.3106 5.14459C14.0351 1.86897 8.70899 1.86889 5.43327 5.14434C2.15646 8.39091 2.18869 13.7172 5.43252 16.9916L5.43329 16.9924L11.266 22.7951L11.3718 22.9003L11.4776 22.7951L17.3103 16.9924L17.3111 16.9916C20.5559 13.7161 20.5559 8.42068 17.3111 5.14509ZM11.3719 25.455C11.1121 25.455 10.8494 25.3515 10.6361 25.1382L3.96153 18.4637C-0.128999 14.3732 -0.128948 7.70345 3.96153 3.61292C8.05201 -0.477613 14.6917 -0.477614 18.7822 3.61292C22.8727 7.7034 22.8727 14.3731 18.7822 18.4637L12.1076 25.1382C11.8943 25.3515 11.6316 25.455 11.3719 25.455Z"
        fill="#2E2382"
        stroke="white"
        strokeWidth="0.3"
      />
      <mask id="path-2-inside-1_1520_4545" fill="white">
        <path d="M11.3712 15.7612C13.7765 15.7612 15.7306 13.807 15.7306 11.4017C15.7306 8.99639 13.7765 7.04224 11.3712 7.04224C8.96588 7.04224 7.01172 8.99639 7.01172 11.4017C7.01172 13.807 8.96588 15.7612 11.3712 15.7612ZM11.3712 9.44738C12.4535 9.44738 13.3253 10.3192 13.3253 11.4015C13.3253 12.4839 12.4535 13.3557 11.3712 13.3557C10.2888 13.3557 9.41702 12.4839 9.41702 11.4015C9.41702 10.3192 10.2888 9.44738 11.3712 9.44738Z" />
      </mask>
      <path
        d="M11.3712 15.7612C13.7765 15.7612 15.7306 13.807 15.7306 11.4017C15.7306 8.99639 13.7765 7.04224 11.3712 7.04224C8.96588 7.04224 7.01172 8.99639 7.01172 11.4017C7.01172 13.807 8.96588 15.7612 11.3712 15.7612ZM11.3712 9.44738C12.4535 9.44738 13.3253 10.3192 13.3253 11.4015C13.3253 12.4839 12.4535 13.3557 11.3712 13.3557C10.2888 13.3557 9.41702 12.4839 9.41702 11.4015C9.41702 10.3192 10.2888 9.44738 11.3712 9.44738Z"
        fill="#2E2382"
      />
      <path
        d="M11.3712 16.0612C13.9422 16.0612 16.0306 13.9727 16.0306 11.4017H15.4306C15.4306 13.6413 13.6108 15.4612 11.3712 15.4612V16.0612ZM16.0306 11.4017C16.0306 8.83071 13.9422 6.74224 11.3712 6.74224V7.34224C13.6108 7.34224 15.4306 9.16208 15.4306 11.4017H16.0306ZM11.3712 6.74224C8.80019 6.74224 6.71172 8.83071 6.71172 11.4017H7.31172C7.31172 9.16208 9.13156 7.34224 11.3712 7.34224V6.74224ZM6.71172 11.4017C6.71172 13.9727 8.80019 16.0612 11.3712 16.0612V15.4612C9.13156 15.4612 7.31172 13.6413 7.31172 11.4017H6.71172ZM11.3712 9.74738C12.2879 9.74738 13.0253 10.4849 13.0253 11.4015H13.6253C13.6253 10.1535 12.6192 9.14738 11.3712 9.14738V9.74738ZM13.0253 11.4015C13.0253 12.3182 12.2879 13.0557 11.3712 13.0557V13.6557C12.6192 13.6557 13.6253 12.6496 13.6253 11.4015H13.0253ZM11.3712 13.0557C10.4545 13.0557 9.71702 12.3182 9.71702 11.4015H9.11702C9.11702 12.6496 10.1231 13.6557 11.3712 13.6557V13.0557ZM9.71702 11.4015C9.71702 10.4849 10.4545 9.74738 11.3712 9.74738V9.14738C10.1231 9.14738 9.11702 10.1535 9.11702 11.4015H9.71702Z"
        fill="white"
        mask="url(#path-2-inside-1_1520_4545)"
      />
    </svg>
  );
}

export default IconGeo;
