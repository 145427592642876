import cn from "classnames";
import React from "react";

import classes from "./DeleteRow.module.scss";

function DeleteRow({ onClick, isMini, className }) {
  return (
    <div
      className={cn(classes.ItemDelete, className, { [classes.Mini]: isMini })}
      onClick={onClick}>
      <span />
    </div>
  );
}

export default DeleteRow;
