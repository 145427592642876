import React, { useEffect, useState } from "react";

import classes from "./CustomTilesBlock.module.scss";

import Tile from "../Tile/Tile";

export const CustomTilesBlock = ({
  onTapUploadFiles,
  onChange,
  tiles,
  deleteTile,
  onRemoveFile,
}) => {
  const [tileData, setTileData] = useState([]);

  useEffect(() => {
    const newTilesData = [];

    tiles.length > 0 &&
      tiles.forEach((el) => {
        newTilesData.push({
          id: el._id,
          name: el.name,
          description: el.description,
          documents: el.documents,
          type: el.type,
          url: el.url,
        });
      });

    setTileData(newTilesData);
  }, [tiles]);

  return (
    <div className={classes.CustomTiles}>
      {tileData &&
        tileData.length > 0 &&
        tileData.map((el) => (
          <Tile
            key={el.id}
            data={el}
            onTapUploadFiles={() => onTapUploadFiles(el.id)}
            onChange={onChange}
            type="custom"
            deleteTile={deleteTile}
            onRemoveFile={onRemoveFile}
          />
        ))}
    </div>
  );
};
