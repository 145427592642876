import cn from "classnames";
import React, { useRef } from "react";

import classes from "./FileField.module.scss";

import { useWindowSize } from "../../../../../hooks/useWindowSize";
import { IconTrash } from "../../../../../icons/IconTrash";

export const FileField = ({
  border,
  disable,
  documents,
  onSelectDocuments,
  onRemoveDocument,
  multipleMedia = false,
}) => {
  const id = useRef();
  const { width } = useWindowSize();

  return documents.length === 0 ? (
    <div className={classes.Field}>
      {width && width > 767 && <span className={classes.FieldTitle}>Файл:</span>}

      <div
        className={cn(classes.Input, {
          [classes.FieldInputBorderAll]: border === "all",
          [classes.FieldInputBorderLeft]: border === "left",
          [classes.FieldInputBorderRight]: border === "right",
          [classes.Disabled]: disable,
        })}>
        <span>Выберете файлы</span>
        <label className={cn(classes.FieldInput, { [classes.DisabledInput]: false })}>
          <input
            multiple={multipleMedia}
            style={{
              display: "none",
            }}
            accept={".doc,.docx,.xml,application/msword,.pdf"}
            type="file"
            // disabled={(file && accessLevel !== 1) || disable}
            onChange={onSelectDocuments}
          />
          <span>Обзор</span>
        </label>
      </div>
    </div>
  ) : (
    documents.map((doc) => (
      <div key={String(doc.file.name)} className={classes.Field}>
        {width && width > 767 && <span className={classes.FieldTitle}>Файл:</span>}

        <div
          className={cn(classes.Input, {
            [classes.FieldInputBorderAll]: border === "all",
            [classes.FieldInputBorderLeft]: border === "left",
            [classes.FieldInputBorderRight]: border === "right",
            [classes.Disabled]: false,
          })}>
          <span>{doc.file.name}</span>
          <label
            htmlFor={id.current}
            className={cn(classes.FieldInput)}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              e.currentTarget.data = undefined;

              onRemoveDocument(doc.file.name);
            }}>
            <input
              multiple={multipleMedia}
              style={{
                display: "none",
              }}
              accept={".doc,.docx,.xml,application/msword,.pdf"}
              type="file"
              id={id.current}
              // disabled={(file && accessLevel !== 1) || disable}
              // onChange={(e) => onSelectDocuments(e.target.files)}
            />
            <span>
              <IconTrash className={classes.TrashIcon} />
            </span>
          </label>
        </div>
      </div>
    ))
  );
};
