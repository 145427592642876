import cn from "classnames";
import React, { useState } from "react";
import Select from "react-select";

import classes from "./DownloadDropDown.module.scss";
import IconDownload from "./IconDownload";

const MyOption = (props) => {
  const { innerProps, innerRef, isSelected, isFocused, isDisabled } = props;

  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={cn(classes.CustomOption, {
        [classes.Active]: isSelected,
        [classes.Focus]: isFocused,
        [classes.Disabled]: isDisabled,
      })}>
      <div className={classes.OptionValue}>{props.data.label} </div>
      <span className={classes.OptionCount}>{props.data.count}</span>
    </div>
  );
};

function DownloadDropDown({ options, onClick, isOpen, onClose }) {
  const [currentTypeDownload, setCurrentTypeDownload] = useState(options[0]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#ffffff" : "#94a2b6",
      padding: "0 30px 0 30px",
      backgroundColor: state.isSelected ? "#e00c3c" : "#ffffff",
      transition: "all .2s ease",
      cursor: "pointer",
      textAlign: "left",
      height: 53,
      display: "flex",
      alignItems: "center",
      fontSize: "13px",
      lineHeight: "20px",
      position: "relative",
      whiteSpace: "nowrap",

      "&:hover": {
        color: "#ffffff",
        backgroundColor: "#e00c3c",
      },

      "&:after": {
        position: "absolute",
        content: '""',
        top: "0px",
        left: "30px",
        right: "30px",
        height: "1px",
        width: "auto",
        transition: "all .2s ease",
        backgroundColor: state.isSelected ? "#E00C3C" : "#f1f1f1",
      },

      "&:hover:after": {
        backgroundColor: "#e00c3c",
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      border: "none",
      margin: 0,
      padding: 0,
      boxShadow: "none",
      borderRadius: 0,
      width: "auto",
      minWidth: "100%",

      "&:after": {
        position: "absolute",
        zIndex: 999,
        content: '""',
        top: "-8px",
        left: "0px",
        height: "8px",
        width: "100%",
        backgroundColor: "#F2F5F9",
        transition: "all .2s ease",
        opacity: 1,
      },

      "@media only screen and (max-width: 1200px)": {
        width: "100%",
      },
    }),

    menuList: (provided, state) => ({
      ...provided,
      border: "none",
      margin: 0,
      padding: 0,
      maxHeight: "400px",
      boxShadow: "0px 10px 24px rgba(139, 134, 174, 0.3)",
    }),

    control: (provided, state) => ({
      ...provided,
      width: "100%",
      height: "100%",
      minHeight: 60,
      border: "none",
      backgroundColor: "#fffffff",
      display: "flex",
      alignItems: "center",
      outline: "none",
      margin: 0,
      padding: 0,
      cursor: "pointer",
      boxShadow: "none",
      "&:hover": {
        border: "none",
      },
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      margin: 0,
      padding: 0,
      height: "100%",
      display: "flex",
      alignItems: "center",
      overflow: "visible",
      paddingLeft: 20,
    }),

    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "16px",
      lineHeight: "19px",
      color: "#505D6F",
      fontWeight: "500",
      margin: 0,
      padding: "0",
      opacity: state.isDisabled ? ".5" : "1",
      whiteSpace: "normal",
    }),

    indicatorsContainer: (provided, state) => ({
      ...provided,
    }),

    indicatorContainer: (provided, state) => ({
      ...provided,
      padding: 0,
    }),

    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),

    container: (provided, state) => ({
      ...provided,
      height: 60,
      width: "100%",
      overflow: "visible",
      backgroundColor: "#F2F5F9",
      borderRadius: !state.menuIsOpen ? "0px 0px 0px 10px" : 0,
    }),

    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "#ff4763",
      "&:hover": { color: "#2E2382" },
    }),
  };

  return (
    <div className={`${classes.Wrapper} ${isOpen ? classes.Open : "close"}`} id="DownloadDropDown">
      <div className={classes.Inner}>
        <div className={classes.Header}>
          <IconDownload />
          Выгрузить
        </div>

        <div className={classes.SelectWrap}>
          <Select
            classNamePrefix="select"
            className="DownloadDropDown"
            instanceId="DownloadDropDown"
            onChange={(obj) => setCurrentTypeDownload(obj)}
            options={[
              { value: "0", label: "Категория", count: "Реплики", disabled: true },
              ...options,
            ]}
            value={currentTypeDownload}
            isSearchable={false}
            styles={customStyles}
            components={{ Option: MyOption }}
            isOptionDisabled={(option) => option.disabled}
            placeholder="Все реплики по фильтру"
          />
        </div>

        <a className={classes.BtnUpload} onClick={() => onClick(currentTypeDownload)}>
          <IconDownload className={classes.BtnIcon} />
          Выгрузить
        </a>

        {/*<button className={classes.BtnUpload} onClick={() => onClick(currentTypeDownload)}>*/}
        {/*    <IconDownload className={classes.BtnIcon} />*/}
        {/*    Выгрузить*/}
        {/*/!*</button>*!/*/}

        {/*<button className={classes.BtnClose} onClick={onClose}>*/}
        {/*    <IconClose className={classes.IconClose} />*/}
        {/*</button>*/}
      </div>
    </div>
  );
}

export default DownloadDropDown;
