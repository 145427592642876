import React from "react";

export default ({ id, value, onChange, onBlur, style, min = 0, max = 255, className }) => (
  <textarea
    minLength={min}
    maxLength={max}
    {...(id === "description" && { rows: "6", cols: "19" })}
    onChange={(e) => onChange(id, e.target.value)}
    autoFocus={true}
    ref={(ref) => ref && ref.focus()}
    onFocus={(e) =>
      e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)
    }
    name={id}
    style={style}
    value={value}
    onBlur={onBlur}
    className={className}
  />
);
