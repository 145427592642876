import React from "react";
import { Link } from "react-router-dom";

import "./NavTabs.scss";

const NavTabs = (props) => {
  const accessLevel = localStorage.getItem("accessLevel");
  const clsClient = ["select-pages__links-block-button"];
  const clsManager = ["select-pages__links-block-button"];

  if (props.active === "client") {
    clsClient.push("active");
  }

  if (props.active === "manager") {
    clsManager.push("active");
  }

  return (
    <div className="select-pages__links-block">
      <div className={clsClient.join(" ")}>
        <Link to="/admin/client">Клиенты</Link>
      </div>

      {accessLevel === "1" ? (
        <div className={clsManager.join(" ")}>
          <Link to="/admin/manager">Агентство</Link>
        </div>
      ) : null}
    </div>
  );
};

export default NavTabs;
