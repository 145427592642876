import axios from "axios";
import React, { useContext, useState } from "react";

import IconDelete from "./IconDelete";
import IconUpload from "./IconUpload";
import classes from "./QueryForm.module.scss";

import config from "../../../config/config";
import { AnaliseContext } from "../../../context/analise";
import IconGoogle from "../icons/IconGoogle";
import IconYandex from "../icons/IconYandex";

const QueryForm = ({ onChangeIsOpenQueryForm }) => {
  const { state, actions } = useContext(AnaliseContext);

  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);

  const onSubmitFile = async (e) => {
    const file = e.currentTarget.files[0];

    if (file) {
      setIsLoading(true);

      try {
        const token = localStorage.getItem("token");

        const bodyFormData = new FormData();

        bodyFormData.append("token", token);
        bodyFormData.append("file", file);

        const { data } = await axios.post(
          `${config.SERVER_BASE_URL}/analysis/import-data`,
          bodyFormData,
        );

        if (data?.status === "fail") {
          alert(data?.text?.join("\n"));
          return;
        }

        if (data?.status === "success") {
          setList(data?.data);
        }

        setIsLoading(false);
      } catch (err) {
        console.log("onSubmitFile", err);
        setIsLoading(false);
      }
    }
  };

  const onSave = async (e) => {
    setIsLoading(true);

    try {
      const token = localStorage.getItem("token");

      const { data } = await axios.post(`${config.SERVER_BASE_URL}/analysis/queries`, {
        token: token,
        project: state.filter.project?.value,
        data: JSON.stringify(list),
      });

      setIsLoading(false);

      if (data?.status === "fail") {
        alert(data?.errorText?.split(",")?.join("\n"));
        return;
      }

      if (data?.status === "success") {
        onChangeIsOpenQueryForm(false);
        actions.onGetData();
      }
    } catch (err) {
      console.log("onSave", err);
      setIsLoading(false);
    }
  };

  const onChangeList = (rowIndex, value) => {
    const newArr = list.map((item, index) => {
      if (index === rowIndex) {
        return { ...item, frequency: value };
      }

      return item;
    });

    setList(newArr);
  };

  const onDeleteRow = (rowIndex) => {
    const newArr = list.filter((item, index) => index !== rowIndex);
    setList(newArr);
  };

  return (
    <div className={classes.QueryForm} data-show="N">
      <div className={classes.Top}>
        <div className={classes.Title}>
          {window.innerWidth > 767 ? "Добавление поисковых запросов" : "Добавление поиск. запр."}
        </div>

        <div className={classes.ButtonBack} onClick={() => onChangeIsOpenQueryForm(false)} />
      </div>

      <div className={classes.MainSection}>
        <div className={classes.TableTitle}>
          Добавленные поисковые запросы:
          {/* <button className={classes.BtnFrequency} onClick={() => console.log(1)}>
                        <svg width={25} height={20} viewBox='0 0 25 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M21.9026 19.0245C22.1368 18.7895 22.1368 18.4095 21.9026 18.1755L18.223 14.4985C18.212 14.4865 18.201 14.4755 18.19 14.4655L12.8942 9.1755L10.7726 7.0555C10.7706 7.0545 10.7696 7.0535 10.7676 7.0515L3.88572 0.1755C3.65055 -0.0585 3.26928 -0.0585 3.03711 0.1755C2.80294 0.4095 2.80294 0.7895 3.03711 1.0235L6.13733 4.1205C4.09587 5.2055 2.36963 6.7865 1.11273 8.7365C0.778495 9.2605 0.778495 9.9375 1.11474 10.4645C3.61953 14.3375 7.86356 16.6495 12.4699 16.6495C14.2611 16.6495 16.0014 16.3045 17.6486 15.6225L21.054 19.0245C21.1711 19.1415 21.3252 19.2005 21.4783 19.2005C21.6314 19.2005 21.7865 19.1415 21.9026 19.0245ZM20.6492 13.8876C21.9041 12.9396 22.9729 11.7876 23.8265 10.4616C24.1607 9.93756 24.1607 9.26156 23.8245 8.73456C21.3197 4.86156 17.0757 2.54956 12.4694 2.54956C11.6878 2.54956 10.8942 2.62056 10.1117 2.75856C9.78445 2.81656 9.5683 3.12856 9.62534 3.45456C9.68438 3.78156 9.9926 3.99756 10.3218 3.94056C11.0343 3.81356 11.7579 3.74956 12.4694 3.74956C16.6654 3.74956 20.5341 5.85656 22.8138 9.38256C22.8988 9.51656 22.8988 9.68356 22.8148 9.81556C22.0392 11.0196 21.0665 12.0686 19.9237 12.9316C19.6605 13.1306 19.6075 13.5076 19.8076 13.7716C19.9267 13.9276 20.1048 14.0096 20.287 14.0096C20.4141 14.0096 20.5411 13.9696 20.6492 13.8876ZM10.0674 9.59937C10.0674 10.9224 11.1452 11.9994 12.4701 11.9994C12.9064 11.9994 13.3237 11.8834 13.69 11.6664L10.4016 8.38037C10.1835 8.74637 10.0674 9.16437 10.0674 9.59937ZM14.5563 12.5325L16.7219 14.6955C15.3609 15.1965 13.9339 15.4495 12.4699 15.4495C8.27385 15.4495 4.40508 13.3425 2.12545 9.81751C2.04039 9.68251 2.04039 9.51551 2.12345 9.38451C3.33532 7.50651 5.02553 6.00251 7.02996 5.01251L9.53375 7.51451C9.10044 8.12051 8.86627 8.84251 8.86627 9.59951C8.86627 11.5845 10.4834 13.1995 12.4699 13.1995C13.2284 13.1995 13.9509 12.9655 14.5563 12.5325Z'
                                fill='#2E2382'
                            />
                        </svg>
                        Скрыть частотность
                    </button> */}
        </div>

        <div className={classes.TableHeader}>
          <div className={classes.TableCol} data-type="date">
            Дата:
          </div>

          <div className={classes.TableCol} data-type="request">
            {window.innerWidth > 1280 ? "Поисковый запрос:" : "Запрос:"}
          </div>

          <div className={classes.TableCol} data-type="system">
            {window.innerWidth > 1280 ? "Поисковая система:" : "ПС:"}
          </div>

          <div className={classes.TableCol} data-type="size">
            {window.innerWidth > 1280 ? "Глубина выгрузки:" : "ТОП:"}
          </div>

          <div className={classes.TableCol} data-type="region">
            Регион:
          </div>

          <div className={classes.TableCol} data-type="frequency">
            {window.innerWidth > 1280 ? "Частотность:" : "Частот.:"}
          </div>

          <div className={classes.TableCol} data-type="delete" />
        </div>

        <div className={classes.TableList}>
          {list.map((item, index) => (
            <div className={classes.TableRow} key={index}>
              <div className={classes.TableCol} data-type="date">
                {item?.date}
              </div>

              <div className={classes.TableCol} data-type="request">
                {item?.searchQuery}
              </div>

              <div className={classes.TableCol} data-type="system">
                {window.innerWidth > 1280 ? (
                  item?.searchSystem
                ) : (
                  <>
                    {item?.searchSystem === "Яндекс" && <IconYandex />}
                    {item?.searchSystem === "Google" && <IconGoogle />}
                  </>
                )}
              </div>

              <div className={classes.TableCol} data-type="size">
                {window.innerWidth > 1280 ? item?.type : item?.type.split(" ")[1]}
              </div>

              <div className={classes.TableCol} data-type="region">
                {item?.region}
              </div>

              <div className={classes.TableCol} data-type="frequency">
                <input
                  type="text"
                  value={item?.frequency}
                  onChange={(e) => onChangeList(index, e.currentTarget.value)}
                />
              </div>

              <div className={classes.TableCol} data-type="delete">
                <IconDelete className={classes.IconDelete} onClick={() => onDeleteRow(index)} />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={classes.ButtonBlock}>
        {list.length > 0 ? (
          <button className={classes.CancelBtn} onClick={() => onChangeIsOpenQueryForm(false)}>
            Очистить
          </button>
        ) : (
          <div className={classes.AddButton}>
            <input
              className={classes.AddInput}
              type="file"
              accept=".csv,.xlsx"
              name={"name"}
              id={"name"}
              onChange={onSubmitFile}
              placeholder={"Загрузить поисковые запросы"}
            />

            <label className={classes.AddNewInput} htmlFor={"name"}>
              <span className={classes.AddLabel}>
                <IconUpload />
                {!isLoading ? "Загрузить поисковые запросы" : "Загружаем файл"}
              </span>
            </label>
          </div>
        )}

        {/* <ButtonTable name='Сохранить' onClick={saveHandler} /> */}

        <button className={classes.BtnSave} onClick={onSave}>
          Сохранить
        </button>

        <button className={classes.BtnCancel} onClick={() => onChangeIsOpenQueryForm(false)}>
          Отменить
        </button>
      </div>
    </div>
  );
};

export default QueryForm;
