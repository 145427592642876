import React from "react";

import classes from "./RowInner.module.scss";

import IconCloseMini from "../../../../icons/IconCloseMini";
import IconComment from "../../../../icons/IconComment";
import IconNegative from "../../../../icons/IconNegative";
import IconNeutral from "../../../../icons/IconNeutral";
import IconPending from "../../../../icons/IconPending";
import IconPositive from "../../../../icons/IconPositive";
import IconSend from "../../../../icons/IconSend";

function RowInner({ item, onAddComment, onDeleteComment, newComment, onChangeNewComment }) {
  return (
    <div className={classes.Wrap} data-tonality={item.info.tonalityCode}>
      <div className={classes.Row}>
        <div className={classes.Col}>
          <div className={classes.ColTitle}>Тональность</div>

          <div className={classes.ColValue}>
            {item.info.tonalityCode === "positive" && <IconPositive />}
            {item.info.tonalityCode === "negative" && <IconNegative />}
            {item.info.tonalityCode === "neutral" && <IconNeutral />}
            {item.info.tonalityCode === "pending" && <IconPending />}
            {item.info.tonality}
          </div>
        </div>

        <div className={classes.Col}>
          <div className={classes.ColTitle}>
            Количество размещенных реплик в категории <b>официальный представитель</b>
          </div>

          <div className={classes.ColValue}>{item?.info.representative}</div>
        </div>

        <div className={classes.Col}>
          <div className={classes.ColTitle}>
            Количество размещенных реплик в категории <b>агент влияния</b>
          </div>

          <div className={classes.ColValue}>{item?.info.agent}</div>
        </div>
      </div>

      {item?.comments?.length > 0 && (
        <div className={classes.Comments}>
          {item?.comments.map((comment, commentIndex) => (
            <div className={classes.Comment} key={commentIndex}>
              <div className={classes.CommentHeader}>
                <span>{comment.date}</span>
                <span>{comment.name}</span>
              </div>

              <div className={classes.CommentText}>
                {comment.text}

                <button
                  className={classes.CommentDelete}
                  onClick={() => onDeleteComment(comment.id)}>
                  <IconCloseMini />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className={classes.TextField}>
        <IconComment className={classes.IconComment} />

        <input type="text" value={newComment} onChange={onChangeNewComment} />

        <button className={classes.BtnSend} onClick={() => onAddComment(item.id)}>
          <IconSend className={classes.IconSend} />
        </button>
      </div>
    </div>
  );
}

export default RowInner;
