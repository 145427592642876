import cn from "classnames";
import React from "react";

import classes from "./TextField.module.scss";

export const TextField = ({ border, disable, fullWidth, value, onChange }) => {
  return (
    <div className={cn(classes.Field, { [classes.FullWidth]: fullWidth })}>
      <span className={classes.FieldTitle}>Название папки:</span>
      <input
        type="text"
        value={value}
        className={cn(classes.FieldInput, {
          [classes.FieldInputBorderAll]: border === "all",
          [classes.FieldInputBorderLeft]: border === "left",
          [classes.FieldInputBorderRight]: border === "right",
        })}
        disabled={disable}
        onChange={onChange}
      />
    </div>
  );
};
