import cn from "classnames";
import React, { useState } from "react";

import IconClose from "./IconClose";
import IconCopy from "./IconCopy";
import IconLink from "./IconLink";
import IconPencil from "./IconPencil";
import classes from "./UrlDropDown.module.scss";

function isValidURL(str) {
  var a = document.createElement("a");
  a.href = str;
  return a.host && a.host !== window.location.host;
}

function UrlDropDown({
  href,
  isOpen,
  onClose,
  onChangeUrlInReplica,
  replicaId,
  onChangeSelectedItem,
}) {
  const [isEdit, setIsEdit] = useState(false);
  const [newUrl, setNewUrl] = useState("");

  const accessLevel = localStorage.getItem("accessLevel");

  const onChangeIsEdit = (value) => {
    setIsEdit(value);
  };

  const onChangeNewUrl = (e) => {
    setNewUrl(e.currentTarget.value);
  };

  const handlerOnChangeUrlInReplica = () => {
    onChangeUrlInReplica(replicaId, newUrl);
    onChangeIsEdit(false);
    onChangeSelectedItem(false);
  };

  return (
    <div className={cn(classes.Wrapper, { [classes.Open]: isOpen })}>
      <div className={classes.Inner}>
        {!isEdit ? (
          <div className={cn(classes.Field, { [classes.IsEdit]: isEdit })}>
            {/* <a href={href} target='_blank' rel='noopener noreferrer'>{href}</a> */}
            <span className={classes.Href}>{href}</span>
          </div>
        ) : (
          <input
            className={cn(classes.Field, { [classes.IsEdit]: isEdit })}
            value={newUrl}
            onChange={onChangeNewUrl}
          />
        )}

        {!isEdit && (
          <div className={classes.Control}>
            <button
              className={cn(classes.BtnCopy, { [classes.Disabled]: !isValidURL(href) })}
              onClick={() => {
                navigator.clipboard.writeText(href);
                onClose();
              }}>
              <IconCopy />
              Копировать
            </button>

            <a
              className={cn(classes.BtnGoToLink, { [classes.Disabled]: !isValidURL(href) })}
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              onClick={onClose}>
              <IconLink />
              Перейти
            </a>

            {+accessLevel !== 2 && (
              <span
                className={cn(classes.BtnEdit)}
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => onChangeIsEdit(true)}>
                <IconPencil />
                Редактировать
              </span>
            )}
          </div>
        )}

        {isEdit && (
          <div className={classes.EditControl}>
            <button className={classes.EditBtn} onClick={() => onChangeIsEdit(false)}>
              Отменить
            </button>

            <button
              className={classes.EditBtn}
              onClick={handlerOnChangeUrlInReplica}
              disabled={!newUrl}>
              Сохранить
            </button>
          </div>
        )}

        <button className={classes.Close} onClick={onClose}>
          <IconClose />
        </button>
      </div>
    </div>
  );
}

export default UrlDropDown;
