import React from "react";

function IconPencil({ className }) {
  return (
    <svg
      className={className}
      width={17}
      height={17}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 16.4H6.4L16.4 6.4L10 0L0 10V16.4ZM5.6 14.4H2V10.8L5.6 14.4ZM3.4 9.4L7 13L11.1 8.9L7.5 5.3L3.4 9.4ZM12.5 7.5L8.9 3.9L10 2.8L13.6 6.4L12.5 7.5Z"
        fill="#D0DDEF"
      />
    </svg>
  );
}

export default IconPencil;
