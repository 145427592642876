import React from "react";

function IconChevronDown({ className }) {
  return (
    <svg
      className={className}
      width={11}
      height={7}
      viewBox="0 0 11 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 4.22487L9.63108 -1.19675e-07L11 1.4L6.20878 6.3L6.18446 6.27513L5.47568 7L-1.22392e-07 1.4L1.36892 -8.41976e-07L5.5 4.22487Z"
        fill="#505D6F"
      />
    </svg>
  );
}

export default IconChevronDown;
