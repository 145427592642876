import React from "react";

function IconCheckedBig({ className }) {
  return (
    <svg
      className={className}
      width={22}
      height={15}
      viewBox="0 0 22 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M1.5 6.5L8.5 13.5" stroke="white" strokeWidth={2} strokeLinecap="round" />
      <path d="M20.5 1.5L8.5 13.5" stroke="white" strokeWidth={2} strokeLinecap="round" />
    </svg>
  );
}

export default IconCheckedBig;
