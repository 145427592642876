import React from "react";

import "./SearchHistoryInputs.styles.scss";
import { ISearchHistoryInputsProps } from "./SearchHistoryInputs.types";

import { SearchHistoryInput } from "../SearchHistoryInput";

export const SearchHistoryInputs = ({
  brandNameSearch,
  setBrandNameSearch,
  regionNameSearch,
  setRegionNameSearch,
  handleOnFetchingQueries,
}: ISearchHistoryInputsProps) => {
  return (
    <div className="search-history-inputs">
      <SearchHistoryInput
        value={brandNameSearch}
        setValue={setBrandNameSearch}
        placeholder="Введите фразу и нажмите Enter"
        handleOnFetchingQueries={handleOnFetchingQueries}
      />
      <SearchHistoryInput
        value={regionNameSearch}
        setValue={setRegionNameSearch}
        placeholder="Введите регион и нажмите Enter"
        handleOnFetchingQueries={handleOnFetchingQueries}
      />
    </div>
  );
};
