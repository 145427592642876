import cn from "classnames";
import React, { useEffect, useState } from "react";

import "./CompanyList.scss";

import CompanyItem from "../CompanyItem/CompanyItem";

const CompanyList = (props) => {
  const [items, setItems] = useState([]);
  const [isOpenAtLeastOne, setIsOpenAtLeastOne] = useState(false);

  const onCheckIsOpenAtLeastOne = (list) => {
    const newList = list.filter((item) => item.openCompany);

    if (!newList.length) {
      setIsOpenAtLeastOne(false);
    }
  };

  const onToogleAllOpen = () => {
    const newList = Object.values(props.companies).map((item) => {
      return { ...item, openCompany: !isOpenAtLeastOne };
    });

    setIsOpenAtLeastOne((prev) => !prev);
    setItems(newList);
  };

  const onToogleById = (id) => {
    setIsOpenAtLeastOne(true);

    const newList = items.map((item) => {
      if (item._id === id) {
        return { ...item, openCompany: !item.openCompany };
      }

      return item;
    });

    onCheckIsOpenAtLeastOne(newList);
    setItems(newList);
  };

  useEffect(() => {
    const items = Object.values(props.companies);
    setItems(items);
  }, [props.companies]);

  return (
    <>
      <div className="admin-company__top-side">
        <div className="admin-company__top-side-cell">Название компании:</div>

        <div
          className={cn("admin-company__top-side-cell__allOpen", { isOpen: isOpenAtLeastOne })}
          onClick={onToogleAllOpen}>
          {isOpenAtLeastOne ? "Свернуть все" : "Развернуть все"}
          <span>
            <svg
              width={12}
              height={8}
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.68198 4.26777L1.41421 0L0 1.41421L4.94975 6.36396L4.97487 6.33883L5.70711 7.07107L11.364 1.41421L9.94975 0L5.68198 4.26777Z"
                fill="white"
              />
            </svg>
          </span>
        </div>
      </div>

      <div className="admin-company__container">
        {items.map((item, index) => (
          <CompanyItem
            key={index}
            id={item._id}
            name={item.name}
            active={item.active}
            getCompanies={props.getCompanies}
            filter={props.filter}
            openCompany={item.openCompany}
            onToogleById={onToogleById}
          />
        ))}
      </div>
    </>
  );
};

export default CompanyList;
