import React, { useContext, useState } from "react";

import "./CompanyEdit.scss";

import { apiCompanyEdit } from "../../../../../api/companies";
import { PopupContext } from "../../../../../context/popup/popupContext";

const CompanyEdit = (props) => {
  const accessLevel = localStorage.getItem("accessLevel");
  const popupContextData = useContext(PopupContext);
  const [companyName, setCompanyName] = useState(props.name);

  const companyEdit = async (event, companyId, companyName) => {
    event.preventDefault();

    popupContextData.setPreloader(true);
    const data = await apiCompanyEdit(companyId, companyName);

    if (data.status === "fail") {
      popupContextData.setPreloader(false);
      alert(data.errorText);
    } else {
      props.close();
      props.getCompanies();
    }
  };

  return (
    <div className="admin-company__company-manipulation admin-company__company-manipulation_editing">
      <div className="admin-company__company-manipulation-top-side">
        <div className="admin-company__company-manipulation-top-side-name">
          Редактирование компании
        </div>

        <div
          className="admin-company__company-manipulation-top-side-button"
          onClick={props.close}
        />
      </div>

      <div className="admin-company__company-manipulation-content">
        <div className="admin-company__company-manipulation-input-container">
          <label htmlFor={`company-name-${props.id}`}>Название компании:</label>

          <input
            type="text"
            id={`company-name-${props.id}`}
            value={companyName}
            onChange={(event) => setCompanyName(event.target.value)}
          />
        </div>

        <div className="admin-company__company-manipulation-buttons">
          <div className="admin-company__company-manipulation-button admin-company__company-manipulation-button_mobile-width-124px admin-company__company-manipulation-button_blue">
            <button onClick={(event) => companyEdit(event, props.id, companyName)}>
              Сохранить
            </button>
          </div>

          {accessLevel === "1" ? (
            <div className="admin-company__company-manipulation-button admin-company__company-manipulation-button_mobile-width-124px admin-company__company-manipulation-button_red">
              <button onClick={props.deleteShow}>Удалить</button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CompanyEdit;
