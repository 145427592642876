import React from "react";

import "./RoleItem.scss";

import UserList from "../UserList/UserList";

const RoleItem = (props) => {
  return (
    <div className="admin-company__company-item admin-company__company-item_clients-mode admin-company__company-item_active">
      <div className="admin-company__company-item-bar" onClick={() => props.onToggleRole(props.id)}>
        <div className="admin-company__company-item-name">{props.name}</div>
      </div>

      {props.openRole ? <UserList roleId={props.id} filter={props.filter} /> : null}
    </div>
  );
};

export default RoleItem;
