import cn from "classnames";
import propTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";

import { CustomTile } from "./CustomTile/CustomTile";
import { TextTile } from "./TextTile/TextTile";
import classes from "./Tile.module.scss";

import { PopupContext } from "../../../context/popup/popupContext";
import InputEditText from "../InputEditText/InputEditText";
import ListMedia from "../ListMedia/ListMedia";

const ACTIVE_EDIT = {
  default: null,
  name: "name",
  description: "description",
};

const MAX_FILE_SIZE = 30000000;

const Tile = ({ type, onChange, data, deleteTile, onTapUploadFiles, onRemoveFile }) => {
  const [editInput, setEditInput] = useState(ACTIVE_EDIT.default);
  const [name, setName] = useState(data?.name ?? "");
  const [description, setDescription] = useState(data?.description ?? "");
  const [documents, setDocuments] = useState([]);
  const popupContextData = useContext(PopupContext);
  const id = useRef();
  const isDescriptionContainsSymbols = [...description].every((item) => [" ", "\n"].includes(item));

  const onSelectDocuments = (e) => {
    const media = e.target.files[0];

    if (e.target.files[0].size > MAX_FILE_SIZE) {
      popupContextData.setPopupNotice({
        state: true,
        title: "Ошибка",
        text: "Максимальный размер файла - 30MB",
      });

      return;
    }

    const result = [];

    result.push({
      name: "",
      file: {
        size: media.size,
        name: media.name,
        type: media.type,
        file: media,
        path: "",
        id: media.id || "1",
      },
    });

    onChange(
      type === "custom" ? "blocksCustom" : "blocksSystem",
      data.id,
      result,
      description,
      name,
    );
    setEditInput(null);
  };

  const onSaveData = () => {
    if (name.length < 3 || name.length > 255) {
      alert("Название должно быть не меньше 3 символов и не больше 255.");
      setName(data.name);
    } else if ([...name].every((item) => [" ", "\n"].includes(item))) {
      alert("Введите называние папки");
    } else if (description.length > 1) {
      if (description.length <= 5) {
        alert("Описание должно превышать 5 символов");
        return;
      }

      if (isDescriptionContainsSymbols) {
        setDescription(data.description);
        alert("Введите корректное описание");
        return;
      }
    }

    onChange(
      type === "custom" ? "blocksCustom" : "blocksSystem",
      data.id,
      documents,
      description,
      name,
    );
    setEditInput(null);
  };

  const onChangeStatusEdit = (fieldId) => setEditInput(fieldId);

  useEffect(() => {
    id.current = Math.floor(Math.random() * 1000000);
  }, []);

  useEffect(() => {
    setDocuments(data?.documents ?? []);
  }, [data?.documents]);

  const handleRemoveFile = ({ path, fileId }) => {
    onRemoveFile({
      path,
      fileId,
      blockId: data.id,
    });
  };

  const handleDefaultTitle = () => {
    return editInput === "name" ? (
      <InputEditText
        id="name"
        min={3}
        max={100}
        onChange={(id, value) => setName(value)}
        onBlur={onSaveData}
        value={name}
        className={classes.inputDefaultTitle}
      />
    ) : (
      <div onClick={() => onChangeStatusEdit("name")} className={classes.TileTitle}>
        {name}
      </div>
    );
  };

  const onChangeInput = (id, value) => {
    if (id === "name") {
      setName(value);
    }
    if (id === "description") {
      setDescription(value);
    }
  };

  return (
    <div
      className={cn(classes.Tile, {
        [classes.DefaultTile]: type !== "custom",
      })}>
      <div>
        {data.documents.length === 1 || documents.length === 1 ? null : (
          <label htmlFor={id.current} style={{ cursor: "pointer" }}>
            <input
              className={classes.fileInput}
              accept={".doc,.docx,.xml,application/msword,.pdf"}
              type="file"
              id={id.current}
              onChange={onSelectDocuments}
            />
            <span className={classes.iconUploadImage} onClick={() => onTapUploadFiles()}>
              {" "}
            </span>
          </label>
        )}

        {type === "custom" && (
          <span className={classes.iconRemoveFolder} onClick={() => deleteTile(data.id)}>
            {" "}
          </span>
        )}

        <div className={classes.Icon}></div>
        {type !== "custom" && handleDefaultTitle()}

        {["text", "documents"].includes(type) && (
          <TextTile
            activeEdit={editInput}
            onBlur={onSaveData}
            onChangeStatusEdit={onChangeStatusEdit}
            documents={documents}
            description={description}
            onChange={onChangeInput}
            onRemoveFile={handleRemoveFile}
          />
        )}

        {type === "custom" && (
          <CustomTile
            onBlur={onSaveData}
            activeEdit={editInput}
            onChangeStatusEdit={onChangeStatusEdit}
            name={name}
            description={description}
            onChange={onChangeInput}
          />
        )}
      </div>
      {!!documents?.length && <ListMedia documents={documents} onRemoveFile={handleRemoveFile} />}
    </div>
  );
};

Tile.propTypes = {
  type: propTypes.string,
  data: propTypes.object,
  deleteTile: propTypes.func,
  onChange: propTypes.func,
  onTapUploadFiles: propTypes.func,
  onRemoveFile: propTypes.func,
};

export default Tile;
