import React from "react";

import "./Loading.scss";

const Loading = () => (
  <div className="element-loading" style={{ display: "none" }}>
    Загрузка...
  </div>
);

export default Loading;
