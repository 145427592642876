import React from "react";

function IconCalendar() {
  return (
    <svg width={20} height={21} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2221 2.58904H15.5558V0.967773H14.4442V2.58904H5.55579V0.967773H4.44421V2.58904H2.77789C1.24584 2.58904 0 3.80119 0 5.29182V18.265C0 19.7556 1.24584 20.9678 2.77789 20.9678H17.2221C18.7542 20.9678 20 19.7556 20 18.265V7.99461V6.9141V5.29182C20 3.8022 18.7542 2.58904 17.2221 2.58904ZM18.8897 18.265C18.8897 19.1592 18.1424 19.8863 17.2234 19.8863H2.77814C1.85911 19.8863 1.11182 19.1592 1.11182 18.265V7.99463H18.8897V18.265ZM1.11035 6.9142H18.8893V5.29192C18.8893 4.39774 18.142 3.67065 17.223 3.67065H15.5566V4.75217H14.4451V3.67065H5.55561V4.75217H4.44299V3.67065H2.77667C1.85765 3.67065 1.11035 4.39774 1.11035 5.29192V6.9142Z"
        fill="#E00C3C"
      />
    </svg>
  );
}

export default IconCalendar;
