import React from "react";

import "./UserAddInputs.scss";

import Input from "../../../../UI/Input/Input";

const UserAddInputs = (props) => {
  return (
    <div className={props.cls}>
      <label htmlFor="">{props.label}:</label>

      <Input
        type={props.type}
        name={props.name}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.fieldChange}
      />
    </div>
  );
};

export default UserAddInputs;
