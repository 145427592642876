import axios from "axios";
import React, { useContext, useState } from "react";

import classes from "./PopupSubscribeTg.module.scss";

import config from "../../../../config/config";
import { PopupContext } from "../../../../context/popup/popupContext";
import IconInfo from "../../../../icons/IconInfo";

function PopupSubscribeTg({ projectId, updateReplicaList }) {
  const { onCloseModal, setPreloader } = useContext(PopupContext);

  const [value, setValue] = useState("");

  const onSubmit = async () => {
    onCloseModal();

    try {
      setPreloader(true);
      const token = localStorage.getItem("token");

      const { data } = await axios.post(`${config.SERVER_BASE_URL}/telegram/code/`, {
        token,
        project: projectId,
        code: value,
      });

      if (data.status === "fail") {
        setPreloader(false);
        alert(data?.errorText);
      } else {
        onCloseModal();
        updateReplicaList();
      }
    } catch (error) {
      setPreloader(false);
      console.log(error);
    }
  };

  return (
    <div className={classes.Wrapper}>
      <div className={classes.Inner}>
        <div className={classes.Header}>
          <IconInfo />
          Подписка на уведомления о смене статуса реплик
        </div>

        <div className={classes.Content}>
          <p className={classes.Text}>
            Чтобы подписаться на уведомления о смене статуса реплик, перейдите в наш{" "}
            <a href="https://t.me/ORM_AMDG_bot" target="_blank" rel="noopener noreferrer">
              telegram бот
            </a>{" "}
            и запустите его. Далее отправьте команду subscribe, бот отправит вам код. Введите
            полученный код в поле ниже в данном окне и нажмите кнопку подписаться.
          </p>

          <input
            className={classes.Input}
            type="text"
            value={value}
            onChange={(e) => setValue(e.currentTarget.value)}
          />
        </div>

        <div className={classes.Control}>
          <button onClick={onSubmit} disabled={!value.trim()}>
            Подписаться
          </button>
        </div>
      </div>
    </div>
  );
}

export default PopupSubscribeTg;
