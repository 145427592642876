import React, { useContext, useState } from "react";

import "./UserDelete.scss";

import { apiUsersInProjectDelete } from "../../../../../api/users-in-project";
import { PopupContext } from "../../../../../context/popup/popupContext";
import Input from "../../../../UI/Input/Input";

const UserDelete = (props) => {
  const popupContextData = useContext(PopupContext);
  const [msg, setMsg] = useState("");
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  const deleteLink = async (linkId) => {
    popupContextData.setPreloader(true);
    const data = await apiUsersInProjectDelete(linkId, msg);

    if (data.status === "fail") {
      popupContextData.setPreloader(false);
      alert(data.errorText);
    } else {
      props.getLinks();
      props.close();
    }
  };

  return (
    <div className="admin-company__user-manipulation admin-company__user-manipulation_removing">
      <div className="admin-company__user-manipulation-top-side">
        <div className="admin-company__user-manipulation-top-side-name">
          Исключить пользователя из рабочей группы проекта
        </div>

        <div className="admin-company__user-manipulation-top-side-button" onClick={props.close} />
      </div>

      {!openDeleteConfirm ? (
        <div className="admin-company__user-manipulation-content">
          <div className="admin-company__user-manipulation-text">
            Вы действительно хотите исключить пользователя{" "}
            <span>
              {props.userLastName} {props.userName} - {props.userRole}
            </span>{" "}
            из рабочей группы?
          </div>

          <div className="admin-company__user-manipulation-buttons">
            <div className="admin-company__user-manipulation-button admin-company__user-manipulation-button_mobile-width-71px admin-company__user-manipulation-button_blue">
              <button onClick={props.close}>Нет</button>
            </div>

            <div className="admin-company__user-manipulation-button admin-company__user-manipulation-button_mobile-width-71px admin-company__user-manipulation-button_red">
              <button onClick={() => setOpenDeleteConfirm(true)}>Да</button>
            </div>
          </div>
        </div>
      ) : null}

      {openDeleteConfirm ? (
        <div className="admin-company__user-manipulation-reason-content">
          <div className="admin-company__user-manipulation-reason-text">
            <span>Укажите причину!</span>
            <br /> Без указания причины пользователь не может быть исключен!
          </div>

          <div className="admin-company__user-manipulation-reason-field">
            <Input
              type="text"
              name="msg"
              value={msg}
              onChange={(event) => setMsg(event.target.value)}
              className="admin-company__project-manipulation-reason-input"
            />

            <button
              className="admin-company__user-manipulation-reason-button"
              onClick={() => deleteLink(props.id)}>
              Подтвердить удаление
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default UserDelete;
