import React, { useContext, useEffect, useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import "./NegativeBlockChart.css";
import classes from "./NegativeBlockChart.module.scss";

import { AnaliseContext } from "../../../../../context/analise";

const colorsNmae = {
  negative: "Негатив",
  neutral: "Нерелевант",
  pending: "Управляемое",
  positive: "Позитив",
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload?.length) {
    return (
      <div className={classes.Tooltip}>
        <p className={classes.TooltipDate}>{label}</p>

        {payload.map((item, index) => (
          <p className={classes.TooltipItem} key={index} style={{ color: `${item.color}` }}>
            {`${colorsNmae[item.dataKey]} : ${item.value}`}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

function NegativeBlockChart({ data }) {
  const { state } = useContext(AnaliseContext);

  const [result, setResult] = useState([]);

  useEffect(() => {
    if (data?.length) {
      const newArr = [];

      data.forEach((item) => {
        const [day, month, year] = item.date.split(".");
        const dateResult = `${day}.${month}.${year.substring(2)}`;

        newArr.push({
          name: dateResult,
          pending: item.pending,
          neutral: item.neutral,
          positive: item.positive,
          negative: item.negative,
        });
      });

      setResult(newArr);
    }
  }, [data]);

  return (
    <div className={classes.Wrap}>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={result}
          margin={{
            top: 20,
            right: 40,
            left: window.innerWidth > 767 ? 20 : 0,
            bottom: 20,
          }}>
          <defs>
            <linearGradient id="pendingColor" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#FF6400" stopOpacity={0} />
              <stop offset="95%" stopColor="#FF6400" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="neutralColor" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#505D6F" stopOpacity={0} />
              <stop offset="95%" stopColor="#505D6F" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="positiveColor" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#119E00" stopOpacity={0} />
              <stop offset="95%" stopColor="#119E00" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="negativeColor" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#E00C3C" stopOpacity={0} />
              <stop offset="95%" stopColor="#E00C3C" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />

          {state.activeCharts.pending && (
            <Area
              type="monotone"
              dataKey="pending"
              stackId="1"
              stroke="#FF6400"
              fill="url(#pendingColor)"
            />
          )}

          {state.activeCharts.neutral && (
            <Area
              type="monotone"
              dataKey="neutral"
              stackId="2"
              stroke="#505D6F"
              fill="url(#neutralColor)"
            />
          )}

          {state.activeCharts.positive && (
            <Area
              type="monotone"
              dataKey="positive"
              stackId="3"
              stroke="#119E00"
              fill="url(#positiveColor)"
            />
          )}

          {state.activeCharts.negative && (
            <Area
              type="monotone"
              dataKey="negative"
              stackId="4"
              stroke="#E00C3C"
              fill="url(#negativeColor)"
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default NegativeBlockChart;
