import axios from "axios";

import { getErrorData } from "./errors";

import config from "../config/config";

export const apiCompanyList = async (filter = {}) => {
  const companies = [];
  const token = localStorage.getItem("token");
  const filterData = [];

  Object.keys(filter).map((filterId) => {
    const filterItem = filter[filterId];

    if (filterItem.name && filterItem.value) {
      return filterData.push(`f-${filterItem.name}=${filterItem.value}`);
    } else {
      return true;
    }
  });

  const response = await axios.get(
    `${config.SERVER_BASE_URL}/companies?token=${token}&active=all${filterData.length ? "&" + filterData.join("&") : ""}`,
  );

  if (response && response.data.status === "success") {
    response.data.data.map((company) => {
      return (companies[company._id] = {
        ...company,
        openCompany: false,
      });
    });
  }

  if (Object.keys(companies).length > 0) {
    return {
      status: "success",
      data: companies,
    };
  } else {
    return getErrorData();
  }
};

export const apiCompanyAdd = async (companyName) => {
  const token = localStorage.getItem("token");
  const errors = [];

  if (!companyName) {
    errors.push("Укажите название компании");
  }

  if (errors.length) {
    return getErrorData(errors);
  } else {
    const response = await axios.post(`${config.SERVER_BASE_URL}/companies`, {
      token,
      name: companyName,
    });

    if (response && response.data.status === "success") {
      return {
        status: "success",
      };
    } else {
      return getErrorData(response.data.errorText);
    }
  }
};

export const apiCompanyEdit = async (companyId, companyName) => {
  const token = localStorage.getItem("token");
  const errors = [];

  if (!companyId) {
    errors.push("ID компании не найден");
  }

  if (!companyName) {
    errors.push("Укажите название компании");
  }

  if (errors.length) {
    return getErrorData(errors);
  } else {
    const response = await axios.put(`${config.SERVER_BASE_URL}/companies/${companyId}`, {
      token,
      name: companyName,
    });

    if (response && response.data.status === "success") {
      return {
        status: "success",
      };
    } else {
      return getErrorData(response.data.errorText);
    }
  }
};

export const apiCompanyChangeActive = async (companyId, companyActive) => {
  const token = localStorage.getItem("token");
  const errors = [];

  if (!companyId) {
    errors.push("ID компании не найден");
  }

  if (errors.length) {
    return getErrorData(errors);
  } else {
    const response = await axios.put(`${config.SERVER_BASE_URL}/companies/${companyId}`, {
      token,
      active: companyActive,
    });

    if (response && response.data.status === "success") {
      return {
        status: "success",
      };
    } else {
      return getErrorData(response.data.errorText);
    }
  }
};

export const apiCompanyDelete = async (companyId, msg = "") => {
  const token = localStorage.getItem("token");
  const errors = [];

  if (!companyId) {
    errors.push("ID компании не найден");
  }

  if (!msg || msg.length < 5) {
    errors.push("Укажите причину удаления");
  }

  if (errors.length) {
    return getErrorData(errors);
  } else {
    const response = await axios.delete(
      `${config.SERVER_BASE_URL}/companies/${companyId}?token=${token}&msg=${msg}`,
    );

    if (response && response.data.status === "success") {
      return {
        status: "success",
      };
    } else {
      return getErrorData(response.data.errorText);
    }
  }
};
