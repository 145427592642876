import React, { useContext } from "react";

import "./Layout.scss";

import Footer from "../../components/Footer/Footer";
import LeftSideBar from "../../components/LeftSideBar/LeftSideBar";
import ButtonEscape from "../../components/UI/ButtonEscape/ButtonEscape";
import { AuthContext } from "../../context/auth/authContext";

const Layout = ({ children }) => {
  const authContextData = useContext(AuthContext);

  return (
    <>
      <main id="app" className="app">
        <div className="main-container">
          <div className="main-wrap">
            {authContextData.auth ? <ButtonEscape /> : null}

            {children}
          </div>

          {authContextData.auth ? <LeftSideBar /> : null}
        </div>
        {authContextData.auth ? <Footer /> : null}
      </main>
    </>
  );
};

export default Layout;
