import React from "react";

import "./SearchHistoryTable.styles.scss";
import { ISearchHistoryTableProps } from "./SearchHistoryTable.types";
import { TableHeader } from "./TableHeader";
import { TableRow } from "./TableRow";

import { ISearchingQuery } from "../../../api/searching-queries";

export const SearchHistoryTable = ({ tableRowData }: ISearchHistoryTableProps) => {
  return (
    <div className="search-history-table">
      <table>
        <TableHeader />
        <tbody>
          {tableRowData.map((row: ISearchingQuery) => (
            <TableRow key={row._id} tableRowData={row} />
          ))}
        </tbody>
      </table>
    </div>
  );
};
