import cn from "classnames";
import React from "react";
import Select from "react-select";

import classes from "./PaginationSettings.module.scss";

const MyOption = (props) => {
  const { innerProps, innerRef, isSelected, isFocused, isDisabled } = props;

  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={cn(classes.CustomOption, {
        [classes.Active]: isSelected,
        [classes.Focus]: isFocused,
        [classes.Disabled]: isDisabled,
      })}>
      <div className={classes.OptionValue}>{props.data.label}</div>
      {/* <span className={classes.OptionCount}>{props.data.count}</span> */}
    </div>
  );
};

function PaginationSettings({ options, onChange, value, className }) {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#ffffff" : "#94a2b6",
      padding: "0 30px 0 30px",
      backgroundColor: state.isSelected ? "#e00c3c" : "#ffffff",
      transition: "all .2s ease",
      cursor: "pointer",
      textAlign: "left",
      height: 53,
      display: "flex",
      borderBottom: "1px solid #f1f1f1",
      alignItems: "center",
      fontSize: "13px",
      lineHeight: "20px",
      position: "relative",
      whiteSpace: "nowrap",

      "&:hover": {
        color: "#ffffff",
        backgroundColor: "#e00c3c",
      },

      "&:last-child": {
        borderBottom: "none",
      },

      "&:after": {
        position: "absolute",
        content: '""',
        top: "0px",
        left: "30px",
        right: "30px",
        height: "1px",
        width: "auto",
        transition: "all .2s ease",
      },

      "&:hover:after": {
        backgroundColor: "#e00c3c",
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      border: "none",
      margin: 0,
      padding: 0,
      boxShadow: "none",
      borderRadius: 0,
      width: "auto",
      minWidth: "100%",

      top: "auto",
      bottom: "100%",

      "@media only screen and (max-width: 1200px)": {
        width: "100%",
      },
    }),
    menuList: (provided, state) => ({
      ...provided,
      border: "none",
      margin: 0,
      padding: 0,
      maxHeight: "400px",
      boxShadow: "0px 10px 24px #F2F0FF",
    }),
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      height: "100%",
      minHeight: 38,
      border: "none",
      borderRadius: 0,
      backgroundColor: "#fffffff",
      display: "flex",
      alignItems: "center",
      outline: "none",
      margin: 0,
      padding: 0,
      cursor: "pointer",
      boxShadow: "none",
      "&:hover": {
        border: "none",
      },
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      margin: 0,
      padding: 0,
      height: "100%",
      display: "flex",
      alignItems: "center",
      overflow: "visible",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "16px",
      lineHeight: "19px",
      color: "#505D6F",
      fontWeight: "500",
      margin: 0,
      padding: "0",
      opacity: state.isDisabled ? ".5" : "1",
      whiteSpace: "normal",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
    }),
    indicatorContainer: (provided, state) => ({
      ...provided,
      padding: 0,
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    container: (provided, state) => ({
      ...provided,
      height: "100%",
      width: "100%",
      overflow: "visible",
      borderBottom: "1px solid #D2DCEA",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "#ff4763",
      "&:hover": { color: "#2E2382" },
    }),
  };

  return (
    <div className={cn(classes.Wrapper, className)}>
      <p className={classes.Title}>Отображать на странице:</p>

      <div className={classes.SelectWrap}>
        <Select
          classNamePrefix="select"
          className="PaginationSettings"
          instanceId="PaginationSettings"
          onChange={onChange}
          options={options}
          value={value}
          isSearchable={false}
          styles={customStyles}
          components={{ Option: MyOption }}
        />
      </div>
    </div>
  );
}

export default PaginationSettings;
