import axios from "axios";

import { getErrorData } from "./errors";

import config from "../config/config";

export const apiProjectList = async (companyId, filter = {}) => {
  const projects = [];
  const token = localStorage.getItem("token");
  const filterData = [];

  Object.keys(filter).map((filterId) => {
    const filterItem = filter[filterId];

    if (filterItem.name && filterItem.value) {
      return filterData.push(`f-${filterItem.name}=${filterItem.value}`);
    } else {
      return true;
    }
  });

  const response = await axios.get(
    `${config.SERVER_BASE_URL}/projects?token=${token}&active=all&companyId=${companyId}${
      filterData.length ? "&" + filterData.join("&") : ""
    }`,
  );

  if (response && response.data.status === "success") {
    response.data.data.map((project) => {
      return (projects[project._id] = {
        ...project,
      });
    });
  }

  if (Object.keys(projects).length > 0) {
    return {
      status: "success",
      data: projects,
    };
  } else {
    return getErrorData();
  }
};

export const apiProjectAdd = async (companyId, projectName) => {
  const token = localStorage.getItem("token");
  const errors = [];

  if (!companyId) {
    errors.push("Компания не найдена");
  }

  if (!projectName) {
    errors.push("Укажите название проекта");
  }

  if (errors.length) {
    return getErrorData(errors);
  } else {
    const response = await axios.post(`${config.SERVER_BASE_URL}/projects`, {
      token,
      name: projectName,
      companyId,
    });

    if (response && response.data.status === "success") {
      return {
        status: "success",
        data: response?.data?.data,
      };
    } else {
      return getErrorData(response.data.errorText);
    }
  }
};

export const apiProjectAddPlan = async (projectId, jsonData) => {
  const token = localStorage.getItem("token");

  const res = await axios.post(`${config.SERVER_BASE_URL}/plan-v2`, {
    token,
    project: projectId,
    data: jsonData,
  });

  return res;
};

export const apiProjectGetPlan = async (projectId) => {
  const token = localStorage.getItem("token");
  const res = await axios.get(
    `${config.SERVER_BASE_URL}/plan-v2?token=${token}&project=${projectId}`,
  );
  return res;
};

export const apiProjectEdit = async (projectId, projectName) => {
  const token = localStorage.getItem("token");
  const errors = [];

  if (!projectId) {
    errors.push("Проект не найден");
  }

  if (!projectName) {
    errors.push("Укажите название проека");
  }

  if (errors.length) {
    return getErrorData(errors);
  } else {
    const response = await axios.put(`${config.SERVER_BASE_URL}/projects/${projectId}`, {
      token,
      name: projectName,
    });

    if (response && response.data.status === "success") {
      return {
        status: "success",
      };
    } else {
      return getErrorData(response.data.errorText);
    }
  }
};

export const apiProjectChangeActive = async (projectId, projectActive) => {
  const token = localStorage.getItem("token");
  const errors = [];

  if (!projectId) {
    errors.push("ID проекта не найден");
  }

  if (errors.length) {
    return getErrorData(errors);
  } else {
    const response = await axios.put(`${config.SERVER_BASE_URL}/projects/${projectId}`, {
      token,
      active: projectActive,
    });

    if (response && response.data.status === "success") {
      return {
        status: "success",
      };
    } else {
      return getErrorData(response.data.errorText);
    }
  }
};

export const apiProjectDelete = async (projectId, msg = "") => {
  const token = localStorage.getItem("token");
  const errors = [];

  if (!projectId) {
    errors.push("ID проекта не найден");
  }

  if (!msg || msg.length < 5) {
    errors.push("Укажите причину удаления");
  }

  if (errors.length) {
    return getErrorData(errors);
  } else {
    const response = await axios.delete(
      `${config.SERVER_BASE_URL}/projects/${projectId}?token=${token}&msg=${msg}`,
    );

    if (response && response.data.status === "success") {
      return {
        status: "success",
      };
    } else {
      return getErrorData(response.data.errorText);
    }
  }
};
