import React, { ChangeEvent, useEffect, useState } from "react";

import "./SearchHistorySelector.styles.scss";
import { ISearchHistorySelectorProps } from "./SearchHistorySelector.types";

import { deepOptions, findSystemOptions } from "../../Search/SearchModal/constants";
import SelectWithCounter from "../../UI/SelectWithCounter/SelectWithCounter";

export const SearchHistorySelector = ({
  optionDefault,
  value,
  setValue,
  handleOnFetchingQueries,
}: ISearchHistorySelectorProps) => {
  const [isSelectorClicked, setIsSelectorClicked] = useState(false);

  const handleOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (!e.target.value) {
      setValue(null);
    } else {
      setValue(Number(e.target.value));
    }

    setIsSelectorClicked(true);
  };

  useEffect(() => {
    (async () => {
      if (isSelectorClicked) {
        await handleOnFetchingQueries(1);
        setIsSelectorClicked(false);
      }
    })();
  }, [isSelectorClicked]);

  return (
    <div className="search-history-selector-wrapper">
      <SelectWithCounter
        optionDefault={optionDefault}
        value={value}
        options={optionDefault === "Система" ? findSystemOptions : deepOptions}
        isHideCount
        maxHeight="200px"
        onChange={handleOnChange}
      />
    </div>
  );
};
