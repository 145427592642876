export const findSystemOptions = [
  { id: 1, name: "Яндекс XML", count: 1 },
  { id: 2, name: "Яндекс Desktop", count: 1 },
  { id: 3, name: "Яндекс Mobile", count: 1 },
  { id: 11, name: "Google Desktop", count: 1 },
  { id: 12, name: "Google Mobile", count: 1 },
];

export const deepOptions = [
  { id: 10, name: "10", count: 1 },
  { id: 20, name: "20", count: 1 },
];

export const yandexRegions = [
  {
    name: "C",
    year: 1972,
  },
  {
    name: "Elm",
    year: 2012,
  },
];
