import React from "react";

function IconPending({ className }) {
  return (
    <svg
      className={className}
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 14C0 6.26865 6.26842 0 13.9995 0C21.7319 0 28 6.26865 28 14C28 21.732 21.7319 28 13.9995 28C6.26842 28 0 21.732 0 14ZM3.04351 14C3.04351 20.051 7.94904 24.9565 13.9995 24.9565C20.0516 24.9565 24.9565 20.051 24.9565 14C24.9565 7.94865 20.0516 3.04348 13.9995 3.04348C7.94904 3.04348 3.04351 7.94865 3.04351 14Z"
        fill="#FF6400"
      />
      <path
        d="M17.4568 8.4914L11.294 14.6542C10.848 15.1003 10.92 15.8956 11.4548 16.4303C11.9895 16.9651 12.7848 17.0371 13.2309 16.5911L19.3937 10.4283C19.8397 9.98225 19.7677 9.18696 19.2329 8.65217C18.6981 8.11738 17.9028 8.04539 17.4568 8.4914Z"
        fill="#FF6400"
      />
    </svg>
  );
}

export default IconPending;
