import React from "react";

function IconLink({ className }) {
  return (
    <svg
      className={className}
      width={27}
      height={14}
      viewBox="0 0 27 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.51812 10.8177H5.53008C2.4758 10.8177 0 8.39678 0 5.40883C0 2.42194 2.4758 0 5.53008 0H12.036C15.0905 0 17.5661 2.42168 17.5661 5.40883C17.5661 7.73147 16.0707 9.71075 13.9698 10.4775C13.5151 10.0295 13.1486 9.45139 12.9259 9.05522C14.5148 8.57241 15.6685 7.12268 15.6685 5.40883C15.6685 3.30039 13.9211 1.59077 11.7649 1.59077H5.85526C3.69978 1.59077 1.95193 3.30039 1.95193 5.40883C1.95193 7.51727 3.69951 9.22689 5.85526 9.22689H8.16185C8.21456 9.77969 8.3362 10.3127 8.51812 10.8177ZM14.9637 14C11.9097 14 9.43367 11.5783 9.43367 8.5909C9.43367 6.16949 11.0607 4.11995 13.3032 3.43008C13.7692 3.79825 14.1014 4.32094 14.2223 4.91758C12.5847 5.37133 11.3853 6.84377 11.3853 8.5909C11.3853 10.6996 13.1332 12.4092 15.2892 12.4092H21.1988C23.354 12.4092 25.1019 10.6996 25.1019 8.5909C25.1019 6.48246 23.3543 4.77258 21.1988 4.77258H18.8368C18.7827 4.22005 18.6603 3.68706 18.4773 3.18181H21.4699C24.5242 3.18181 27 5.60322 27 8.5909C27 11.5781 24.5242 14 21.4697 14H14.9637Z"
        fill="#2E2382"
      />
    </svg>
  );
}

export default IconLink;
