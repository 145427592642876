import cn from "classnames";
import React, { useState } from "react";

import classes from "./DesktopPlanStart.module.scss";
import IconAccordionOpen from "./IconAccordionOpen";
import IconShevronBottom from "./IconShevronBottom";
import Row from "./Row/Row";
import { headerData } from "./headerData";
import { useDesktopPlanStart } from "./useDesktopPlanStart";

function DesktopPlanStart({ title, onCreatePlan }) {
  const {
    items,
    onAddRow,
    onDeleteRow,
    onAddInnerRow,
    onDeleteInnerRow,
    onChangeFieldsRow,
    onChangeFieldsInnerRow,
    prevDateEnd,
    isDisabledAddRow,
  } = useDesktopPlanStart();

  const {
    items: itemsOne,
    onAddRow: onAddRowOne,
    onDeleteRow: onDeleteRowOne,
    onAddInnerRow: onAddInnerRowOne,
    onDeleteInnerRow: onDeleteInnerRowOne,
    onChangeFieldsRow: onChangeFieldsRowOne,
    onChangeFieldsInnerRow: onChangeFieldsInnerRowOne,
    prevDateEnd: prevDateEndOne,
    isDisabledAddRow: isDisabledAddRowOne,
  } = useDesktopPlanStart();

  const {
    items: itemsTwo,
    onAddRow: onAddRowTwo,
    onDeleteRow: onDeleteRowTwo,
    onAddInnerRow: onAddInnerRowTwo,
    onDeleteInnerRow: onDeleteInnerRowTwo,
    onChangeFieldsRow: onChangeFieldsRowTwo,
    onChangeFieldsInnerRow: onChangeFieldsInnerRowTwo,
    prevDateEnd: prevDateEndTwo,
    isDisabledAddRow: isDisabledAddRowTwo,
  } = useDesktopPlanStart();

  const [isOpenDropdown, setIsOpenDropdown] = useState({
    planNull: false,
    planOne: false,
    planTwo: false,
  });

  const onChangeIsOpenDropdown = (namePlan) => {
    setIsOpenDropdown((prev) => ({ ...prev, [namePlan]: !prev[namePlan] }));
  };

  const handlerOnCreatePlan = () => {
    const onCheckDatePlan = (arr) => {
      if (arr?.length > 1) {
        return arr;
      }

      if (!arr?.[0]?.dateStart && !arr?.[0]?.dateEnd) {
        return [];
      }

      return arr;
    };

    const arrData = [
      {
        type: null,
        data: onCheckDatePlan(items),
      },
      {
        type: 1,
        data: onCheckDatePlan(itemsOne),
      },
      {
        type: 2,
        data: onCheckDatePlan(itemsTwo),
      },
    ];

    onCreatePlan(arrData);
  };

  return (
    <div className={cn(classes.Wrap)}>
      {/* {title && <h2 className={classes.Title}>{title}</h2>} */}

      <div className={classes.Inner}>
        <div className={classes.Accordion}>
          <div className={cn(classes.AccordionItem, { [classes.Open]: isOpenDropdown.planNull })}>
            <div
              className={classes.AccordionItemHeader}
              onClick={() => onChangeIsOpenDropdown("planNull")}>
              ПЛАН — ОДИНОЧНЫЕ РЕПЛИКИ
              {!isOpenDropdown.planNull ? <IconAccordionOpen /> : <IconShevronBottom />}
            </div>

            <div className={classes.AccordionDropdown}>
              <div className={classes.Header}>
                {headerData.map((item, index) => (
                  <div
                    className={classes.HeaderItem}
                    key={index}
                    dangerouslySetInnerHTML={{ __html: item }}
                  />
                ))}
              </div>

              {items.length > 0 && (
                <div className={classes.Items}>
                  {items.map((item, index) => (
                    <Row
                      item={item}
                      num={index + 1}
                      key={item.id}
                      isLast={items.length - 1 === index}
                      onAddRow={onAddRow}
                      onDeleteRow={onDeleteRow}
                      onAddInnerRow={onAddInnerRow}
                      onDeleteInnerRow={onDeleteInnerRow}
                      onChangeFieldsRow={onChangeFieldsRow}
                      onChangeFieldsInnerRow={onChangeFieldsInnerRow}
                      prevDateEnd={prevDateEnd}
                      isDisabledAddRow={isDisabledAddRow}
                    />
                  ))}
                </div>
              )}

              <div className={classes.AddPlanWrap}>
                <button className={classes.AddPlan} type="button" onClick={handlerOnCreatePlan}>
                  Создать
                </button>
              </div>
            </div>
          </div>

          <div className={cn(classes.AccordionItem, { [classes.Open]: isOpenDropdown.planOne })}>
            <div
              className={classes.AccordionItemHeader}
              onClick={() => onChangeIsOpenDropdown("planOne")}>
              ПЛАН — ИНИЦИИРОВАНИЕ
              {!isOpenDropdown.planOne ? <IconAccordionOpen /> : <IconShevronBottom />}
            </div>

            <div className={classes.AccordionDropdown}>
              <div className={classes.Header}>
                {headerData.map((item, index) => (
                  <div
                    className={classes.HeaderItem}
                    key={index}
                    dangerouslySetInnerHTML={{ __html: item }}
                  />
                ))}
              </div>

              {itemsOne.length > 0 && (
                <div className={classes.Items}>
                  {itemsOne.map((item, index) => (
                    <Row
                      item={item}
                      num={index + 1}
                      key={item.id}
                      isLast={itemsOne.length - 1 === index}
                      onAddRow={onAddRowOne}
                      onDeleteRow={onDeleteRowOne}
                      onAddInnerRow={onAddInnerRowOne}
                      onDeleteInnerRow={onDeleteInnerRowOne}
                      onChangeFieldsRow={onChangeFieldsRowOne}
                      onChangeFieldsInnerRow={onChangeFieldsInnerRowOne}
                      prevDateEnd={prevDateEndOne}
                      isDisabledAddRow={isDisabledAddRowOne}
                    />
                  ))}
                </div>
              )}

              <div className={classes.AddPlanWrap}>
                <button className={classes.AddPlan} type="button" onClick={handlerOnCreatePlan}>
                  Создать
                </button>
              </div>
            </div>
          </div>

          <div className={cn(classes.AccordionItem, { [classes.Open]: isOpenDropdown.planTwo })}>
            <div
              className={classes.AccordionItemHeader}
              onClick={() => onChangeIsOpenDropdown("planTwo")}>
              ПЛАН — РЕАГИРОВАНИЕ
              {!isOpenDropdown.planTwo ? <IconAccordionOpen /> : <IconShevronBottom />}
            </div>

            <div className={classes.AccordionDropdown}>
              <div className={classes.Header}>
                {headerData.map((item, index) => (
                  <div
                    className={classes.HeaderItem}
                    key={index}
                    dangerouslySetInnerHTML={{ __html: item }}
                  />
                ))}
              </div>

              {itemsTwo.length > 0 && (
                <div className={classes.Items}>
                  {itemsTwo.map((item, index) => (
                    <Row
                      item={item}
                      num={index + 1}
                      key={item.id}
                      isLast={itemsTwo.length - 1 === index}
                      onAddRow={onAddRowTwo}
                      onDeleteRow={onDeleteRowTwo}
                      onAddInnerRow={onAddInnerRowTwo}
                      onDeleteInnerRow={onDeleteInnerRowTwo}
                      onChangeFieldsRow={onChangeFieldsRowTwo}
                      onChangeFieldsInnerRow={onChangeFieldsInnerRowTwo}
                      prevDateEnd={prevDateEndTwo}
                      isDisabledAddRow={isDisabledAddRowTwo}
                    />
                  ))}
                </div>
              )}

              <div className={classes.AddPlanWrap}>
                <button className={classes.AddPlan} type="button" onClick={handlerOnCreatePlan}>
                  Создать
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DesktopPlanStart;
