import React, { useContext } from "react";

import Calendar from "./Calendar/Calendar";
import Filter from "./Filter/Filter";
import classes from "./MobileVersion.module.scss";
import ScreenRating from "./ScreenRating/ScreenRating";
import ScreenSearch from "./ScreenSearch/ScreenSearch";
import ScreenTonality from "./ScreenTonality/ScreenTonality";
import Tabs from "./Tabs/Tabs";
import { useModal } from "./useModal";

import { AnaliseContext } from "../../../context/analise";

function MobileVersion() {
  const controlModal = useModal();
  const controlModalChildren = useModal();

  const { state } = useContext(AnaliseContext);

  return (
    <section className={classes.Section}>
      <div className={classes.Title}>
        {state.activeTabScreen === "search" && "Анализ поисковой выдачи"}
        {state.activeTabScreen === "tonality" && "Анализ тональности"}
        {state.activeTabScreen === "rating" && "Анализ рейтинга площадок"}
      </div>

      <Filter controlModal={controlModal} controlModalChildren={controlModalChildren} />
      <Tabs controlModal={controlModal} />
      <Calendar />

      {state.activeTabScreen === "search" && <ScreenSearch />}
      {state.activeTabScreen === "tonality" && <ScreenTonality />}
      {state.activeTabScreen === "rating" && <ScreenRating />}
    </section>
  );
}

export default MobileVersion;
