import cn from "classnames";
import React from "react";

import IconClose from "./IconClose";
import IconCopy from "./IconCopy";
import IconLink from "./IconLink";
import classes from "./UrlDropDown.module.scss";

function isValidURL(str) {
  var a = document.createElement("a");
  a.href = str;
  return a.host && a.host !== window.location.host;
}

function UrlDropDown({ href, isOpen, onClose }) {
  return (
    <div className={cn(classes.Wrapper, { [classes.Open]: isOpen })}>
      <div className={classes.Inner}>
        <div className={cn(classes.Field)}>
          <span className={classes.Href}>{href}</span>
        </div>

        <div className={classes.Control}>
          <button
            className={cn(classes.BtnCopy, { [classes.Disabled]: !isValidURL(href) })}
            onClick={() => {
              navigator.clipboard.writeText(href);
              onClose();
            }}>
            <IconCopy />
            Копировать
          </button>

          <a
            className={cn(classes.BtnGoToLink, { [classes.Disabled]: !isValidURL(href) })}
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onClose}>
            <IconLink />
            Перейти
          </a>
        </div>

        <button className={classes.Close} onClick={onClose}>
          <IconClose />
        </button>
      </div>
    </div>
  );
}

export default UrlDropDown;
