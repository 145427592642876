import React from "react";

import classes from "./CustomTile.module.scss";

import InputEditText from "../../InputEditText/InputEditText";

export const CustomTile = ({
  onChangeStatusEdit,
  activeEdit,
  name,
  description,
  onChange,
  onBlur,
}) => {
  const isDescriptionContainsSymbols = ![...description].every((item) =>
    ["", " ", "\n"].includes(item),
  );

  const handleOnStartEditDescription = () => {
    onChangeStatusEdit("description");
  };

  return (
    <div>
      {activeEdit === "name" ? (
        <InputEditText
          id={"name"}
          min={3}
          max={100}
          onChange={onChange}
          onBlur={onBlur}
          value={name}
          className={classes.activeInput}
        />
      ) : (
        <div onClick={() => onChangeStatusEdit("name")} className={classes.tileName}>
          {name}
        </div>
      )}

      {isDescriptionContainsSymbols || activeEdit === "description" ? null : (
        <p onClick={handleOnStartEditDescription} className={classes.editDescription}>
          Редактировать описание
        </p>
      )}

      {activeEdit === "description" ? (
        <InputEditText
          id={"description"}
          min={0}
          max={255}
          onChange={onChange}
          className={classes.descriptionInput}
          value={description}
          onBlur={onBlur}
        />
      ) : (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a onClick={() => onChangeStatusEdit("description")} className={classes.description}>
          {description}
        </a>
      )}
    </div>
  );
};
