import React from "react";

function IconClose() {
  return (
    <svg width={11} height={12} viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.72139 4.64678L9.58594 0.782227L11.0002 2.19644L7.1356 6.06099L10.8767 9.80212L9.46252 11.2163L5.72139 7.4752L1.97903 11.2176L0.564819 9.80334L4.30718 6.06099L0.472656 2.22647L1.88687 0.812256L5.72139 4.64678Z"
        fill="white"
      />
    </svg>
  );
}

export default IconClose;
