import React from "react";

import classes from "./ButtonSelector.module.scss";

export default function ButtonSelector(props) {
  return (
    <div
      className={classes.ButtonSelector}
      data-color={props.color}
      onClick={() => props.click()}
      data-disabled={props.dataDisabled}>
      {props.text}
    </div>
  );
}
