import React from "react";

function IconClose({ className }) {
  return (
    <svg
      className={className}
      width={10}
      height={10}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.22898 0.743922L0.744054 2.22885L3.59016 5.07495L0.744054 7.92106L2.22898 9.40598L5.07508 6.55988L7.92119 9.40598L9.40611 7.92106L6.56001 5.07495L9.40611 2.22885L7.92119 0.743923L5.07508 3.59003L2.22898 0.743922Z"
        fill="#E00C3C"
      />
    </svg>
  );
}

export default IconClose;
