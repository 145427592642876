import cn from "classnames";
import React, { useEffect, useState } from "react";

import classes from "./Search.module.scss";

import config from "../../../../config/config";
import Input from "../../../UI/Input/Input";
import DownloadDropDown from "../DownloadDropDown/DownloadDropDown";
import ProjectSettings from "../ProjectSettings/ProjectSettings";
import ReplicaAdd from "../ReplicaAdd/ReplicaAdd";
import UploadFile from "../UploadFile/UploadFile";

const Search = (props) => {
  const [projectSettings, setProjectSettings] = useState(false);
  const [uploadFile, setUploadFile] = useState(false);
  const [replicaAdd, setReplicaAdd] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [isOpenDownloadDropDown, setIsOpenDownloadDropDown] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const blackout = () => {
    !props.blackout ? props.setBlackOut(true) : props.setBlackOut(false);
  };

  const handlerExportReplicaList = (typeDownload) => {
    props.exportReplicaList(typeDownload);
    setIsOpenDownloadDropDown(false);
  };

  const handleCloseDropdown = () => {
    const dropdown = document.getElementById("DownloadDropDown");

    if (dropdown && !dropdown.classList.contains("close")) {
      setIsOpenDownloadDropDown(false);
    }
  };

  const uploadClasses = cn({
    "search-and-settings__upload": true,
    "search-and-settings__upload_open": uploadFile,
  });

  const [search, setSearch] = useState(props.search.value);

  const openCloseFilterMobile = () => {
    const filter = document.querySelector(".replic__filter");
    if (filter.classList.contains("replic__filter_mobile-open")) {
      filter.classList.remove("replic__filter_mobile-open");
    } else {
      filter.classList.add("replic__filter_mobile-open");
    }
  };

  const hideFilterMobile = () => {
    const filter = document.querySelector(".replic__filter");
    if (filter.classList.contains("replic__filter_mobile-open")) {
      filter.classList.remove("replic__filter_mobile-open");
    }
  };

  const searchOpenMobile = () => {
    setSearchOpen(!searchOpen);
  };

  const exportReplica = () => {
    // получение токена
    const token = localStorage.getItem("token");

    // тип реплики (реплики / инициирование  / реагирование)
    // реплики - null
    // инициирование - 1
    // реагирование - 2
    const replicaType = localStorage.getItem("replica-type");

    // фильтры для реплик
    const company = localStorage.getItem("filter-replica-company");
    const project = localStorage.getItem("filter-replica-project");
    const category = localStorage.getItem("filter-replica-category");
    const platform = localStorage.getItem("filter-replica-platform");
    const dateFrom = localStorage.getItem("filter-replica-dateFrom");
    const dateTo = localStorage.getItem("filter-replica-dateTo");
    const status = localStorage.getItem("filter-replica-status");
    const search = localStorage.getItem("filter-replica-search");
    const filterByDateChangeStatus = localStorage.getItem(
      "filter-replica-filterByDateChangeStatus",
    );

    // сортировка
    const sortName = localStorage.getItem("replica-sort-name");
    const sortDirection = localStorage.getItem("replica-sort-direction");

    const urlWithParams = new URL(`${config.SERVER_BASE_URL}/replica/export-v3`);

    // добавляем нужные search params, которые выбраны в фильтрах
    token && urlWithParams.searchParams.append("token", token);
    replicaType && urlWithParams.searchParams.append("replicaType", replicaType);
    company && urlWithParams.searchParams.append("company", company);
    project && urlWithParams.searchParams.append("project", project);
    category && urlWithParams.searchParams.append("category", category);
    platform && urlWithParams.searchParams.append("platform", platform);
    dateFrom && urlWithParams.searchParams.append("dateFrom", dateFrom);
    dateTo && urlWithParams.searchParams.append("dateTo", dateTo);
    status && urlWithParams.searchParams.append("status", status);
    search && urlWithParams.searchParams.append("search", search);
    filterByDateChangeStatus &&
      urlWithParams.searchParams.append("filterByDateChangeStatus", filterByDateChangeStatus);

    // добавляем в search params сортировку
    sortName
      ? urlWithParams.searchParams.append("sortName", sortName)
      : urlWithParams.searchParams.append("sortName", "date");
    sortDirection
      ? urlWithParams.searchParams.append("sortDirection", sortDirection)
      : urlWithParams.searchParams.append("sortDirection", "desc");

    window.open(urlWithParams);
  };

  const searchCLasses = cn({
    "search-and-settings__search": true,
    "search-and-settings__search_open-mobile": searchOpen,
  });

  useEffect(() => {
    if (window.innerWidth > 767) {
      window.addEventListener("click", handleCloseDropdown);
    }

    return () => window.removeEventListener("click", handleCloseDropdown);
  }, []);

  useEffect(() => {
    setSearch(props.search.value);
  }, [props.search.value]);

  return (
    <>
      <div className="search-and-settings" data-active={props.blackout ? "Y" : null}>
        <div className="brightless" data-active={props.blackout ? "Y" : null} />

        <div className="search-and-settings__top-side">
          <div className={searchCLasses}>
            <input
              type="submit"
              value=""
              onClick={() => props.filterInputChange(search, props.search.name)}
            />

            <Input
              type={props.search.type}
              name={props.search.name}
              value={search}
              placeholder={props.search.placeholder}
              onChange={(event) => setSearch(event.target.value)}
              onKeyPress={(event) =>
                event.key === "Enter" ? props.filterInputChange(search, props.search.name) : null
              }
            />

            <div
              className="search-and-settings__settings-button_back"
              data-active="Y"
              onClick={() => searchOpenMobile()}
            />
          </div>

          <div className={cn("search-and-settings__settings", classes.Settings)}>
            <div onClick={searchOpenMobile} className="search-and-settings__search-mobile" />

            <div
              onClick={() => {
                setIsOpenFilter((prev) => !prev);
                openCloseFilterMobile();
                blackout();
              }}
              className="search-and-settings__tune-mobile"
              data-active={
                props.blackout && !props.replicaChildrenOpenForBlackout && isOpenFilter ? "Y" : null
              }
            />

            <div className={uploadClasses} onClick={() => setUploadFile(!uploadFile)}>
              <span className="search-and-settings__upload-text">Загрузить данные</span>
            </div>

            {props.replicaCount > 0 && !props.isReplicaPage ? (
              <div className={classes.Download} onClick={(e) => e.stopPropagation()}>
                <div
                  className={cn("search-and-settings__download", classes.DownloadBtn, {
                    [classes.Open]: isOpenDownloadDropDown,
                  })}
                  onClick={() => {
                    blackout();
                    setIsOpenDownloadDropDown((prev) => !prev);
                  }}>
                  <span className="search-and-settings__download-text">Выгрузить данные</span>
                </div>

                <DownloadDropDown
                  onClick={(value) => {
                    blackout();
                    handlerExportReplicaList(value);
                  }}
                  onClose={() => setIsOpenDownloadDropDown(false)}
                  options={props.exportSelectOptions}
                  isOpen={isOpenDownloadDropDown}
                />
              </div>
            ) : null}

            {props.replicaCount > 0 && props.isReplicaPage && (
              <div className={classes.Download}>
                <div
                  className={cn("search-and-settings__download", classes.DownloadBtn)}
                  onClick={exportReplica}>
                  <span className="search-and-settings__download-text">Выгрузить данные</span>
                </div>
              </div>
            )}

            <>
              <div
                className="search-and-settings__add-edit-replic"
                onClick={() => {
                  hideFilterMobile();
                  blackout();
                  props.setTabsShow(false);
                  setReplicaAdd(true);
                }}>
                <span className="search-and-settings__add-edit-replic-text">
                  {props.replicaType && (props.replicaType === 1 || props.replicaType === 2)
                    ? "Добавить главную реплику"
                    : "Добавить реплику"}
                </span>
              </div>

              <div
                className="search-and-settings__settings-button"
                data-active={projectSettings ? "Y" : null}
                onClick={() => {
                  hideFilterMobile();
                  blackout();
                  setProjectSettings(!projectSettings);
                }}
              />
            </>
          </div>
        </div>

        {projectSettings ? (
          <ProjectSettings
            updateReplicaList={props.updateReplicaList}
            close={() => {
              hideFilterMobile();
              blackout();
              setProjectSettings(false);
            }}
          />
        ) : null}

        {uploadFile ? (
          <UploadFile
            companies={props.companies}
            updateReplicaList={props.updateReplicaList}
            close={() => setUploadFile(false)}
          />
        ) : null}
      </div>

      {replicaAdd ? (
        <ReplicaAdd
          updateReplicaList={props.updateReplicaList}
          close={() => {
            setReplicaAdd(false);
            props.setTabsShow(true);
            blackout();
          }}
          replicaType={props.replicaType}
          replicaChildrenUpdateStart={props.replicaChildrenUpdateStart}
          setTabsShow={props.setTabsShow}
        />
      ) : null}
    </>
  );
};

export default Search;
