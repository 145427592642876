import React from "react";

function IconAllSelected() {
  return (
    <svg width={10} height={8} viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.90308 0L9.6 1.69692L4.4484 6.8484C4.22344 7.07351 3.91825 7.19999 3.6 7.19999C3.28175 7.19999 2.97656 7.07351 2.7516 6.8484L0 4.09692L1.69692 2.4L3.6 4.30308L7.90308 0Z"
        fill="#E00C3C"
      />
    </svg>
  );
}

export default IconAllSelected;
