import React from "react";

import classes from "./DeleteReplic.module.scss";

import ButtonSelector from "../../../UI/ButtonSelector/ButtonSelector";

export default function DeleteReplic(props) {
  return (
    <div className={classes.DeleteReplic}>
      <div className={classes.Text}>Вы действительно хотите удалить выбранные реплики?</div>

      <div className={classes.BtnBlockWrapper}>
        <div className={classes.BtnWrapper}>
          <ButtonSelector text="Да" color="red" click={props.clickDelete} />
        </div>

        <div className={classes.BtnWrapper}>
          <ButtonSelector text="Нет" color="gray" click={props.clickCancel} />
        </div>
      </div>
    </div>
  );
}
