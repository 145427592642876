import React from "react";

function IconChangeArrowDown() {
  return (
    <svg width={9} height={12} viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.86354 0.249512V9.20476L0.954696 6.29592L0 7.25062L4.5 11.7506L9 7.25062L8.0453 6.29592L5.13646 9.20476V0.249512H3.86354Z"
        fill="#E00C3C"
      />
    </svg>
  );
}

export default IconChangeArrowDown;
