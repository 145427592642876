import React from "react";

import "./BackButton.styles.scss";
import { IBackButtonProps } from "./BackButton.types";

export const BackButton = ({ onClick }: IBackButtonProps) => {
  return (
    <button className="search-history-back-button" onClick={onClick}>
      Назад
    </button>
  );
};
