import React from "react";

import "./Input.scss";

const Input = (props) => {
  return (
    <input
      type={props.type}
      name={props.name}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      disabled={props.disabled}
      className={props.className}
      onKeyPress={props.onKeyPress}
      onBlur={props.onBlur}
    />
  );
};

export default Input;
