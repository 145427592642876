import React from "react";

import "./Footer.scss";

import RightReserved from "../RightReserved/RightReserved";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__wrap">
        <RightReserved />
      </div>
    </footer>
  );
};
export default Footer;
