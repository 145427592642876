import React from "react";

import { ISearchHistoryTableProps } from "./SearchHistoryResultsTable.types";
import { TableHeader } from "./TableHeader";
import { TableRow } from "./TableRow";

import { ISearchingQueryResults } from "../../../api/searching-query-results/searching-query-results.types";

export const SearchHistoryResultsTable = ({ tableRowData }: ISearchHistoryTableProps) => {
  return (
    <div>
      <table>
        <TableHeader />
        <tbody>
          {tableRowData.map((row: ISearchingQueryResults) => (
            <TableRow key={row._id} tableRowData={row} />
          ))}
        </tbody>
      </table>
    </div>
  );
};
