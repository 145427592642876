import React, { useState } from "react";

import "./CompanyItem.scss";

import CompanyDelete from "../CompanyDelete/CompanyDelete";
import CompanyEdit from "../CompanyEdit/CompanyEdit";
import CompanyItemText from "../CompanyItemText/CompanyItemText";
import ProjectList from "../ProjectList/ProjectList";

const CompanyItem = (props) => {
  const [companyEdit, setCompanyEdit] = useState(false);
  const [companyDelete, setCompanyDelete] = useState(false);

  const clsItem = ["admin-company__company-item"];

  if (props.active) {
    clsItem.push("admin-company__company-item_active");
  } else {
    clsItem.push("admin-company__company-item_not-active");
  }

  return (
    <div className={clsItem.join(" ")}>
      {!companyEdit && !companyDelete ? (
        <CompanyItemText
          id={props.id}
          name={props.name}
          active={props.active}
          getCompanies={props.getCompanies}
          companyOpen={() => props.onToogleById(props.id)}
          companyEdit={() => setCompanyEdit(true)}
        />
      ) : null}

      {companyEdit ? (
        <CompanyEdit
          id={props.id}
          name={props.name}
          close={() => setCompanyEdit(false)}
          deleteShow={() => {
            setCompanyDelete(true);
            setCompanyEdit(false);
          }}
          getCompanies={props.getCompanies}
        />
      ) : null}

      {companyDelete ? (
        <CompanyDelete
          id={props.id}
          name={props.name}
          close={() => setCompanyDelete(false)}
          getCompanies={props.getCompanies}
        />
      ) : null}

      {props.openCompany ? <ProjectList companyId={props.id} filter={props.filter} /> : null}
    </div>
  );
};

export default CompanyItem;
