import React, { useState } from "react";

import "./Search.scss";

import Input from "../../../../UI/Input/Input";
import Nav from "../Nav/Nav";

const Search = (props) => {
  const [search, setSearch] = useState(props.search.value);
  const accessLevel = localStorage.getItem("accessLevel");

  return (
    <div className={props.cls}>
      <div className="search-and-settings__top-side">
        <div className="search-and-settings__search">
          <input type="submit" value="" />

          <Input
            type={props.search.type}
            name={props.search.name}
            value={search}
            placeholder={props.search.placeholder}
            onChange={(event) => setSearch(event.target.value)}
            onKeyPress={(event) =>
              event.key === "Enter" ? props.filterInputChange(search, props.search.name) : null
            }
          />
        </div>

        {props.nav && (accessLevel === "1" || accessLevel === "5") ? (
          <Nav companyAdd={props.companyAdd} />
        ) : null}
      </div>
    </div>
  );
};

export default Search;
