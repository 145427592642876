import axios from "axios";
import React, { useContext, useEffect, useState } from "react";

import List from "./List/List";
import NegativeBlockChart from "./NegativeBlockChart/NegativeBlockChart";
import classes from "./TabScreenRating.module.scss";

import config from "../../../../config/config";
import { AnaliseContext } from "../../../../context/analise";

const TabScreenRating = () => {
  const { state } = useContext(AnaliseContext);
  const [data, setData] = useState({});

  const onGetData = async () => {
    try {
      const token = localStorage.getItem("token");

      const { data } = await axios.get(`${config.SERVER_BASE_URL}/analysis/rating`, {
        params: {
          token: token,
          query: state.filter.query,
          dateFrom: state.dateFrom,
          dateTo: state.dateTo,
        },
      });

      if (data?.status === "fail") {
        alert(data?.errorText?.split(",")?.join("\n"));
        return;
      }

      if (data?.status === "success") {
        setData(data);
      }
    } catch (err) {
      console.log("onGetData", err);
    }
  };

  useEffect(() => {
    if (state.filter.query) {
      onGetData();
    } else {
      setData({});
    }
  }, [state.dateFrom, state.dateTo, state.filter]);

  return (
    <>
      <div className={classes.Wrap}>
        <div className={classes.TopBlock}>
          <div className={classes.RowTwo}>
            <div className={classes.Infotmation}>
              <div className={classes.ColorDot} data-color="Orange" />
              <div className={classes.Text}>Управляемое</div>
            </div>

            <div className={classes.Infotmation}>
              <div className={classes.ColorDot} data-color="Black" />
              <div className={classes.Text}>Нерелевант</div>
            </div>

            <div className={classes.Infotmation}>
              <div className={classes.ColorDot} data-color="Green" />
              <div className={classes.Text}>Позитив</div>
            </div>

            <div className={classes.Infotmation}>
              <div className={classes.ColorDot} data-color="Red" />
              <div className={classes.Text}>Негатив</div>
            </div>
          </div>
        </div>

        <div className={classes.MainBlock}>
          {state.dateFrom && state.dateTo && data?.contentGraph?.length > 0 ? (
            <NegativeBlockChart data={data?.contentGraph || []} />
          ) : (
            <div className={classes.MainBlockText}>Данные отсутствуют</div>
          )}
        </div>
      </div>

      {data?.content?.length > 0 && <List list={data?.content} data={data} onGetData={onGetData} />}
    </>
  );
};

export default TabScreenRating;
