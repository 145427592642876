import React, { useContext } from "react";
import { CSSTransition } from "react-transition-group";

import classes from "./Filter.module.scss";

import config from "../../../../config/config";
import { AnaliseContext } from "../../../../context/analise";
import IconAdd from "../../icons/IconAdd";
import IconDownLoad from "../../icons/IconDownLoad";
import IconFilter from "../../icons/IconFilter";
import IconQuery from "../../icons/IconQuery";
import ModalCompany from "../popups/ModalCompany/ModalCompany";
import ModalFilter from "../popups/ModalFilter/ModalFilter";
import ModalProject from "../popups/ModalProject/ModalProject";
import ModalQuery from "../popups/ModalQuery/ModalQuery";
import ModalQueryAdd from "../popups/ModalQueryAdd/ModalQueryAdd";
import ModalRegion from "../popups/ModalRegion/ModalRegion";
import ModalSize from "../popups/ModalSize/ModalSize";
import ModalSystem from "../popups/ModalSystem/ModalSystem";

function Filter({ controlModal, controlModalChildren }) {
  const { state } = useContext(AnaliseContext);
  const { filter } = state;

  const token = localStorage.getItem("token");

  const queryJSX = state.queries.find((item) => item.id === state.filter?.query);

  const urlExportData = `${config.SERVER_BASE_URL}/analysis/search/export`;
  const urlExportDataParams = `?token=${token}&query=${filter.query}&dateFrom=${state.dateFrom}&dateTo=${state.dateTo}`;
  const urlExportDataJSX = urlExportData + urlExportDataParams;

  return (
    <>
      <div className={classes.Wrap}>
        <div className={classes.Inner}>
          <button className={classes.BtnFilter} onClick={() => controlModal.onOpenModal("filter")}>
            <IconFilter />
          </button>

          <button className={classes.BtnQuery} onClick={() => controlModal.onOpenModal("query")}>
            <IconQuery />

            <span>{queryJSX?.name || "Поиск. запрос"}</span>
          </button>

          {state.accessLevel !== 2 && (
            <button className={classes.BtnAdd} onClick={() => controlModal.onOpenModal("queryAdd")}>
              <IconAdd />
            </button>
          )}

          {state.accessLevel !== 2 && (
            <a
              className={classes.BtnDownload}
              href={urlExportDataJSX}
              target="_blank"
              rel="noopener noreferrer"
              data-disabled={
                !state?.activeTabScreen === "search" || !state?.contentScreenSearch?.length
                  ? "Y"
                  : ""
              }>
              <IconDownLoad />
            </a>
          )}
        </div>
      </div>

      <CSSTransition
        in={controlModal.showModal === "filter"}
        timeout={200}
        mountOnEnter={true}
        unmountOnExit={true}>
        {(state) => (
          <ModalFilter
            className={state}
            controlModal={controlModal}
            controlModalChildren={controlModalChildren}
          />
        )}
      </CSSTransition>

      <CSSTransition
        in={controlModal.showModal === "query"}
        timeout={200}
        mountOnEnter={true}
        unmountOnExit={true}>
        {(state) => <ModalQuery className={state} controlModal={controlModal} />}
      </CSSTransition>

      <CSSTransition
        in={controlModal.showModal === "queryAdd"}
        timeout={200}
        mountOnEnter={true}
        unmountOnExit={true}>
        {(state) => <ModalQueryAdd className={state} controlModal={controlModal} />}
      </CSSTransition>

      <CSSTransition
        in={controlModalChildren.showModal === "company"}
        timeout={200}
        mountOnEnter={true}
        unmountOnExit={true}>
        {(state) => <ModalCompany className={state} controlModalChildren={controlModalChildren} />}
      </CSSTransition>

      <CSSTransition
        in={controlModalChildren.showModal === "project"}
        timeout={200}
        mountOnEnter={true}
        unmountOnExit={true}>
        {(state) => <ModalProject className={state} controlModalChildren={controlModalChildren} />}
      </CSSTransition>

      <CSSTransition
        in={controlModalChildren.showModal === "size"}
        timeout={200}
        mountOnEnter={true}
        unmountOnExit={true}>
        {(state) => <ModalSize className={state} controlModalChildren={controlModalChildren} />}
      </CSSTransition>

      <CSSTransition
        in={controlModalChildren.showModal === "system"}
        timeout={200}
        mountOnEnter={true}
        unmountOnExit={true}>
        {(state) => <ModalSystem className={state} controlModalChildren={controlModalChildren} />}
      </CSSTransition>

      <CSSTransition
        in={controlModalChildren.showModal === "region"}
        timeout={200}
        mountOnEnter={true}
        unmountOnExit={true}>
        {(state) => <ModalRegion className={state} controlModalChildren={controlModalChildren} />}
      </CSSTransition>
    </>
  );
}

export default Filter;
