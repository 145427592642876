// import IconClose from '../../icons/IconClose'
import cn from "classnames";
import React from "react";

import classes from "./MobailModal.module.scss";

import IconCloseArrow from "../../icons/IconCloseArrow";
import IconCloseMini from "../../icons/IconCloseMini";
import IconFilter from "../../icons/IconFilter";

function MobailModal({
  controlModal,
  children,
  title,
  isIcon = false,
  icon,
  isCloseArrow = false,
  isSmallTitle,
}) {
  return (
    <div className={cn(classes.Wrapper)}>
      <div className={classes.Header}>
        {isIcon && (
          <div className={classes.Icon}>
            {isIcon && !icon && <IconFilter />}
            {isIcon && icon}
          </div>
        )}

        <span className={cn(classes.Title, { [classes.Small]: isSmallTitle })}>{title}</span>

        <button className={cn(classes.Close)} onClick={controlModal.onCloseModal}>
          {!isCloseArrow && <IconCloseMini />}
          {isCloseArrow && <IconCloseArrow />}
        </button>
      </div>

      <div className={classes.Inner}>{children}</div>
    </div>
  );
}

export default MobailModal;
