import axios from "axios";
import React, { useContext, useEffect, useState } from "react";

import classes from "./ScreenTonality.module.scss";

import config from "../../../../config/config";
import { AnaliseContext } from "../../../../context/analise";
import TonalBlockChart from "../../DesctopVersion/TabScreenTonality/TonalBlockChart/TonalBlockChart";
import TonalBlockChartLine from "../../DesctopVersion/TabScreenTonality/TonalBlockChartLine/TonalBlockChartLine";

function ScreenTonality() {
  const { state } = useContext(AnaliseContext);
  const { filter } = state;
  const [data, setData] = useState([]);

  const onGetData = async () => {
    try {
      const token = localStorage.getItem("token");

      const { data } = await axios.get(`${config.SERVER_BASE_URL}/analysis/tonality`, {
        params: {
          token: token,
          project: state.filter.project?.value,
          deepLevel: state.filter.deepLevel,
          searchSystem: state.filter.searchSystem,
          region: state.filter.region,
          query: state.filter.query,
          dateFrom: state.dateFrom,
          dateTo: state.dateTo,
        },
      });

      if (data?.status === "fail") {
        alert(data?.errorText?.split(",")?.join("\n"));
        return;
      }

      if (data?.status === "success") {
        setData(data.content);
      }
    } catch (err) {
      console.log("onGetData", err);
    }
  };

  useEffect(() => {
    if (filter.query) {
      onGetData();
    }
  }, [state.dateFrom, state.dateTo, filter.query]);

  return (
    <section className={classes.Section}>
      <div className={classes.Header}>
        <div className={classes.Colors}>
          <p data-type="pending">Управляемое</p>

          <p data-type="positive">Позитив</p>

          <p data-type="neutral">Нерелевант</p>

          <p data-type="negative">Негатив</p>
        </div>
      </div>

      <div className={classes.Charts}>
        {!data?.length && <div className={classes.Empty}>Данные отсутствуют</div>}

        {data?.length === 1 && (
          <TonalBlockChart data={data} width={310} height={310} cx={150} cy={150} />
        )}

        {data?.length > 1 && <TonalBlockChartLine data={data} />}
      </div>
    </section>
  );
}

export default ScreenTonality;
