import React from "react";

import "./Filter.scss";

import SelectStyle from "../../../../UI/SelectStyle/SelectStyle";

const Filter = (props) => {
  return (
    <div className="select-pages__select-block">
      <div className="select-pages__select-unit select-pages__select-unit_width-219px select-pages__select-unit_small-arrow">
        <SelectStyle
          name={props.filter.status.name}
          value={props.filter.status.value}
          options={props.filter.status.options}
          optionDefault={props.filter.status.optionDefault}
          onChange={(event) =>
            props.filterInputChange(event.target.value, props.filter.status.name)
          }
          location={"adminUsers"}
        />
      </div>
    </div>
  );
};

export default Filter;
