import React from "react";
import { Link } from "react-router-dom";

import "./ButtonEscape.scss";

const ButtonEscape = () => {
  return <Link to="/logout" className="button-escape"></Link>;
};

export default ButtonEscape;
