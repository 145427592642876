import axios from "axios";
import cn from "classnames";
import React, { useContext, useEffect, useState } from "react";

import "./ProjectSettings.scss";
import ProjectSettingsFieldNote from "./ProjectSettingsFieldNote/ProjectSettingsFieldNote";
import ProjectSettingsFieldSelect from "./ProjectSettingsFieldSelect/ProjectSettingsFieldSelect";
import ProjectSettingsFieldText from "./ProjectSettingsFieldText/ProjectSettingsFieldText";
import customFields from "./customFields";

import config from "../../../../config/config";
import { PopupContext } from "../../../../context/popup/popupContext";
import SelectStyle from "../../../UI/SelectStyle/SelectStyle";

const ProjectSettings = (props) => {
  const popupContextData = useContext(PopupContext);
  const [counter, setCounter] = useState(1);
  const [noFields, setNoFields] = useState(true);

  const [inputs, setInputs] = useState({
    company: {
      name: "company",
      value: "",
      options: [],
      optionDefault: "Выберите компанию",
    },
    project: {
      name: "project",
      value: "",
      options: [],
      optionDefault: "Выберите проект",
    },
    type: {
      name: "type",
      value: "",
      options: customFields,
      optionDefault: "Тип поля",
    },
    custom: {},
  });

  const [showAddFiledButton, setShowAddFiledButton] = useState(false);

  const getCompanies = async () => {
    popupContextData.setPreloader(true);

    const token = localStorage.getItem("token");
    const response = await axios.get(`${config.SERVER_BASE_URL}/replica/companies?token=${token}`);
    const responseData = response.data;

    if (responseData.status === "fail") {
      alert(responseData.errorText);
    } else {
      const inputsCopy = { ...inputs };

      if (responseData.data) {
        Object.keys(responseData.data).map((idx) => {
          return inputsCopy.company.options.push({
            id: responseData.data[idx].id,
            name: responseData.data[idx].name,
          });
        });
      }

      setInputs(inputsCopy);
    }

    popupContextData.setPreloader(false);
  };

  const getProjects = async (companyId) => {
    const projects = [];

    if (companyId) {
      popupContextData.setPreloader(true);

      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${config.SERVER_BASE_URL}/replica/projects?token=${token}&companyId=${companyId}`,
      );
      const responseData = response.data;

      if (responseData.status === "success" && responseData.data.length) {
        Object.keys(responseData.data).map((idx) => {
          return projects.push({
            id: responseData.data[idx].id,
            name: responseData.data[idx].name,
          });
        });
      }

      popupContextData.setPreloader(false);
    }

    return projects;
  };

  const getField = async (projectId) => {
    const fields = {};

    if (projectId) {
      popupContextData.setPreloader(true);

      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${config.SERVER_BASE_URL}/replica/projects/settings/${projectId}?token=${token}`,
      );
      const responseData = response.data;

      if (responseData.status === "success" && responseData.data.length) {
        responseData.data.map((data, indexData) => {
          const id = "field-" + (indexData + 1);

          fields[id] = {
            ...inputs.type.options[data.type].settings,
            id: id,
            name: id,
            value: data.name,
            sort: 100 * (indexData + 1),
            deleted: false,
            viewReplica: data.viewReplica,
            options: [],
            fieldId: data._id.toString(),
          };

          if (data.type === "select" && data.options.length) {
            data.options.map((option, index) => {
              return fields[id].options.push({
                id: index + 1,
                name: `option-${id}-${index + 1}`,
                value: option.value,
                fieldId: option._id.toString(),
                deleted: false,
              });
            });
          }

          if (data.type === "note") {
            fields[id].checked = data.name === "agency";
            fields[id].value = data.name;
          }

          return true;
        });

        setCounter(responseData.data.length + 1);
      }

      popupContextData.setPreloader(false);
    }

    return fields;
  };

  const selectChange = async (event, name) => {
    console.log(name);
    const value = event.target.value;
    const inputsCopy = { ...inputs };
    inputsCopy[name].value = value;

    if (name === "company") {
      inputsCopy.project.value = "";
      inputsCopy.project.options = await getProjects(value);

      setCounter(1);
      inputsCopy.custom = {};
    } else if (name === "project") {
      setCounter(1);
      inputsCopy.custom = await getField(value);
    }

    setInputs(inputsCopy);
  };

  const fieldAdd = () => {
    const value = inputs.type.value;
    const inputsCopy = { ...inputs };

    if (value) {
      const id = "field-" + counter;

      inputsCopy.custom[id] = {
        ...inputs.type.options[value].settings,
        id: id,
        name: id,
        value: "",
        sort: 100 * counter,
        deleted: false,
        viewReplica: false,
        options: [],
      };

      if (inputs.type.options[value].settings.fieldType === "select") {
        inputsCopy.custom[id].options.push({
          id: 1,
          name: `option-${id}-1`,
          value: "",
          deleted: false,
        });
      }

      if (value === "note") {
        inputsCopy.custom[id].value = "client";
        inputsCopy.custom[id].checked = false;
      }

      setCounter(counter + 1);
      setNoFields(false);
    }

    inputsCopy.type.value = "";
    setInputs(inputsCopy);
  };

  const fieldDelete = (inputId) => {
    const inputsCopy = { ...inputs };
    // delete inputsCopy.custom[inputId]
    inputsCopy.custom[inputId].deleted = true;
    setInputs(inputsCopy);
  };

  const fieldChange = (value, id) => {
    const inputsCopy = { ...inputs };
    inputsCopy.custom[id].value = value;
    setInputs(inputsCopy);
  };

  const selectAddField = (fieldId) => {
    const inputsCopy = { ...inputs };
    const newId = inputs.custom[fieldId].options.length
      ? inputs.custom[fieldId].options[inputs.custom[fieldId].options.length - 1].id + 1
      : 1;

    inputsCopy.custom[fieldId].options.push({
      id: newId,
      name: `option-${fieldId}-${newId}`,
      value: "",
      deleted: false,
    });

    setInputs(inputsCopy);
  };

  const selectChangeField = (event, fieldId, optionId) => {
    const inputsCopy = { ...inputs };

    inputsCopy.custom[fieldId].options.map((option, index) => {
      if (option.id === optionId) {
        inputsCopy.custom[fieldId].options[index].value = event.target.value;
      }

      return true;
    });

    setInputs(inputsCopy);
  };

  const selectDeleteField = (fieldId, optionId) => {
    const inputsCopy = { ...inputs };
    const optionsNew = inputsCopy.custom[fieldId].options.filter(
      (option) => option.id !== optionId,
    );
    // const optionsNew = [...inputsCopy.custom[fieldId].options]

    optionsNew.map((option, index) => {
      if (optionId === option.id) {
        return (optionsNew[index].deleted = true);
      } else {
        return true;
      }
    });

    if (optionsNew.length) {
      inputsCopy.custom[fieldId].options = optionsNew;
    } else {
      inputsCopy.custom[fieldId].options = [];
    }

    setInputs(inputsCopy);
  };

  const changeCheckbox = (event, id) => {
    const checked = event.target.checked;
    const inputsCopy = { ...inputs };
    inputsCopy.custom[id].viewReplica = checked;
    setInputs(inputsCopy);
  };

  const changeCheckboxNote = (checked, id) => {
    const inputsCopy = { ...inputs };
    inputsCopy.custom[id].checked = checked;

    if (checked) {
      inputsCopy.custom[id].value = "agency";
    } else {
      inputsCopy.custom[id].value = "client";
    }

    setInputs(inputsCopy);
  };

  const save = async (event) => {
    event.preventDefault();

    popupContextData.setPreloader(true);
    const token = localStorage.getItem("token");

    const response = await axios.post(`${config.SERVER_BASE_URL}/replica/projects`, {
      token,
      company: inputs.company.value,
      project: inputs.project.value,
      custom: inputs.custom,
    });

    if (response.data.status === "success") {
      popupContextData.setPopupNotice({
        state: true,
        title: "Внимание",
        text: "Изменения успешно сохранены и будут применены к следующим репликам проекта.",
      });

      props.close();
      props.updateReplicaList();
    } else {
      popupContextData.setPreloader(false);
      alert(response.data.errorText);
    }
  };

  const changeClassesMobileFields = () => {
    setShowAddFiledButton(!showAddFiledButton);
  };

  useEffect(() => {
    getCompanies();
     
  }, []);

  const fieldsClasses = cn({
    "search-and-settings__fields-manage-fields": true,
    "search-and-settings__fields-manage-fields_show-mobile": showAddFiledButton,
  });

  const fieldsBottomSideClasses = cn({
    "search-and-settings__fields-bottom-side": true,
    "search-and-settings__fields-bottom-side_additional-top-padding": noFields,
  });

  return (
    <div className="search-and-settings__contain-block">
      <div className="search-and-settings__title-bar">Настройка проекта</div>

      <div className="search-and-settings__contain-block-container">
        <div className="search-and-settings__top-select">
          <SelectStyle
            name={inputs.company.name}
            value={inputs.company.value}
            options={inputs.company.options}
            optionDefault={inputs.company.optionDefault}
            onChange={(event) => selectChange(event, inputs.company.name)}
            location={"projectSettings"}
          />
        </div>

        {inputs.company.value ? (
          <div className="search-and-settings__top-select">
            <SelectStyle
              name={inputs.project.name}
              value={inputs.project.value}
              options={inputs.project.options}
              optionDefault={inputs.project.optionDefault}
              onChange={(event) => selectChange(event, inputs.project.name)}
              location={"projectSettings"}
            />
          </div>
        ) : null}

        {inputs.company.value && inputs.project.value ? (
          <div className="search-and-settings__fields">
            {Object.keys(inputs.custom).length ? (
              <>
                <div className="search-and-settings__fields-header-bar">
                  <div className="search-and-settings__fields-cell">В реплику</div>
                  <div className="search-and-settings__fields-cell search-and-settings__fields-cell_margin-right-161px">
                    Тип поля
                  </div>
                  <div className="search-and-settings__fields-cell">Значение поля</div>
                </div>

                <div className="search-and-settings__fields-header-bar-mobile">
                  <div className="search-and-settings__fields-cell-mobile">
                    <div className="search-and-settings__fields-header-bar-mobile-arrow" />
                  </div>

                  <div className="search-and-settings__fields-cell-mobile">Тип и значение поля</div>
                </div>

                {Object.keys(inputs.custom).map((idx, index) => {
                  const input = inputs.custom[idx];

                  if (input.deleted === true) {
                    return true;
                  } else {
                    if (input.fieldType === "text" || input.fieldType === "url") {
                      return (
                        <ProjectSettingsFieldText
                          key={index}
                          id={input.id}
                          disabled={input.disabled}
                          viewReplica={input.viewReplica}
                          fieldType={input.fieldType}
                          fieldTypeName={input.fieldTypeName}
                          name={input.name}
                          value={input.value}
                          placeholder={input.placeholder}
                          change={(event) => fieldChange(event.target.value, input.id)}
                          changeCheckbox={(event) => changeCheckbox(event, input.id)}
                          delete={() => fieldDelete(input.id)}
                        />
                      );
                    } else if (input.fieldType === "select") {
                      return (
                        <ProjectSettingsFieldSelect
                          key={index}
                          id={input.id}
                          viewReplica={input.viewReplica}
                          fieldType={input.fieldType}
                          fieldTypeName={input.fieldTypeName}
                          name={input.name}
                          value={input.value}
                          placeholder={input.placeholder}
                          options={input.options}
                          change={(event) => fieldChange(event.target.value, input.id)}
                          changeCheckbox={(event) => changeCheckbox(event, input.id)}
                          delete={() => fieldDelete(input.id)}
                          selectAddField={() => selectAddField(input.id)}
                          selectChangeField={selectChangeField}
                          selectDeleteField={selectDeleteField}
                        />
                      );
                    } else if (input.fieldType === "note") {
                      return (
                        <ProjectSettingsFieldNote
                          key={index}
                          id={input.id}
                          disabled={input.disabled}
                          viewReplica={input.viewReplica}
                          fieldType={input.fieldType}
                          fieldTypeName={input.fieldTypeName}
                          name={input.name}
                          value={input.value}
                          placeholder={input.placeholder}
                          checked={input.checked}
                          change={(event) => changeCheckboxNote(event.target.checked, input.id)}
                          changeCheckbox={(event) => changeCheckbox(event, input.id)}
                          delete={() => fieldDelete(input.id)}
                        />
                      );
                    } else {
                      return false;
                    }
                  }
                })}
              </>
            ) : null}

            <div className={fieldsBottomSideClasses}>
              <div className={fieldsClasses}>
                <div className="search-and-settings__fields-select-container">
                  <SelectStyle
                    name={inputs.type.name}
                    value={inputs.type.value}
                    options={inputs.type.options}
                    optionDefault={inputs.type.optionDefault}
                    paddingRight={window.innerWidth < 768 ? "75px" : null}
                    onChange={(event) => selectChange(event, inputs.type.name)}
                    location={"fieldType"}
                  />
                  <div
                    className="search-and-settings__fields-select-container_close-btn"
                    onClick={changeClassesMobileFields}></div>
                </div>

                <div className="search-and-settings__fields-add-button" onClick={fieldAdd} />
              </div>

              <div className="search-and-settings__fields-buttons">
                <div
                  onClick={changeClassesMobileFields}
                  className="search-and-settings__fields-trigger"
                />

                <div className="search-and-settings__fields-button search-and-settings__fields-button_blue">
                  <button onClick={save}>Сохранить</button>
                </div>

                <div className="search-and-settings__fields-button">
                  <button onClick={props.close}>Отменить</button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProjectSettings;
