import React, { useContext, useState } from "react";

import "./ProjectEdit.scss";

import { apiProjectEdit } from "../../../../../api/projects";
import { PopupContext } from "../../../../../context/popup/popupContext";

const ProjectEdit = (props) => {
  const accessLevel = localStorage.getItem("accessLevel");
  const popupContextData = useContext(PopupContext);
  const [projectName, setProjectName] = useState(props.name);

  const projectEdit = async (event, projectId, projectName) => {
    event.preventDefault();

    popupContextData.setPreloader(true);
    const data = await apiProjectEdit(projectId, projectName);

    if (data.status === "fail") {
      popupContextData.setPreloader(false);
      alert(data.errorText);
    } else {
      props.close();
      props.getProjects();
    }
  };

  return (
    <div className="admin-company__project-manipulation admin-company__project-manipulation_editing">
      <div className="admin-company__project-manipulation-top-side">
        <div className="admin-company__project-manipulation-top-side-name">
          Редактирование проекта
        </div>

        <div
          className="admin-company__project-manipulation-top-side-button"
          onClick={props.close}
        />
      </div>

      <div className="admin-company__project-manipulation-content">
        <div className="admin-company__project-manipulation-input-container">
          <label htmlFor="">Название проекта:</label>

          <input
            type="text"
            id=""
            value={projectName}
            onChange={(event) => setProjectName(event.target.value)}
          />
        </div>

        <div className="admin-company__project-manipulation-buttons">
          <div className="admin-company__project-manipulation-button admin-company__project-manipulation-button_mobile-width-124px admin-company__project-manipulation-button_blue">
            <button onClick={(event) => projectEdit(event, props.id, projectName)}>
              Сохранить
            </button>
          </div>

          {accessLevel === "1" ? (
            <div className="admin-company__project-manipulation-button admin-company__project-manipulation-button_mobile-width-124px admin-company__project-manipulation-button_red">
              <button onClick={props.deleteShow}>Удалить</button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ProjectEdit;
