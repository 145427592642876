import React from "react";

import "./ProjectSettingsFieldSelectItem.scss";

import Input from "../../../../UI/Input/Input";

const ProjectSettingsFieldSelectItem = (props) => {
  return (
    <div className="search-and-settings__field-list-item">
      <Input
        type="text"
        name={props.name}
        value={props.value}
        placeholder="Пункт списка"
        onChange={props.selectChangeField}
      />

      <div className="search-and-settings__field-button-remove" onClick={props.selectDeleteField} />

      <div className="search-and-settings__field-button-add" onClick={props.selectAddField} />
    </div>
  );
};

export default ProjectSettingsFieldSelectItem;
