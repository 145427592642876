import React, { useContext } from "react";

import "./PopupNotice.scss";

import { PopupContext } from "../../context/popup/popupContext";

const PopupNotice = (props) => {
  const popupContextData = useContext(PopupContext);
  const cls = ["popup-notice"];

  const popupClose = () => {
    popupContextData.setPopupNotice({
      state: false,
      title: popupContextData.popupNotice.title,
      text: popupContextData.popupNotice.text,
    });
  };

  if (props.className) {
    cls.push(props.className);
  }

  return (
    <div className={cls.join(" ")}>
      <div className="popup-notice__wrap">
        <div className="popup-notice__plate">
          <div className="popup-notice__top-decor" />

          <div className="popup-notice__close" onClick={popupClose}>
            Закрыть
          </div>

          {popupContextData.popupNotice.title ? (
            <div className="popup-notice__title">{popupContextData.popupNotice.title}</div>
          ) : null}
          {popupContextData.popupNotice.text ? (
            <div className="popup-notice__text">{popupContextData.popupNotice.text}</div>
          ) : null}
          {popupContextData.popupNotice.list ? (
            <div className="popup-notice__list">
              {popupContextData.popupNotice.list.map((item, index) => {
                return (
                  <li key={index} className="popup-notice__list-item">
                    {item}
                  </li>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PopupNotice;
