import React from "react";

function IconGeo() {
  return (
    <svg width={22} height={26} viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5675 5.14521L16.567 5.14472C13.2914 1.86909 7.96534 1.86901 4.68961 5.14447C1.41281 8.39103 1.44504 13.7174 4.68887 16.9917L4.68964 16.9925L10.5224 22.7952L10.6282 22.9004L10.734 22.7952L16.5667 16.9925L16.5675 16.9917C19.8123 13.7163 19.8123 8.42081 16.5675 5.14521ZM10.6282 25.4551C10.3685 25.4551 10.1058 25.3517 9.89245 25.1384L3.21788 18.4638C-0.872651 14.3733 -0.872601 7.70357 3.21788 3.61304C7.30836 -0.47749 13.948 -0.477491 18.0386 3.61304C22.1291 7.70352 22.1291 14.3733 18.0386 18.4638L11.364 25.1383C11.1507 25.3517 10.888 25.4551 10.6282 25.4551Z"
        fill="#2E2382"
        stroke="white"
        strokeWidth="0.3"
      />
      <mask id="path-2-inside-1_1801_2017" fill="white">
        <path d="M10.6275 15.7613C13.0328 15.7613 14.987 13.8071 14.987 11.4018C14.987 8.99652 13.0328 7.04236 10.6275 7.04236C8.22222 7.04236 6.26807 8.99652 6.26807 11.4018C6.26807 13.8071 8.22222 15.7613 10.6275 15.7613ZM10.6275 9.4475C11.7099 9.4475 12.5817 10.3193 12.5817 11.4017C12.5817 12.484 11.7099 13.3558 10.6275 13.3558C9.54517 13.3558 8.67337 12.484 8.67337 11.4017C8.67337 10.3193 9.54517 9.4475 10.6275 9.4475Z" />
      </mask>
      <path
        d="M10.6275 15.7613C13.0328 15.7613 14.987 13.8071 14.987 11.4018C14.987 8.99652 13.0328 7.04236 10.6275 7.04236C8.22222 7.04236 6.26807 8.99652 6.26807 11.4018C6.26807 13.8071 8.22222 15.7613 10.6275 15.7613ZM10.6275 9.4475C11.7099 9.4475 12.5817 10.3193 12.5817 11.4017C12.5817 12.484 11.7099 13.3558 10.6275 13.3558C9.54517 13.3558 8.67337 12.484 8.67337 11.4017C8.67337 10.3193 9.54517 9.4475 10.6275 9.4475Z"
        fill="#2E2382"
      />
      <path
        d="M10.6275 16.0613C13.1985 16.0613 15.287 13.9728 15.287 11.4018H14.687C14.687 13.6414 12.8671 15.4613 10.6275 15.4613V16.0613ZM15.287 11.4018C15.287 8.83083 13.1985 6.74236 10.6275 6.74236V7.34236C12.8671 7.34236 14.687 9.1622 14.687 11.4018H15.287ZM10.6275 6.74236C8.05654 6.74236 5.96807 8.83083 5.96807 11.4018H6.56807C6.56807 9.1622 8.38791 7.34236 10.6275 7.34236V6.74236ZM5.96807 11.4018C5.96807 13.9728 8.05654 16.0613 10.6275 16.0613V15.4613C8.38791 15.4613 6.56807 13.6414 6.56807 11.4018H5.96807ZM10.6275 9.7475C11.5442 9.7475 12.2817 10.485 12.2817 11.4017H12.8817C12.8817 10.1536 11.8756 9.1475 10.6275 9.1475V9.7475ZM12.2817 11.4017C12.2817 12.3183 11.5442 13.0558 10.6275 13.0558V13.6558C11.8756 13.6558 12.8817 12.6497 12.8817 11.4017H12.2817ZM10.6275 13.0558C9.71085 13.0558 8.97337 12.3183 8.97337 11.4017H8.37337C8.37337 12.6497 9.37948 13.6558 10.6275 13.6558V13.0558ZM8.97337 11.4017C8.97337 10.485 9.71085 9.7475 10.6275 9.7475V9.1475C9.37948 9.1475 8.37337 10.1536 8.37337 11.4017H8.97337Z"
        fill="white"
        mask="url(#path-2-inside-1_1801_2017)"
      />
    </svg>
  );
}

export default IconGeo;
