import React from "react";

import "./CompanyItemText.scss";

import { apiCompanyChangeActive } from "../../../../../api/companies";

const CompanyItemText = (props) => {
  const accessLevel = localStorage.getItem("accessLevel");

  const changeActive = async (companyId, companyActive) => {
    const data = await apiCompanyChangeActive(companyId, !companyActive);

    if (data.status === "fail") {
      alert(data.errorText);
    } else {
      props.getCompanies();
    }
  };

  return (
    <div className="admin-company__company-item-bar">
      <div className="admin-company__company-item-name" onClick={props.companyOpen}>
        {props.name}
      </div>

      {accessLevel === "1" || accessLevel === "5" ? (
        <>
          <div className="admin-company__company-item-edit" onClick={props.companyEdit} />

          <div
            className="admin-company__company-item-switcher"
            onClick={() => changeActive(props.id, props.active)}
          />
        </>
      ) : null}
    </div>
  );
};

export default CompanyItemText;
