import React from "react";

function IconNotification() {
  return (
    <svg width={16} height={17} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.95158 13.5999H13.3443C13.9947 13.5993 14.6183 13.3406 15.078 12.8805C15.5377 12.4206 15.796 11.7969 15.796 11.1465C15.796 10.4962 15.5379 9.87229 15.0783 9.41223L13.5312 7.86534V5.3832C13.5312 3.45999 12.5053 1.68284 10.8396 0.72113C9.17415 -0.240377 7.12192 -0.240377 5.45633 0.72113C3.79064 1.68284 2.76473 3.45999 2.76473 5.3832V7.86514L1.21764 9.41223C0.758136 9.87233 0.5 10.4963 0.5 11.1465C0.5 11.7969 0.758312 12.4207 1.21799 12.8805C1.67769 13.3406 2.30123 13.5993 2.95168 13.5999H2.95158ZM2.41896 10.6137L4.46452 8.5679V5.3832C4.46452 4.06733 5.16652 2.85148 6.30628 2.19334C7.44583 1.5354 8.84984 1.5354 9.98944 2.19334C11.1292 2.85148 11.8312 4.06733 11.8312 5.3832V8.5679L13.877 10.6137H13.8768C14.0181 10.755 14.0973 10.9465 14.0973 11.1463C14.0973 11.3461 14.0179 11.5378 13.8768 11.6789C13.7354 11.8202 13.5439 11.8998 13.3441 11.8998H2.95143C2.75162 11.8998 2.56011 11.8202 2.41882 11.6789C2.2777 11.5378 2.19825 11.3461 2.19825 11.1463C2.19825 10.9465 2.27751 10.7549 2.41882 10.6137H2.41896Z"
        fill="#E00C3C"
      />
      <path d="M5.88086 15.2998H10.4143V16.9999H5.88086V15.2998Z" fill="#E00C3C" />
    </svg>
  );
}

export default IconNotification;
