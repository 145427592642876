import { ITableHeaderData } from "./TableHeader.types";

export const tableHeader: ITableHeaderData[] = [
  {
    id: 1,
    name: "Домен",
  },
  {
    id: 2,
    name: "URL",
  },
  {
    id: 3,
    name: "Шкала",
  },
  {
    id: 4,
    name: "Рейтинг",
  },
  {
    id: 5,
    name: "Заголовок",
  },
  {
    id: 6,
    name: "Описание",
  },
  {
    id: 7,
    name: "Тональность",
  },
];
