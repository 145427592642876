import axios from "axios";
import React, { useContext, useEffect, useState } from "react";

import "./ReplicaAdd.scss";
import ReplicaAddCustom from "./ReplicaAddCustom/ReplicaAddCustom";
import ReplicaAddMain from "./ReplicaAddMain/ReplicaAddMain";

import config from "../../../../config/config";
import { PopupContext } from "../../../../context/popup/popupContext";
import SelectStyle from "../../../UI/SelectStyle/SelectStyle";
import customFields from "../ProjectSettings/customFields";

const ReplicaAdd = (props) => {
  const popupContextData = useContext(PopupContext);

  const dateFormat = new Intl.DateTimeFormat("ru", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const initialState = {
    company: {
      name: "company",
      value: "",
      options: [],
      optionDefault: "Компания",
      disabled: false,
    },
    project: {
      name: "project",
      value: "",
      options: [],
      optionDefault: "Проект",
      disabled: false,
    },
    category: {
      name: "category",
      value: "",
      options: [],
      optionDefault: "Категория",
    },
    date: {
      type: "text",
      name: "date",
      value: dateFormat.format(Date.now()),
      placeholder: false,
      label: "Дата",
    },
    platform: {
      type: "text",
      name: "platform",
      value: "",
      placeholder: false,
      label: "Площадка",
    },
    msg: {
      type: "textarea",
      name: "msg",
      value: "",
      placeholder: false,
      label: "Текст реплики",
    },
    url: {
      type: "text",
      name: "url",
      value: "",
      placeholder: "Введите URL",
      label: "URL",
    },
    agentName: {
      type: "text",
      name: "agentName",
      value: "",
      placeholder: "М или Ж",
      label: "Имя агента",
    },
    noteSystem: {
      type: "text",
      name: "noteSystem",
      value: "",
      placeholder: "Примечание",
      label: "Примечание",
    },
    custom: {},
  };

  const [inputs, setInputs] = useState(initialState);

  const addDataForUpdate = async () => {
    await getCategories();

    const inputsCopy = { ...inputs };

    if (props.company) {
      inputsCopy.company.disabled = true;
      inputsCopy.company.value = props.company._id;

      inputsCopy.company.options.push({
        id: props.company._id,
        name: props.company.name,
      });
    }

    if (props.project) {
      inputsCopy.project.disabled = true;
      inputsCopy.project.value = props.project._id;

      inputsCopy.project.options.push({
        id: props.project._id,
        name: props.project.name,
      });

      inputsCopy.custom = await getField(inputsCopy.project.value);
    }

    if (props.category) {
      inputsCopy.category.value = props.category._id;
    }

    if (props.date) {
      inputsCopy.date.value = props.date;
    }

    if (props.url) {
      inputsCopy.url.value = props.url;
    }

    if (props.platform) {
      inputsCopy.platform.value = props.platform;
    }

    if (props.msg) {
      inputsCopy.msg.value = props.msg;
    }

    if (props.noteSystem) {
      inputsCopy.noteSystem.value = props.noteSystem; // d
    }

    if (props.fields.length) {
      props.fields.map((fieldData) => {
        const fieldType = fieldData.field.type;
        const fieldId = fieldData.field._id.toString();

        if (fieldData.valueOptions) {
          inputsCopy.custom[fieldId].value =
            fieldType === "select" ? fieldData.valueOptions._id.toString() : fieldData.value;
          inputsCopy.custom[fieldId].date = dateFormat.format(new Date(fieldData.createdDate));
        } else {
          inputsCopy.custom[fieldId].value = fieldData.value;
          inputsCopy.custom[fieldId].date = dateFormat.format(new Date(fieldData.createdDate));
        }

        return true;
      });
    }
    setInputs(inputsCopy);
  };

  const addDataForAddChildren = async () => {
    await getCategories();

    const inputsCopy = { ...inputs };

    if (props.company) {
      inputsCopy.company.disabled = true;
      inputsCopy.company.value = props.company._id;

      inputsCopy.company.options.push({
        id: props.company._id,
        name: props.company.name,
      });
    }

    if (props.project) {
      inputsCopy.project.disabled = true;
      inputsCopy.project.value = props.project._id;

      inputsCopy.project.options.push({
        id: props.project._id,
        name: props.project.name,
      });

      inputsCopy.custom = await getField(inputsCopy.project.value);
    }

    setInputs(inputsCopy);
  };

  const getCompanies = async () => {
    popupContextData.setPreloader(true);

    const token = localStorage.getItem("token");
    const response = await axios.get(`${config.SERVER_BASE_URL}/replica/companies?token=${token}`);
    const responseData = response.data;

    if (responseData.status === "fail") {
      alert(responseData.errorText);
    } else {
      const inputsCopy = { ...inputs };

      if (responseData.data) {
        Object.keys(responseData.data).map((idx) => {
          return inputsCopy.company.options.push({
            id: responseData.data[idx].id,
            name: responseData.data[idx].name,
          });
        });
      }

      if (localStorage.getItem("filter-replica-company")) {
        inputsCopy.company.value = localStorage.getItem("filter-replica-company");
        inputsCopy.project.value = "";
        inputsCopy.project.options = await getProjects(
          localStorage.getItem("filter-replica-company"),
        );
        inputsCopy.custom = {};

        if (localStorage.getItem("filter-replica-project")) {
          inputsCopy.project.value = localStorage.getItem("filter-replica-project");
          inputsCopy.custom = await getField(localStorage.getItem("filter-replica-project"));
        }
      }

      setInputs(inputsCopy);
    }

    popupContextData.setPreloader(false);
  };

  const getProjects = async (companyId) => {
    const projects = [];

    if (companyId) {
      popupContextData.setPreloader(true);

      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${config.SERVER_BASE_URL}/replica/projects?token=${token}&companyId=${companyId}`,
      );
      const responseData = response.data;

      if (responseData.status === "success" && responseData.data.length) {
        Object.keys(responseData.data).map((idx) => {
          return projects.push({
            id: responseData.data[idx].id,
            name: responseData.data[idx].name,
          });
        });
      }

      popupContextData.setPreloader(false);
    }

    return projects;
  };

  const getCategories = async () => {
    popupContextData.setPreloader(true);

    const token = localStorage.getItem("token");
    const response = await axios.get(`${config.SERVER_BASE_URL}/categories?token=${token}`);
    const responseData = response.data;

    if (responseData.status === "fail") {
      alert(responseData.errorText);
    } else {
      const inputsCopy = { ...inputs };

      if (responseData.data) {
        Object.keys(responseData.data).map((idx) => {
          return inputsCopy.category.options.push({
            id: responseData.data[idx].id,
            name: responseData.data[idx].name,
          });
        });
      }

      if (localStorage.getItem("filter-replica-category")) {
        inputsCopy.category.value = localStorage.getItem("filter-replica-category");
      }

      setInputs(inputsCopy);
    }

    popupContextData.setPreloader(false);
  };

  const getField = async (projectId) => {
    const fields = {};

    if (projectId) {
      popupContextData.setPreloader(true);

      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${config.SERVER_BASE_URL}/replica/projects/settings/${projectId}?token=${token}`,
      );
      const responseData = response.data;

      if (responseData.status === "success" && responseData.data.length) {
        responseData.data.map((data) => {
          const name = data._id.toString();

          fields[name] = {
            fieldType: customFields[data.type].settings.fieldType,
            fieldTypeName: customFields[data.type].settings.fieldTypeName,
            name: name,
            label: data.name ? data.name : customFields[data.type].settings.fieldTypeName,
            value: "",
          };

          if (data.type === "select" && data.options.length) {
            fields[name].options = [];

            data.options.map((option) => {
              return fields[name].options.push({
                id: option._id.toString(),
                name: option.value,
              });
            });
          }

          return true;
        });
      }

      popupContextData.setPreloader(false);
    }

    return fields;
  };

  const replicaAdd = async (event, type) => {
    event.preventDefault();

    popupContextData.setPreloader(true);
    const token = localStorage.getItem("token");
    const data = { token, type };

    if (props.id) {
      data.id = props.id;
    }

    Object.keys(inputs).map((idx) => {
      if (idx === "custom") {
        return (data.custom = inputs[idx]);
      } else {
        return (data[inputs[idx].name] = inputs[idx].value);
      }
    });

    if (props.replicaType === 1 || props.replicaType === 2) {
      data.replicaType = props.replicaType;

      if (props.parentId) {
        data.parentId = props.parentId;
      }
    }

    // if (type === 'save') {
    //     data.status = props.status
    // }

    const response = await axios.post(`${config.SERVER_BASE_URL}/replica`, data);
    const responseData = response.data;

    if (responseData.status === "fail") {
      popupContextData.setPreloader(false);
      alert(responseData.errorText);
    } else {
      props.replicaChildrenUpdateStart();
      props.close();
      props.updateReplicaList();

      if (props.accessLevel === 2) {
        popupContextData.onOpenModal("saveReplica");
      }
    }
  };

  const getURL = (url) => {
    if (url) {
      url = url.split("https://").join("");
      url = url.split("http://").join("");
      url = url.split("www.").join("");
      url = url.split("/");
      url = url[0];

      return url;
    } else {
      return "";
    }
  };

  const selectChange = async (event, name) => {
    console.log(name);
    console.log(event);
    const value = event.target.value;
    const inputsCopy = { ...inputs };
    inputsCopy[name].value = value;

    if (name === "company") {
      inputsCopy.project.value = "";
      inputsCopy.project.options = await getProjects(value);
      inputsCopy.custom = {};
    } else if (name === "project") {
      inputsCopy.custom = await getField(value);
      props.setTabsShow(false);
    }
    setInputs(inputsCopy);
  };

  const fieldChange = (event, name) => {
    const value = event.target.value;
    const inputsCopy = { ...inputs };
    inputsCopy[name].value = value;

    if (name === "url") {
      inputsCopy.platform.value = getURL(value);
    }

    setInputs(inputsCopy);
  };

  const selectCustomChange = (event, name) => {
    const value = event.target.value;
    const inputsCopy = { ...inputs };
    inputsCopy.custom[name].value = value;
    setInputs(inputsCopy);
  };

  const fieldCustomChange = (event, name) => {
    const value = event.target.value;
    const inputsCopy = { ...inputs };
    inputsCopy.custom[name].value = value;
    setInputs(inputsCopy);
  };

  useEffect(() => {
    if (props.id) {
      addDataForUpdate();
    } else if (props.parentId) {
      addDataForAddChildren();
    } else {
      getCompanies();
      getCategories();
    }

     
  }, []);

  return (
    <>
      <div className="add-edit-replic" data-container={props.parentId ? "children" : "main"}>
        {props.children ? (
          <div>
            <div className="add-edit-replic-vertical-line" data-line={props.dataline} />
            <div className="add-edit-replic-horizont-line" />
          </div>
        ) : null}
        <div className="add-edit-replic-top-side">
          <div className="add-edit-replic-top-side-name">
            {props.id ? (
              <>Редактирование реплики</>
            ) : props.replicaType === 1 ? (
              <>Добавление реплики в инициацию</>
            ) : props.replicaType === 2 ? (
              <>Добавление реплики в реагирование</>
            ) : (
              <>Добавление реплики</>
            )}
          </div>

          <div className="add-edit-replic-top-side-select-container">
            <div
              className="add-edit-replic-top-side-select-unit"
              data-disabled={inputs["company"].disabled ? "Y" : null}>
              <SelectStyle
                name={inputs["company"].name}
                value={inputs["company"].value}
                options={inputs["company"].options}
                optionDefault={inputs["company"].optionDefault}
                disabled={inputs["company"].disabled}
                onChange={(event) => selectChange(event, inputs["company"].name)}
                location={"addReplica"}
              />
            </div>

            <div
              className="add-edit-replic-top-side-select-unit"
              data-disabled={inputs["project"].disabled ? "Y" : null}>
              <SelectStyle
                name={inputs["project"].name}
                value={inputs["project"].value}
                options={inputs["project"].options}
                optionDefault={inputs["project"].optionDefault}
                disabled={inputs["project"].disabled}
                onChange={(event) => {
                  selectChange(event, inputs["project"].name);
                }}
                location={"addReplica"}
              />
            </div>

            {props.replicaType !== 1 ? (
              <div className="add-edit-replic-top-side-select-unit">
                <SelectStyle
                  name={inputs["category"].name}
                  value={inputs["category"].value}
                  options={inputs["category"].options}
                  optionDefault={inputs["category"].optionDefault}
                  onChange={(event) => selectChange(event, inputs["category"].name)}
                  location={"addReplica"}
                />
              </div>
            ) : null}

            <div className="add-edit-replic-top-side-select-unit">
              <div
                className={`add-edit-replic-top-side-select-unit-text ${
                  props.status ? props.status.code : "created"
                }`}>
                {props.status ? props.status.name : "Создана"}
              </div>
            </div>
          </div>

          {!props.parentId ? (
            <div className="add-edit-replic-top-side-bitton-close" onClick={props.close} />
          ) : null}
        </div>

        {inputs["company"].value && inputs["project"].value ? (
          <ReplicaAddMain
            id={props.id || false}
            date={inputs.date}
            platform={inputs.platform}
            company={props.company}
            project={props.project}
            msg={inputs.msg}
            url={inputs.url}
            agentName={inputs.agentName}
            noteSystem={inputs.noteSystem}
            fieldChange={(event, name) => fieldChange(event, name)}
          />
        ) : null}

        {inputs["company"].value && inputs["project"].value ? (
          <ReplicaAddCustom
            id={props.id}
            custom={inputs.custom}
            onlyView={props.onlyView}
            save={(event) => replicaAdd(event, "save")}
            public={(event) => replicaAdd(event, "public")}
            close={props.close}
            fieldCustomChange={(event, name) => fieldCustomChange(event, name)}
            selectCustomChange={(event, name) => selectCustomChange(event, name)}
            accessLevel={props.accessLevel}
            msg={props.msg}
          />
        ) : null}
      </div>
    </>
  );
};

export default ReplicaAdd;
