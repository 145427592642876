import React from "react";

import "./RoleList.scss";

import RoleItem from "../RoleItem/RoleItem";

const RoleList = (props) => {
  return (
    <>
      <div className="admin-company__top-side">
        <div className="admin-company__top-side-cell">Роль:</div>
      </div>

      <div className="admin-company__container">
        {Object.keys(props.roles).map((roleId, index) => {
          const role = props.roles[roleId];

          return (
            <RoleItem
              key={index}
              id={role._id}
              name={role.name}
              accessLevel={role.accessLevel}
              filter={props.filter}
              openRole={role.openRole}
              onToggleRole={(roleId) => props.onToggleRole(roleId)}
            />
          );
        })}
      </div>
    </>
  );
};

export default RoleList;
