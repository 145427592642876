import axios from "axios";

import { ISearchingQueriesParams, ISearchingQueriesResponse } from "./searching-queries.types";

import config from "../../config/config";

export const fetchSearchingQueries = async (
  params: ISearchingQueriesParams,
): Promise<ISearchingQueriesResponse | null> => {
  try {
    const response = await axios.get(
      `${config.SERVER_BASE_URL}/searching-queries/fetch-searching-queries`,
      { params },
    );

    return response?.data?.data;
  } catch (error) {
    return null;
  }
};
