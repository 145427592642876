import React from "react";

export const IconTrash = ({ className }) => {
  return (
    <svg
      className={className}
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_1103_3246" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 3V2H11V3H6ZM4 3V1.68906C4 0.675992 4.89592 0 5.72692 0H11.383C12.4252 0 13 0.894935 13 1.68906V3H18V5H12.5658H5.47529H0V3H4ZM4 6V17.7997C4 17.9251 4.10085 18 4.17739 18H13.8226C13.8992 18 14 17.9251 14 17.7997V6H16V17.7997C16 18.9932 15.0399 20 13.8226 20H4.17739C2.96015 20 2 18.9932 2 17.7997V6H4ZM6 16V6H8V16H6ZM10 6V16H12V6H10Z"
        />
      </mask>
      <path
        d="M6 2V0H4V2H6ZM6 3H4V5H6V3ZM11 2H13V0H11V2ZM11 3V5H13V3H11ZM4 3V5H6V3H4ZM13 3H11V5H13V3ZM18 3H20V1H18V3ZM18 5V7H20V5H18ZM0 5H-2V7H0V5ZM0 3V1H-2V3H0ZM4 6H6V4H4V6ZM14 6V4H12V6H14ZM16 6H18V4H16V6ZM2 6V4H0V6H2ZM6 6V4H4V6H6ZM6 16H4V18H6V16ZM8 6H10V4H8V6ZM8 16V18H10V16H8ZM10 16H8V18H10V16ZM10 6V4H8V6H10ZM12 16V18H14V16H12ZM12 6H14V4H12V6ZM4 2V3H8V2H4ZM11 0H6V4H11V0ZM13 3V2H9V3H13ZM6 5H11V1H6V5ZM2 1.68906V3H6V1.68906H2ZM5.72692 -2C3.99103 -2 2 -0.618201 2 1.68906H6C6 1.73699 5.98807 1.79531 5.96223 1.85016C5.93817 1.90122 5.91001 1.93297 5.89117 1.94995C5.87344 1.96594 5.85718 1.97532 5.8402 1.98202C5.8239 1.98845 5.78745 2 5.72692 2V-2ZM11.383 -2H5.72692V2H11.383V-2ZM15 1.68906C15 0.111651 13.8257 -2 11.383 -2V2C11.3499 2 11.2846 1.99118 11.2092 1.95495C11.1374 1.92046 11.0873 1.87606 11.0571 1.84046C11.0288 1.8071 11.0168 1.78073 11.0114 1.76559C11.0063 1.75124 11 1.72788 11 1.68906H15ZM15 3V1.68906H11V3H15ZM18 1H13V5H18V1ZM20 5V3H16V5H20ZM12.5658 7H18V3H12.5658V7ZM5.47529 7H12.5658V3H5.47529V7ZM0 7H5.47529V3H0V7ZM-2 3V5H2V3H-2ZM4 1H0V5H4V1ZM6 17.7997V6H2V17.7997H6ZM4.17739 16C4.74358 16 5.18594 16.2476 5.46626 16.5248C5.78586 16.8408 6 17.2932 6 17.7997H2C2 19.0508 3.01746 20 4.17739 20V16ZM13.8226 16H4.17739V20H13.8226V16ZM12 17.7997C12 17.2932 12.2141 16.8408 12.5337 16.5248C12.8141 16.2476 13.2564 16 13.8226 16V20C14.9825 20 16 19.0508 16 17.7997H12ZM12 6V17.7997H16V6H12ZM16 4H14V8H16V4ZM18 17.7997V6H14V17.7997H18ZM13.8226 22C16.1855 22 18 20.0563 18 17.7997H14C14 17.8541 13.9786 17.9075 13.9381 17.9488C13.9012 17.9865 13.8639 18 13.8226 18V22ZM4.17739 22H13.8226V18H4.17739V22ZM0 17.7997C0 20.0563 1.81449 22 4.17739 22V18C4.1361 18 4.09878 17.9865 4.06185 17.9488C4.02141 17.9075 4 17.8541 4 17.7997H0ZM0 6V17.7997H4V6H0ZM4 4H2V8H4V4ZM4 6V16H8V6H4ZM8 4H6V8H8V4ZM10 16V6H6V16H10ZM6 18H8V14H6V18ZM12 16V6H8V16H12ZM12 14H10V18H12V14ZM10 6V16H14V6H10ZM10 8H12V4H10V8Z"
        fill="white"
        mask="url(#path-1-inside-1_1103_3246)"
      />
    </svg>
  );
};
