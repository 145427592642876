import cn from "classnames";
import React from "react";

import classes from "./RowInner.module.scss";

import IconPencil from "../../../../icons/IconPencil";
import AddRow from "../../UI/AddRow/AddRow";
import DeleteRow from "../../UI/DeleteRow/DeleteRow";
import TextField from "../../UI/TextField/TextField";

function RowInner({
  item,
  isLast,
  onAddInnerRow,
  rowId,
  onDeleteInnerRow,
  onChangeFieldsInnerRow,
}) {
  return (
    <div className={cn(classes.Item)}>
      <div className={classes.Columns}>
        <div className={`${classes.ItemCol} ${classes.Name}`}>
          <div className={classes.ItemColInner}>
            <div className={classes.ItemValue}>
              {item.name}
              <IconPencil />
            </div>

            <TextField
              className={classes.TextField}
              value={item.name}
              onChange={(e) =>
                onChangeFieldsInnerRow(rowId, item.id, "name", e.currentTarget.value)
              }
              isShowIcon={false}
            />
          </div>
        </div>

        <div className={`${classes.ItemCol} ${classes.RemainsOld}`}>
          <div className={classes.ItemColInner}>
            <div className={classes.ItemValue}>
              {item.remainsOld}
              <IconPencil />
            </div>

            <TextField
              className={classes.TextField}
              value={item.remainsOld}
              onChange={(e) =>
                onChangeFieldsInnerRow(rowId, item.id, "remainsOld", e.currentTarget.value)
              }
              isShowIcon={false}
              type="number"
            />
          </div>
        </div>

        <div className={`${classes.ItemCol} ${classes.Needed}`}>
          <div className={classes.ItemColInner}>
            <div className={classes.ItemValue}>
              {item.needed}
              <IconPencil />
            </div>

            <TextField
              className={classes.TextField}
              value={item.needed}
              onChange={(e) =>
                onChangeFieldsInnerRow(rowId, item.id, "needed", e.currentTarget.value)
              }
              isShowIcon={false}
              type="number"
            />
          </div>
        </div>
      </div>

      {isLast && <AddRow onClick={() => onAddInnerRow(rowId)} isMini />}
      <DeleteRow
        className={classes.DeleteRow}
        onClick={() => onDeleteInnerRow(rowId, item.id)}
        isMini
      />
    </div>
  );
}

export default RowInner;
