import React, { useEffect, useState } from "react";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import classes from "./TonalBlockChartLine.module.scss";
import "./charttets.css";

const onPercentage = (number, total) => {
  if (total === 0) {
    return 0;
  }

  const calcualtion = (number / total) * 100;
  return calcualtion;
};

const colorsNmae = {
  negative: "Негатив",
  neutral: "Нерелевант",
  pending: "Управляемое",
  positive: "Позитив",
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload?.length) {
    return (
      <div className={classes.Tooltip}>
        <p className={classes.TooltipDate}>{label}</p>

        {payload.map((item, index) => (
          <p className={classes.TooltipItem} key={index} style={{ color: `${item.fill}` }}>
            {`${colorsNmae[item.dataKey]} : ${item.value}%`}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

function TonalBlockChartLine({ data }) {
  const [result, setResult] = useState([]);

  useEffect(() => {
    if (data?.length) {
      const newArr = [];

      data.forEach((item) => {
        const [day, month, year] = item.date.split(".");
        const dateResult = `${day}.${month}.${year.substring(2)}`;

        newArr.push({
          name: dateResult,
          pending: onPercentage(item.pending, item.all).toFixed(1),
          neutral: onPercentage(item.neutral, item.all).toFixed(1),
          positive: onPercentage(item.positive, item.all).toFixed(1),
          negative: onPercentage(item.negative, item.all).toFixed(1),
        });
      });

      setResult(newArr);
    }
  }, [data]);

  return (
    <div className={classes.Wrap} id="tonalBlockChartLine">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={result}
          margin={{
            top: 20,
            bottom: 5,
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis
            domain={[0, 100]}
            ticks={[0, 25, 50, 75, 100]}
            tickFormatter={(value) => `${value}%`}
            padding={{ right: 30 }}
          />

          <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
          <Bar dataKey="pending" stackId="a" fill="#FF6400" barSize={18} radius={[0, 0, 8, 8]} />
          <Bar dataKey="neutral" stackId="a" fill="#505D6F" barSize={18} />
          <Bar dataKey="positive" stackId="a" fill="#119E00" barSize={18} />
          <Bar dataKey="negative" stackId="a" fill="#E00C3C" barSize={18} radius={[8, 8, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default TonalBlockChartLine;
