import React, { useContext } from "react";

import IconCalendar from "./IconCalendar";
import classes from "./TitleRow.module.scss";

import { AnaliseContext } from "../../../../context/analise";
import IconChecked from "../../icons/IconChecked";
import IconNegative from "../../icons/IconNegative";
import IconNeutral from "../../icons/IconNeutral";
import IconPending from "../../icons/IconPending";
import IconPositive from "../../icons/IconPositive";
import DateField from "../DateField/DateField";

function TitleRow() {
  const { state, actions } = useContext(AnaliseContext);

  const handlerChangeActiveCharts = (name, value) => {
    const copyObj = { ...state.activeCharts, [name]: value };
    actions.onChangeState("activeCharts", copyObj);
  };

  return (
    <div className={classes.TitleBlock}>
      <div className={classes.Title}>
        {state.activeTabScreen === "search" && "Поисковая выдача"}
        {state.activeTabScreen === "tonality" && "Анализ тональности"}
        {state.activeTabScreen === "rating" && "Анализ рейтинга площадок"}
      </div>

      {/* TODO: сделать компонент + мобилка */}
      {state.activeTabScreen === "rating" && (
        <div className={classes.Checkboks}>
          <label htmlFor="positive" className={classes.CheckboksField}>
            <IconPositive className={classes.CheckboksIcon} />
            <input
              type="checkbox"
              checked={state.activeCharts.positive}
              name="positive"
              id="positive"
              onChange={(e) => handlerChangeActiveCharts("positive", e.currentTarget.checked)}
            />
            <IconChecked className={classes.CheckboksCheck} />
          </label>

          <label htmlFor="negative" className={classes.CheckboksField}>
            <IconNegative className={classes.CheckboksIcon} />
            <input
              type="checkbox"
              checked={state.activeCharts.negative}
              name="negative"
              id="negative"
              onChange={(e) => handlerChangeActiveCharts("negative", e.currentTarget.checked)}
            />
            <IconChecked className={classes.CheckboksCheck} />
          </label>

          <label htmlFor="neutral" className={classes.CheckboksField}>
            <IconNeutral className={classes.CheckboksIcon} />
            <input
              type="checkbox"
              checked={state.activeCharts.neutral}
              name="neutral"
              id="neutral"
              onChange={(e) => handlerChangeActiveCharts("neutral", e.currentTarget.checked)}
            />
            <IconChecked className={classes.CheckboksCheck} />
          </label>

          <label htmlFor="pending" className={classes.CheckboksField}>
            <IconPending className={classes.CheckboksIcon} />
            <input
              type="checkbox"
              checked={state.activeCharts.pending}
              name="pending"
              id="pending"
              onChange={(e) => handlerChangeActiveCharts("pending", e.currentTarget.checked)}
            />
            <IconChecked className={classes.CheckboksCheck} />
          </label>
        </div>
      )}

      <div className={classes.DateBlock}>
        <IconCalendar />

        <div className={classes.Date}>
          <DateField
            value={state.dateFrom}
            onChange={(name, date) => actions.onChangeState("dateFrom", date.toLocaleDateString())}
            name="dateFrom"
            showMonths={2}
          />

          <span className={classes.Separator}>&#8212;</span>

          <DateField
            value={state.dateTo}
            onChange={(name, date) => actions.onChangeState("dateTo", date.toLocaleDateString())}
            name="dateTo"
            showMonths={2}
          />
        </div>
      </div>
    </div>
  );
}

export default TitleRow;
