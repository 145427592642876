import React from "react";

function IconInfo() {
  return (
    <svg width={31} height={31} viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.181 11.8967C16.3316 11.8967 17.2643 10.964 17.2643 9.81335C17.2643 8.66274 16.3316 7.72998 15.181 7.72998C14.0304 7.72998 13.0977 8.66274 13.0977 9.81335C13.0977 10.964 14.0304 11.8967 15.181 11.8967Z"
        fill="white"
      />
      <path
        d="M16.9139 15.5218V22.3884C16.9139 22.8482 16.7313 23.289 16.4062 23.6141C16.0812 23.9392 15.6403 24.1218 15.1806 24.1218C14.7209 24.1218 14.28 23.9392 13.9549 23.6141C13.6299 23.289 13.4473 22.8482 13.4473 22.3884V15.5218C13.4473 15.0621 13.6299 14.6212 13.9549 14.2961C14.28 13.9711 14.7209 13.7885 15.1806 13.7885C15.6403 13.7885 16.0812 13.9711 16.4062 14.2961C16.7313 14.6212 16.9139 15.0621 16.9139 15.5218Z"
        fill="white"
      />
      <path
        d="M15.1807 0.913574C6.89666 0.913574 0.180664 7.62957 0.180664 15.9136C0.180664 24.1976 6.89666 30.9136 15.1807 30.9136C23.4647 30.9136 30.1807 24.1976 30.1807 15.9136C30.1807 7.62957 23.4647 0.913574 15.1807 0.913574ZM23.9013 24.6342C22.7597 25.7955 21.3993 26.7192 19.8988 27.352C18.3983 27.9848 16.7874 28.3142 15.1589 28.3211C13.5304 28.3281 11.9167 28.0125 10.4108 27.3925C8.905 26.7725 7.53684 25.8604 6.38533 24.7089C5.23381 23.5574 4.32175 22.1892 3.70176 20.6834C3.08178 19.1775 2.76615 17.5638 2.77309 15.9354C2.78003 14.3069 3.10941 12.6959 3.74221 11.1954C4.37501 9.69489 5.29871 8.33456 6.46 7.19291C7.60165 6.03162 8.96198 5.10792 10.4625 4.47512C11.963 3.84232 13.574 3.51294 15.2024 3.506C16.8309 3.49906 18.4446 3.81469 19.9505 4.43467C21.4563 5.05466 22.8245 5.96672 23.976 7.11824C25.1275 8.26975 26.0396 9.63791 26.6596 11.1438C27.2796 12.6496 27.5952 14.2633 27.5882 15.8918C27.5813 17.5203 27.2519 19.1312 26.6191 20.6318C25.9863 22.1323 25.0626 23.4926 23.9013 24.6342Z"
        fill="white"
      />
      <path
        d="M15.1807 0.913574C6.89666 0.913574 0.180664 7.62957 0.180664 15.9136C0.180664 24.1976 6.89666 30.9136 15.1807 30.9136C23.4647 30.9136 30.1807 24.1976 30.1807 15.9136C30.1807 7.62957 23.4647 0.913574 15.1807 0.913574ZM23.9013 24.6342C22.7597 25.7955 21.3993 26.7192 19.8988 27.352C18.3983 27.9848 16.7874 28.3142 15.1589 28.3211C13.5304 28.3281 11.9167 28.0125 10.4108 27.3925C8.905 26.7725 7.53684 25.8604 6.38533 24.7089C5.23381 23.5574 4.32175 22.1892 3.70176 20.6834C3.08178 19.1775 2.76615 17.5638 2.77309 15.9354C2.78003 14.3069 3.10941 12.6959 3.74221 11.1954C4.37501 9.69489 5.29871 8.33456 6.46 7.19291C7.60165 6.03162 8.96198 5.10792 10.4625 4.47512C11.963 3.84232 13.574 3.51294 15.2024 3.506C16.8309 3.49906 18.4446 3.81469 19.9505 4.43467C21.4563 5.05466 22.8245 5.96672 23.976 7.11824C25.1275 8.26975 26.0396 9.63791 26.6596 11.1438C27.2796 12.6496 27.5952 14.2633 27.5882 15.8918C27.5813 17.5203 27.2519 19.1312 26.6191 20.6318C25.9863 22.1323 25.0626 23.4926 23.9013 24.6342Z"
        fill="white"
      />
    </svg>
  );
}

export default IconInfo;
