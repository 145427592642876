import React, { useContext, useEffect, useState } from "react";

import "./UserList.scss";

import { apiProjectGetPlan } from "../../../../../api/projects";
import { apiUsersInProjectList } from "../../../../../api/users-in-project";
import { PopupContext } from "../../../../../context/popup/popupContext";
import Loading from "../../../../UI/Loading/Loading";
import UserAdd from "../UserAdd/UserAdd";
import UserItem from "../UserItem/UserItem";

const UserList = (props) => {
  const popupContextData = useContext(PopupContext);
  const [loading, setLoading] = useState(true);
  const [links, setLinks] = useState(undefined);
  const [plan, setPlan] = useState({
    planNull: null,
    planOne: null,
    planTwo: null,
  });

  const [userAdd, setUserAdd] = useState(false);

  const getLinks = async () => {
    setLoading(true);
    const link = await apiUsersInProjectList(props.projectId);

    if (link.status === "success") {
      setLinks(link.data);
    } else {
      setLinks(undefined);
    }

    popupContextData.setPreloader(false);
    setLoading(false);
  };

  const getPlan = async () => {
    try {
      setLoading(true);
      const { data } = await apiProjectGetPlan(props.projectId);

      if (data?.status === "success" && data?.plan?.length) {
        let copyPlan = { ...plan };

        data.plan.forEach((item) => {
          if (item.type === null) {
            copyPlan = { ...copyPlan, planNull: item.data };
          }

          if (item.type === 1) {
            copyPlan = { ...copyPlan, planOne: item.data };
          }

          if (item.type === 2) {
            copyPlan = { ...copyPlan, planTwo: item.data };
          }
        });

        setPlan(copyPlan);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLinks();
    getPlan();
     
  }, []);

  return (
    <div className="admin-company__project-item-content">
      <div className="admin-company__users">
        <div className="admin-company__users-title">ПОЛЬЗОВАТЕЛИ</div>

        <div className="admin-company__users-button-add" onClick={() => setUserAdd(true)} />

        {loading ? <Loading /> : null}

        {userAdd ? (
          <UserAdd
            companyId={props.companyId}
            projectId={props.projectId}
            close={() => setUserAdd(false)}
            getLinks={getLinks}
          />
        ) : null}

        {!loading && links !== undefined ? (
          <>
            <div className="admin-company__users-top-side">
              <div className="admin-company__users-top-side-cell admin-company__users-top-side-cell_280px">
                Пользователь:
              </div>
              <div className="admin-company__users-top-side-cell admin-company__users-top-side-cell_925px">
                Роль:
              </div>
              <div className="admin-company__users-top-side-cell">Статус:</div>
            </div>

            <div className="admin-company__users-content">
              {Object.keys(links).map((linkId, index) => {
                const link = links[linkId];

                return (
                  <UserItem
                    key={index}
                    id={link._id}
                    active={link.active}
                    userName={link.userId.name}
                    userLastName={link.userId.lastName}
                    userRole={link.userId.role.name}
                    projectId={props.projectId}
                    getLinks={getLinks}
                  />
                );
              })}
            </div>
          </>
        ) : null}
      </div>

      {/*<DesktopPlan title='План' plan={plan} isControl={true} onSavePlan={onSavePlan} />*/}
    </div>
  );
};

export default UserList;
