import React, { FC, MouseEvent, PropsWithChildren, useEffect, useRef } from "react";

import classes from "./Modal.module.scss";
import { IModalProps } from "./Modal.types";

const Modal: FC<PropsWithChildren<IModalProps>> = ({
  className,
  children,
  onClose,
  isOpen,
  title,
  withoutButton,
}) => {
  const modalRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Возвращаем скролл при размонтировании компонента
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const handleOnClose = () => {
    onClose?.(false);
    document.body.style.overflow = "hidden";
  };

  const handleOnClickOverlay = (event: MouseEvent<HTMLDivElement>) => {
    if (modalRef.current === event.target) {
      onClose?.(false);
    }
  };

  return (
    <div className={classes.modal} ref={modalRef} onClick={handleOnClickOverlay}>
      <div className={classes.modalWrap}>
        <div className={classes.modalPlate}>
          <div className={classes.topPanel}>
            {title && <p className={classes.modalTitle}>{title}</p>}
          </div>
          {withoutButton || (
            <button className={classes.modalClose} onClick={handleOnClose}>
              Закрыть
            </button>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
