import axios from "axios";
import { translit } from "gost-transliteration";
import React, { useContext, useEffect, useState } from "react";

import classes from "./Desktop.module.scss";

import { apiProjectGetPlan } from "../../api/projects";
import { AddCustomTile } from "../../components/Desktop/AddCustomTile/AddCustomTile";
import { CustomTilesBlock } from "../../components/Desktop/CustomTilesBlock/CustomTilesBlock";
import { DefaultTilesBlock } from "../../components/Desktop/DefaultTilesBlock/DefaultTilesBlock";
import { EditButton } from "../../components/Desktop/EditButton/EditButton";
import { SelectBlock } from "../../components/Desktop/SelectBlock/SelectBlock";
import Modal from "../../components/Modal/Modal";
import config from "../../config/config";
import { PopupContext } from "../../context/popup/popupContext";

const Desktop = () => {
  const popupContextData = useContext(PopupContext);

  const [plan, setPlan] = useState({
    planNull: null,
    planOne: null,
    planTwo: null,
  });
  const [isCreatedFolder] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [desktopData, setDesktopData] = useState({});
  const [selectData, setSelectData] = useState({});

  const token = localStorage.getItem("token");

  const getDirName = (type, name) => {
    switch (type) {
      case 1:
        return "docs";
      case 2:
        return "kpi";
      case 3:
        return "budget";
      case 4:
      default:
        return name;
    }
  };

  const getDesktopData = async (first, company, project) => {
    popupContextData.setPreloader(true);
    if (first) {
      const data = await axios.get(
        `${config.SERVER_BASE_URL}/desktop/?autoSelect=Y&token=${token}`,
      );
      setDesktopData(data.data);
    } else if (company) {
      const data = await axios.get(
        `${config.SERVER_BASE_URL}/desktop/?company=${company}&project=${project || ""}&autoSelect=${
          !project && "Y"
        }&token=${token}`,
      );
      setDesktopData(data.data);
    } else {
      alert("Не передана компания");
    }

    popupContextData.setPreloader(false);
  };

  const onChangeSelect = async (select, el) => {
    popupContextData.setPreloader(true);
    const id = el.target.value;

    if (select === "company") {
      await getDesktopData(false, id);
    } else {
      await getDesktopData(false, selectData.company, id);
    }
    popupContextData.setPreloader(false);
  };

  const onChangeTextInput = async (blockName, blockType, documents, description, name) => {
    popupContextData.setPreloader(true);
    for (const block of desktopData[blockName]) {
      if (block._id === blockType) {
        const req = { ...block, description, name, documents };

        req.token = token;

        if (req.documents.length > 0) {
          const formData = new FormData();
          req.documents.forEach((el, i) => {
            if (el.file.id) {
              const name = translit(el.file.name)
                .replace(/[^a-zA-Zа-яА-Я0-9-_/. ]+/g, "")
                .trim()
                .replace(/[ ]+/g, "-")
                .toLowerCase();

              req.documents[i].name = name;
              req.documents[i].file.path = `${getDirName(block.type, block.name)}/${name}`;
              formData.append("file", el.file.file);
            }
          });

          if (formData.getAll("file").length > 0) {
            await axios.post(
              `${config.SERVER_BASE_URL}/desktop/file/?project=${selectData.project}&company=${
                selectData.company
              }&dirName=${getDirName(block.type, block.name)}&token=${token}`,
              formData,
              {
                headers: { "Content-Type": "multipart/form-data" },
                data: formData,
              },
            );
          }
        }

        await axios.put(
          `${config.SERVER_BASE_URL}/desktop/block/${block._id}/?project=${selectData.project}&company=${selectData.company}`,
          req,
        );

        await getDesktopData(false, selectData.company, selectData.project);
      }
    }
    popupContextData.setPreloader(false);
  };

  const onCreateDir = async (data) => {
    popupContextData.setPreloader(true);

    const req = {
      name: data.name,
      description: data.description || null,
      documents: data.documents,
      token: token,
      project: selectData.project,
      company: selectData.company,
      desktopId: desktopData.desktopId,
    };

    if (data.documents.length > 0) {
      const formData = new FormData();
      req.documents.forEach((el, i) => {
        const name = translit(el.file.name)
          .replace(/[^a-zA-Zа-яА-Я0-9-_/. ]+/g, "")
          .trim()
          .replace(/[ ]+/g, "-")
          .toLowerCase();

        req.documents[i].name = name;
        req.documents[i].file.path = `${getDirName(4, data.name)}/${name}`;
        formData.append("file", el.file.file);
      });

      await axios.post(
        `${config.SERVER_BASE_URL}/desktop/file/?project=${selectData.project}&company=${
          selectData.company
        }&dirName=${getDirName(4, data.name)}&token=${token}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          data: formData,
        },
      );
    }

    await axios.post(
      `${config.SERVER_BASE_URL}/desktop/block?project=${selectData.project}&company=${selectData.company}`,
      req,
    );

    await getDesktopData(false, selectData.company, selectData.project);

    popupContextData.setPreloader(false);
    setIsModalOpen(false);
  };

  const deleteTile = async (id) => {
    popupContextData.setPreloader(true);
    await axios.delete(
      `${config.SERVER_BASE_URL}/desktop/block/${id}/?token=${token}&project=${selectData.project}&company=${selectData.company}`,
    );
    await getDesktopData(false, selectData.company, selectData.project);
    popupContextData.setPreloader(false);
  };

  const handleRemoveFile = async ({ path, fileId, blockId }) => {
    popupContextData.setPreloader(true);
    const token = localStorage.getItem("token");

    await axios.delete(`${config.SERVER_BASE_URL}/desktop/file`, {
      params: {
        token,
        id: fileId,
        path,
        blockId: blockId,
      },
    });

    await getDesktopData(false, selectData.company, selectData.project);
    popupContextData.setPreloader(false);
  };

  const fillSelectData = () => {
    if (Object.keys(desktopData).length) {
      const companiesCpy = desktopData.companies;
      const projectsCpy = desktopData.projects;

      const companies = companiesCpy.map((el) => {
        return {
          name: el.name,
          id: el._id,
        };
      });
      const projects = projectsCpy.map((el) => {
        return {
          name: el.name,
          id: el._id,
        };
      });
      setSelectData({
        companies: companies,
        projects: projects,
        project: desktopData.projectActive,
        company: desktopData.companyActive,
      });
    }
  };

  useEffect(() => {
    getDesktopData(true);
  }, []);

  useEffect(() => {
    fillSelectData();
  }, [desktopData]);

  const onTapUploadFiles = (id) => {};

  const getPlan = async () => {
    if (selectData.project) {
      try {
        const { data } = await apiProjectGetPlan(selectData.project);

        if (data?.status === "success" && data?.plan?.length) {
          let copyPlan = { ...plan };

          data.plan.forEach((item) => {
            if (item.type === null) {
              copyPlan = { ...copyPlan, planNull: item.data };
            }

            if (item.type === 1) {
              copyPlan = { ...copyPlan, planOne: item.data };
            }

            if (item.type === 2) {
              copyPlan = { ...copyPlan, planTwo: item.data };
            }
          });

          setPlan(copyPlan);
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    getPlan();
  }, [selectData.project]);

  const handleOnCancelCreateFolder = () => {
    setIsModalOpen(false);
  };

  const handleOnOpenModal = () => {
    setIsModalOpen(true);
  };

  return (
    <div className={`replic ${classes.Desktop}`}>
      <EditButton onClick={handleOnOpenModal} />

      <div className={classes.DesktopWrapper}>
        <h1 className={classes.Title}>Рабочий стол</h1>

        <SelectBlock items={selectData} onChange={onChangeSelect} />

        <DefaultTilesBlock
          tiles={desktopData.blocksSystem || []}
          onTapUploadFiles={onTapUploadFiles}
          onChange={onChangeTextInput}
          onRemoveFile={handleRemoveFile}
        />

        <CustomTilesBlock
          tiles={desktopData.blocksCustom || []}
          onChange={onChangeTextInput}
          onTapUploadFiles={onTapUploadFiles}
          deleteTile={deleteTile}
          onRemoveFile={handleRemoveFile}
        />
        <Modal onClose={setIsModalOpen} title="Создать папку" withoutButton isOpen={isModalOpen}>
          <AddCustomTile
            onCancelCreatedFolder={handleOnCancelCreateFolder}
            onSuccessCreatedFolder={onCreateDir}
          />
        </Modal>
      </div>
    </div>
  );
};

export default Desktop;
