import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";

import IconAllSelected from "./IconAllSelected";
import IconArrowDown from "./IconArrowDown";
import classes from "./ReplicaList.module.scss";
import "./ToggleSwitch.scss";

import config from "../../../../config/config";
import { PopupContext } from "../../../../context/popup/popupContext";
import IconNotification from "../../../../icons/IconNotification";
import IconTelegram from "../../../../icons/IconTelegram";
import PopupSaveReplica from "../PopupSaveReplica/PopupSaveReplica";
import PopupSubscribeTg from "../PopupSubscribeTg/PopupSubscribeTg";
import ReplicaItem from "../ReplicaItem/ReplicaItem";

const DATA_STATUS = [
  {
    id: "5f12dcfaa2585a4b908cb604",
    title: "Создана",
    code: "created",
  },

  {
    id: "5f12dd12a2585a4b908cb605",
    title: "Ожидает согласования",
    code: "waiting-agreement",
  },
  {
    id: "5f12dd1fa2585a4b908cb606",
    title: "На размещении",
    code: "for-posting",
  },
  {
    id: "5f12dd2aa2585a4b908cb607",
    title: "Отправлено на модерацию",
    code: "under-moderation",
  },
  {
    id: "5f12dce5a2585a4b908cb603",
    title: "Размещено",
    code: "posted",
  },

  {
    id: "5f12dd3ba2585a4b908cb608",
    title: "Отклонено",
    code: "refused",
  },
];

const ReplicaList = (props) => {
  const { showModal, onOpenModal, setPreloader } = useContext(PopupContext);

  const [items, setItems] = useState([]);
  const [isOpenedReplic, setIsOpenedReplic] = useState(
    JSON.parse(localStorage.getItem("isOpenedReplic")) === true,
  );

  const onChangeDate = (replicaId, newDate) => {
    const data = items.map((replica) => {
      if (replica._id === replicaId) {
        return { ...replica, statusChange: newDate };
      }
      return replica;
    });

    setItems(data);
  };

  const onSuccesUpdateRepica = (replicaId, newData) => {
    try {
      // console.log(`onSuccesUpdateRepica: ${JSON.stringify(items)}`)

      const currentReplica = items.find((replica) => replica._id === replicaId);
      if (currentReplica) {
        console.log(
          `onSuccesUpdateRepica current statuts: ${JSON.stringify(currentReplica?.status._id)}`,
        );
        console.log(`onSuccesUpdateRepica new status: ${JSON.stringify(newData.status)}`);

        // props.onChangeStatusReplica(newData.status, currentReplica.status._id);
      }

      console.log(
        `onSuccesUpdateRepica currentReplica 2: ${JSON.stringify(currentReplica?.status)}`,
      );

      const data = items.map((replica) => {
        if (replica._id === replicaId) {
          const currentReplica = DATA_STATUS.find((item) => item.id === newData.status);
          const result = {
            ...replica,
            status: {
              _id: newData.status,
              name: currentReplica.title,
              code: currentReplica.code,
            },
          };

          result.msg.comment = newData.lastClientComment;

          const dataResult = { ...result, statusChange: newData.statusChange };

          return { ...dataResult };
        }
        return replica;
      });

      console.log(`onSuccesUpdateRepica data`);

      setItems(data);

      props.updateReplicaList();
    } catch (error) {
      console.error(`$onSuccesUpdateRepica() error: ${error?.message}`);
    }
  };

  useEffect(() => {
    const list = [];

    Object.keys(props.replica).map((replicaId, index) => {
      const replica = props.replica[replicaId];
      list.push(replica);
    });

    setItems(list);
  }, [props.replica]);

  const openPopupDetail = (obj) => {
    props.onChangeSelectedReplicaForSavePopup(obj);
  };

  const onSubscribeByTelegram = async () => {
    if (props?.telegram?.showButton === "subscribe") {
      onOpenModal("subscribeTg");
      return;
    }

    if (props?.telegram?.showButton === "subscribe-confirm") {
      try {
        setPreloader(true);
        const token = localStorage.getItem("token");

        const { data } = await axios.post(`${config.SERVER_BASE_URL}/telegram/subscribe/`, {
          token,
          project: props.projectId,
        });

        if (data.status === "fail") {
          setPreloader(false);
          alert(data?.errorText);
        } else {
          props.updateReplicaList();
        }
      } catch (error) {
        setPreloader(false);
        console.log(error);
      }

      return;
    }

    return;
  };

  const onSubscribeByChangeStatus = async () => {
    try {
      setPreloader(true);
      const token = localStorage.getItem("token");

      const { data } = await axios.post(`${config.SERVER_BASE_URL}/telegram/send-notice/`, {
        token,
        project: props.projectId,
      });

      if (data.status === "fail") {
        setPreloader(false);
        alert(data?.errorText);
      } else {
        props.updateReplicaList();
      }
    } catch (error) {
      setPreloader(false);
      console.log(error);
    }
  };

  const onChangeUrlInReplica = async (id, newUrl) => {
    try {
      setPreloader(true);
      const token = localStorage.getItem("token");

      const { data } = await axios.put(`${config.SERVER_BASE_URL}/replica/url/${id}`, {
        token,
        url: newUrl,
      });

      if (data.status === "fail") {
        setPreloader(false);
        alert(data?.errorText);
      } else {
        props.updateReplicaList();
      }
    } catch (error) {
      setPreloader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    props.setIsReplicaChange(false);
  }, [props, props.replica]);

  const onToggleOpenedReplic = () => {
    localStorage.setItem("isOpenedReplic", JSON.stringify(!isOpenedReplic));
    setIsOpenedReplic(!isOpenedReplic);
  };

  return (
    <div className={classes.ReplicaList}>
      <div className={classes.HeaderBar}>
        <div className={classes.BarCell}>
          <div
            className={classes.AddButton}
            data-active={props.isAllSelected ? "Y" : null}
            onClick={props.addCheckboxAllReplica}>
            <IconAllSelected />
          </div>
        </div>

        <div className={classes.BarCell} data-width="126">
          <span className={classes.DateTitle}>Дата</span>

          <div
            className={classes.SortBtnUp}
            onClick={() => props.changeSort("date", "asc")}
            data-active={props.sortName === "date" && props.sortDirection === "asc" ? "Y" : null}>
            <IconArrowDown />
          </div>

          <div
            className={classes.SortBtnDown}
            onClick={() => props.changeSort("date", "desc")}
            data-active={props.sortName === "date" && props.sortDirection === "desc" ? "Y" : null}>
            <IconArrowDown />
          </div>
        </div>

        <div className={classes.BarCell} data-width="201">
          Площадка
        </div>

        <div className={classes.BarCell} data-width="782">
          Последняя реплика
        </div>

        <div className={`${classes.BarCell} ${classes.Status}`} data-width="229">
          Статус
        </div>

        <div className={classes.BarCell} data-width="782">
          <label className="toggle-switch">
            <input type="checkbox" checked={isOpenedReplic} onChange={onToggleOpenedReplic} />
            <span className="switch" />
          </label>

          <p style={{ marginLeft: 12, cursor: "pointer" }} onClick={onToggleOpenedReplic}>
            Развернуть реплики
          </p>
        </div>

        {props.accessLevel !== 2 && props?.telegram?.showButton === "sendMessage" && (
          <div className={classes.NotificationStatus} onClick={onSubscribeByChangeStatus}>
            <div
              className={classes.NotificationStatusCount}
              data-active={props?.telegram?.counter ? "Y" : "N"}>
              <IconNotification />
            </div>
            Уведомить о смене статуса
          </div>
        )}

        {props.accessLevel === 2 &&
          (props?.telegram?.showButton === "subscribe" ||
            props?.telegram?.showButton === "subscribe-confirm") && (
            <div
              className={`${classes.NotificationStatus} ${classes.SubscribeNotification}`}
              onClick={() => onSubscribeByTelegram()}>
              <div className={classes.NotificationStatusCount}>
                <IconTelegram />
              </div>
              Подписаться на уведомления о смене статуса реплик
            </div>
          )}
      </div>

      {items &&
        items.map((item, index) => {
          return (
            <>
              <ReplicaItem
                onRejectReplica={onSuccesUpdateRepica}
                onSuccesUpdateRepica={onSuccesUpdateRepica}
                isOpenedReplic={isOpenedReplic}
                key={item._id.toString()}
                item={item}
                date={item.date}
                onChangeDate={onChangeDate}
                id={item._id.toString()}
                onTapRemove={props.onTapRemove}
                onTapReject={props.onTapReject}
                onTapApprove={props.onTapApprove}
                btnDisabled={props.btnDisabled}
                onlyView={item.onlyView}
                company={item.company}
                noteSystem={item.noteSystem}
                project={item.project}
                category={item.category}
                platform={item.platform}
                url={item.url}
                msg={item.msg.msg}
                textSomeone={item.msg.textSomeone}
                comment={item.msg.comment}
                status={item.status}
                checked={item.checked}
                screenshot={item.screenshot}
                agentName={item.agentName}
                fields={item.fields ? item.fields : []}
                type={item.type}
                accessLevel={props.accessLevel}
                replicaSelect={(event, id, status) => props.replicaSelect(event, id, status)}
                updateReplicaList={props.updateReplicaList}
                replicaChildrenSelect={props.replicaChildrenSelect}
                replicaChildrenUpdate={props.replicaChildrenUpdate}
                replicaChildrenUpdateStart={props.replicaChildrenUpdateStart}
                clearReplicaChildrenSelect={props.clearReplicaChildrenSelect}
                blackout={props.blackout}
                setBlackOut={props.setBlackout}
                replicaChildrenOpenForBlackout={props.replicaChildrenOpenForBlackout}
                setReplicaChildrenOpenForBlackout={props.setReplicaChildrenOpenForBlackout}
                replicaType={props.replicaType}
                arraySelectedItems={props.arraySelectedItems}
                statusChange={item.statusChange}
                openPopupDetail={openPopupDetail}
                replicaChange={props.replicaChange}
                onApproveReplicaById={props.onApproveReplicaById}
                dateEdit={item.dateEdit}
                onChangeUrlInReplica={onChangeUrlInReplica}
              />
            </>
          );
        })}

      <CSSTransition
        in={showModal === "subscribeTg"}
        timeout={200}
        mountOnEnter={true}
        unmountOnExit={true}>
        {(state) => (
          <PopupSubscribeTg
            className={state}
            projectId={props.projectId}
            updateReplicaList={props.updateReplicaList}
          />
        )}
      </CSSTransition>

      <CSSTransition
        in={showModal === "saveReplica"}
        timeout={200}
        mountOnEnter={true}
        unmountOnExit={true}>
        {(state) => (
          <PopupSaveReplica
            className={state}
            company={props?.selectedReplicaForSavePopup?.company}
            project={props?.selectedReplicaForSavePopup?.project}
            date={props?.selectedReplicaForSavePopup?.date}
            platform={props?.selectedReplicaForSavePopup?.platform}
            onApproveReplicaById={() =>
              props.onApproveReplicaById(props.selectedReplicaForSavePopup._id)
            }
          />
        )}
      </CSSTransition>
    </div>
  );
};

export default ReplicaList;
