import axios from "axios";

import {
  ISearchingQueryResults,
  ISearchingQueryResultsParams,
} from "./searching-query-results.types";

import config from "../../config/config";

export const fetchSearchingQueryResultsById = async (
  searchingQueryId: string,
): Promise<ISearchingQueryResults[] | null> => {
  try {
    const params: ISearchingQueryResultsParams = {
      searchingQueryId,
    };

    const response = await axios.get(
      `${config.SERVER_BASE_URL}/searching-query-results/fetch-searching-query-results-by-id`,
      { params },
    );

    return response?.data?.data;
  } catch (error) {
    return null;
  }
};
