import React from "react";

import classes from "./ReplicaTabs.module.scss";

import IconAllSelected from "../ReplicaList/IconAllSelected";
import IconArrowDown from "../ReplicaList/IconArrowDown";

export default function ReplicaTabs(props) {
  return (
    <>
      {window.innerWidth > 767 ? (
        <div className={classes.ReplicaTabs}>
          <div
            className={classes.Tab}
            data-active={!props.type ? "Y" : null}
            onClick={() => {
              if (props.type !== null) {
                props.onClick(null);
              }
            }}>
            Реплики
          </div>

          <div
            className={`${classes.Tab} ${classes.CenterTab}`}
            data-active={props.type === 1 ? "Y" : null}
            onClick={() => {
              if (props.type !== 1) {
                props.onClick(1);
              }
            }}>
            Инициирование
          </div>

          <div
            className={classes.Tab}
            data-active={props.type === 2 ? "Y" : null}
            onClick={() => {
              if (props.type !== 2) {
                props.onClick(2);
              }
            }}>
            Реагирование
          </div>
        </div>
      ) : (
        <div className={classes.ReplicaTabs}>
          <div
            className={classes.AddButton}
            data-active={props.isAllSelected ? "Y" : null}
            onClick={props.onClickPlus}>
            <IconAllSelected />
          </div>

          <div className={classes.SortBtns}>
            <div
              className={classes.SortBtnUp}
              onClick={() => props.changeSort("date", "asc")}
              data-active={props.sortName === "date" && props.sortDirection === "asc" ? "Y" : null}>
              <IconArrowDown />
            </div>

            <div
              className={classes.SortBtnDown}
              onClick={() => props.changeSort("date", "desc")}
              data-active={
                props.sortName === "date" && props.sortDirection === "desc" ? "Y" : null
              }>
              <IconArrowDown />
            </div>
          </div>

          <div
            className={`${classes.Tab} ${classes.All}`}
            data-active={!props.type ? "Y" : null}
            onClick={() => {
              if (props.type !== null) {
                props.onClick(null);
              }
            }}>
            <div className={classes.Icon} />
          </div>

          <div
            className={`${classes.Tab} ${classes.Inition}`}
            data-active={props.type === 1 ? "Y" : null}
            onClick={() => {
              if (props.type !== 1) {
                props.onClick(1);
              }
            }}>
            <div className={classes.Icon} />
          </div>

          <div
            className={`${classes.Tab} ${classes.Reaction}`}
            data-active={props.type === 2 ? "Y" : null}
            onClick={() => {
              if (props.type !== 2) {
                props.onClick(2);
              }
            }}>
            <div className={classes.Icon} />
          </div>
        </div>
      )}
    </>
  );
}
