import React from "react";

function IconDownload({ className }) {
  return (
    <svg
      className={className}
      width={29}
      height={28}
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.6133 10.3944C28.6133 6.8615 25.6954 3.97091 22.1291 3.97091C21.8933 3.97091 21.6575 3.97091 21.4217 4.00011C19.8891 1.51829 17.1775 0 14.1712 0C10.5164 0 7.33328 2.27743 6.15433 5.63519C3.08907 5.66438 0.613281 8.1754 0.613281 11.212C0.613281 14.307 3.14802 16.818 6.27223 16.818H6.36065C6.18381 17.4603 6.09539 18.1611 6.09539 18.8618C6.09539 23.5043 9.89749 27.3 14.6133 27.3C19.3291 27.3 23.1312 23.5335 23.1312 18.8618C23.1312 18.1319 23.0428 17.4311 22.8659 16.7888C26.0785 16.4092 28.6133 13.6938 28.6133 10.3944ZM14.6133 25.4313C10.9585 25.4313 7.9817 22.4824 7.9817 18.8618C7.9817 15.2413 10.9585 12.2923 14.6133 12.2923C18.268 12.2923 21.2449 15.2413 21.2449 18.8618C21.2449 22.4824 18.268 25.4313 14.6133 25.4313ZM14.6133 10.4236C17.8849 10.4236 20.7143 12.2631 22.1291 14.9493C24.6638 14.9493 26.727 12.9055 26.727 10.3652C26.727 7.85422 24.6638 5.81037 22.1291 5.81037C20.7143 5.81037 19.4175 6.42353 18.5333 7.50385C18.2091 7.91262 17.6196 7.97102 17.207 7.64984C16.7943 7.32866 16.7354 6.74471 17.0596 6.33594C17.708 5.5184 18.5333 4.90524 19.4764 4.49647C18.268 2.83219 16.2933 1.83947 14.1712 1.83947C11.1354 1.83947 8.51223 3.91251 7.77539 6.8615C7.65749 7.32866 7.21539 7.62064 6.74381 7.56225L6.62591 7.53305C6.56697 7.53305 6.50802 7.52575 6.44907 7.51845C6.39012 7.51115 6.33118 7.50385 6.27223 7.50385C4.1796 7.50385 2.4996 9.13893 2.4996 11.212C2.4996 13.285 4.20907 14.9493 6.27223 14.9493H7.09749C8.51223 12.2631 11.3417 10.4236 14.6133 10.4236Z"
        fill="white"
      />
      <path
        d="M17.7782 18.7243C17.6292 18.573 17.4207 18.573 17.2718 18.7243L15.9314 20.0859V15.0631C15.9314 14.8513 15.7824 14.7 15.5739 14.7H14.3229C14.1143 14.7 13.9654 14.8513 13.9654 15.0631V20.0859L12.625 18.7243C12.476 18.573 12.2675 18.573 12.1186 18.7243L11.225 19.632C11.076 19.7833 11.076 19.9951 11.225 20.1464L14.7101 23.6865C14.859 23.8378 15.0675 23.8378 15.2165 23.6865L18.7016 20.1464C18.8505 19.9951 18.8505 19.7833 18.7016 19.632L17.7782 18.7243Z"
        fill="white"
      />
    </svg>
  );
}

export default IconDownload;
