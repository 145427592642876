import React, { useState } from "react";

import { PopupContext } from "./popupContext";

export const PopupState = ({ children }) => {
  const [preloader, setPreloader] = useState(false);
  // TYPES FORM: 'saveReplica' | 'subscribeTg'
  const [showModal, setShowModal] = useState(null);

  const [popupNotice, setPopupNotice] = useState({
    state: false,
    title: "",
    text: "",
    list: [],
  });

  const onOpenModal = (nameForm) => {
    const body = document.querySelector("body");
    body.classList.add("no-scroll");

    setShowModal(nameForm);
  };

  const onCloseModal = () => {
    const body = document.querySelector("body");
    body.classList.remove("no-scroll");

    setShowModal(null);
  };

  return (
    <PopupContext.Provider
      value={{
        popupNotice,
        setPopupNotice,
        preloader,
        setPreloader,
        showModal,
        onOpenModal,
        onCloseModal,
      }}>
      {children}
    </PopupContext.Provider>
  );
};
