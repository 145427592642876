import React from "react";

import IconLeft from "./IconLeft";
import IconRight from "./IconRight";
import classes from "./Pagination.module.scss";

export default function Pagination({
  totalPages,
  currentPage,
  separator,
  changeCurrentPage,
  href,
  params,
  pageQuery = "page",
  onClick,
}) {
  const showAfterFirst = currentPage < 5;
  const showBeforeLast = currentPage > totalPages - 4;

  const createArrayButtons = (quantity, startNumber) => {
    const newArr = [...Array.from({ length: quantity })];
    return newArr.map((n, i) => startNumber + i);
  };

  const buttonsToRender =
    totalPages > 8
      ? [
          1,
          showAfterFirst && createArrayButtons(4, 2),
          !showAfterFirst && separator,
          !(showAfterFirst || showBeforeLast) && createArrayButtons(3, currentPage - 1),
          !showBeforeLast && separator,
          showBeforeLast && createArrayButtons(4, totalPages - 4),
          totalPages,
        ]
          .flat()
          .filter(Boolean)
      : createArrayButtons(totalPages, 1);

  const arrParams = [];

  if (params) {
    const entries = Object.entries(params).filter(([key, value]) => Boolean(value));
    const newParams = Object.fromEntries(entries);

    for (const item of Object.keys(newParams)) {
      arrParams.push(`${item}=${newParams[item]}`);
    }
  }

  const changePage = (e, page) => {
    e.preventDefault();
    onClick(page);
  };

  return (
    <div className={classes.Wrapper} id="pagination">
      <a
        href={`/${href}/?${pageQuery}=${1}${arrParams.length ? `&${arrParams.join("&")}` : ""}`}
        onClick={(e) => changePage(e, 1)}>
        <button className={classes.FirstPage} data-page={1} disabled={currentPage === 1}>
          <IconLeft />
          <IconLeft />
        </button>
      </a>

      <a
        href={`/${href}/?${pageQuery}=${currentPage - 1}${arrParams.length ? `&${arrParams.join("&")}` : ""}`}
        onClick={(e) => changePage(e, currentPage - 1)}>
        <button className={classes.GoBack} data-page={currentPage - 1} disabled={currentPage === 1}>
          <IconLeft />
        </button>
      </a>

      <div className={classes.NumbersPage}>
        {buttonsToRender.map((number, index) =>
          number === separator ? (
            <div className={classes.Separator} key={index}>
              {separator}
            </div>
          ) : (
            <a
              className={number === currentPage ? classes.Active : ""}
              href={`/${href}/?${pageQuery}=${number}${arrParams.length ? `&${arrParams.join("&")}` : ""}`}
              key={index}
              onClick={(e) => changePage(e, number)}>
              <button
                style={{ width: "fit-content", height: "22px" }}
                key={index}
                data-page={number}
                className={number === currentPage ? classes.Active : ""}>
                {number}
              </button>
            </a>
          ),
        )}
      </div>

      <a
        href={`/${href}/?${pageQuery}=${currentPage + 1}${arrParams.length ? `&${arrParams.join("&")}` : ""}`}
        onClick={(e) => changePage(e, currentPage + 1)}>
        <button
          className={classes.GoNext}
          data-page={currentPage + 1}
          disabled={currentPage === totalPages}>
          <IconRight />
        </button>
      </a>

      <a
        href={`/${href}/?${pageQuery}=${totalPages}${arrParams.length ? `&${arrParams.join("&")}` : ""}`}
        onClick={(e) => changePage(e, totalPages)}>
        <button
          className={classes.LastPage}
          data-page={totalPages}
          disabled={currentPage === totalPages}>
          <IconRight />
          <IconRight />
        </button>
      </a>
    </div>
  );
}
