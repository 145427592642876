import React from "react";

import "./CompanyItem.scss";

import UserList from "../UserList/UserList";

const CompanyItem = (props) => {
  const cls = ["admin-company__company-item", "admin-company__company-item_clients-mode"];

  if (props.active) {
    cls.push("admin-company__company-item_active");
  } else {
    cls.push("admin-company__company-item_not-active");
  }

  return (
    <div className={cls.join(" ")}>
      <div className="admin-company__company-item-bar" onClick={() => props.onToogleById(props.id)}>
        <div className="admin-company__company-item-name">{props.name}</div>
      </div>

      {props.openCompany ? <UserList companyId={props.id} filter={props.filter} /> : null}
    </div>
  );
};

export default CompanyItem;
