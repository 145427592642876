import React from "react";

import "./ResetButton.styles.scss";
import { IResetButtonProps } from "./ResetButton.types";

export const ResetButton = ({ onClick }: IResetButtonProps) => {
  return (
    <button className="search-history-reset-button" onClick={onClick}>
      Сбросить фильтры
    </button>
  );
};
