import React from "react";

function IconArrowDown() {
  return (
    <svg width={10} height={12} viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.62916 0.24939V9.20464L1.72032 6.2958L0.765625 7.25049L5.26562 11.7505L9.76562 7.25049L8.81093 6.2958L5.90209 9.20464V0.24939H4.62916Z"
        fill="#E00C3C"
      />
    </svg>
  );
}

export default IconArrowDown;
