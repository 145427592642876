import React, { useContext, useState } from "react";
import Select from "react-select";

import IconChangeArrowDown from "./IconChangeArrowDown";
import classes from "./TabScreenSearch.module.scss";

import { AnaliseContext } from "../../../../context/analise";
import { customStyles7, customStyles8 } from "../../../../utils/searchSelectStyle";
import UrlDropDown from "../../UI/UrlDropDown/UrlDropDown";
import IconDefault from "../../icons/IconDefault";
import IconNegative from "../../icons/IconNegative";
import IconNeutral from "../../icons/IconNeutral";
import IconPending from "../../icons/IconPending";
import IconPositive from "../../icons/IconPositive";

const optionsTonality = [
  {
    value: "positive",
    label: (
      <div className={classes.SelectorOption}>
        <IconPositive />
      </div>
    ),
  },
  {
    value: "negative",
    label: (
      <div className={classes.SelectorOption}>
        <IconNegative />
      </div>
    ),
  },
  {
    value: "neutral",
    label: (
      <div className={classes.SelectorOption}>
        <IconNeutral />
      </div>
    ),
  },
  {
    value: "pending",
    label: (
      <div className={classes.SelectorOption}>
        <IconPending />
      </div>
    ),
  },
];

function TabScreenSearch() {
  const { state, actions } = useContext(AnaliseContext);

  const [selectedRow, setSelectedRow] = useState(null);

  const onChangeSelectedRow = (index) => {
    setSelectedRow(index);
  };

  return (
    <div className={classes.Wrap}>
      <div className={classes.TableHeader}>
        <div className={classes.TableCol} data-type="position">
          Позиция
        </div>

        <div className={classes.TableCol} data-type="change">
          Изменение
        </div>

        <div className={classes.TableCol} data-type="tonality">
          Тональность
        </div>

        <div className={classes.TableCol} data-type="url">
          URL
        </div>

        <div className={classes.TableCol} data-type="info">
          Сниппет
        </div>

        <div className={classes.TableCol} data-type="type">
          Тип площадки
        </div>
      </div>

      <div className={classes.TableList}>
        {state.contentScreenSearch.length > 0 &&
          state.contentScreenSearch?.map((item, index) => (
            <div className={classes.TableRow} key={index} data-type={item.tonality}>
              <div className={classes.TableCol} data-type="position">
                <span className={classes.RowPosition}>{index + 1}</span>
              </div>

              <div className={classes.TableCol} data-type="change">
                <span
                  className={classes.RowChange}
                  data-type={item?.changeType}
                  data-position={
                    item?.positionOld && item?.positionOld > item?.position ? "up" : "down"
                  }>
                  {(item?.changeType === "positive" || item?.changeType === "negative") && (
                    <IconChangeArrowDown />
                  )}

                  {item?.change}
                </span>
              </div>

              <div className={classes.TableCol} data-type="tonality">
                <Select
                  options={optionsTonality}
                  isSearchable={false}
                  menuPlacement={index === state.contentScreenSearch?.length - 1 ? "top" : "bottom"}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "inherit",
                      primary: "inherit",
                    },
                  })}
                  styles={customStyles7}
                  value={optionsTonality.find((option) => option.value === item.tonality)}
                  onChange={({ value }) => {
                    actions.onChangeTonality(item.id, value);
                  }}
                  placeholder={
                    <div className={classes.SelectorOption}>
                      <IconDefault />
                    </div>
                  }
                />
              </div>

              <div className={classes.TableCol} data-type="url">
                <span className={classes.RowUrl} onClick={() => onChangeSelectedRow(index)}>
                  {item?.url}
                </span>

                <UrlDropDown
                  isOpen={index === selectedRow}
                  href={item?.urlFull}
                  onClose={() => setSelectedRow(null)}
                />
              </div>

              <div className={classes.TableCol} data-type="info">
                <span className={classes.RowInfoTitle}>{item?.info?.title}</span>
                <p className={classes.RowInfoText}>{item?.info?.text}</p>

                <div className={classes.RowInfoRating}>
                  Рейтинг:
                  <span>{item?.info?.rating}</span>
                </div>
              </div>

              <div className={classes.TableCol} data-type="type">
                <Select
                  options={state.categories}
                  isSearchable={false}
                  menuPlacement={index === state.contentScreenSearch?.length - 1 ? "top" : "bottom"}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "inherit",
                      primary: "inherit",
                    },
                  })}
                  styles={customStyles8}
                  value={state.categories.find((option) => option.value === item.category?.id)}
                  onChange={({ value }) => {
                    actions.onChangeCanegory(item.id, value);
                  }}
                  placeholder={"Нет данных"}
                />
              </div>
            </div>
          ))}

        {!state.contentScreenSearch.length && (
          <div className={classes.EmptyText}>
            Выберите поисковый запрос для просмотра результатов поисковой выдачи
          </div>
        )}
      </div>
    </div>
  );
}

export default TabScreenSearch;
