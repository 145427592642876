import React from "react";

function IconDots({ className }) {
  return (
    <svg
      className={className}
      width={3}
      height={14}
      viewBox="0 0 3 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="1.5" cy="1.59998" r="1.5" fill="#BECBDC" />
      <circle cx="1.5" cy={7} r="1.5" fill="#BECBDC" />
      <circle cx="1.5" cy="12.4" r="1.5" fill="#BECBDC" />
    </svg>
  );
}

export default IconDots;
