import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { AuthState } from "./context/auth/authState";
import { PopupState } from "./context/popup/popupState";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store";

const queryClient = new QueryClient();

const app = (
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <Provider store={store}>
        <AuthState>
          <PopupState>
            <App />
          </PopupState>
        </AuthState>
      </Provider>
    </BrowserRouter>
  </QueryClientProvider>
);

ReactDOM.render(app, document.getElementById("root"));

serviceWorker.unregister();
