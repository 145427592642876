import cn from "classnames";
import React, { useContext } from "react";

import classes from "./ModalQuery.module.scss";

import { AnaliseContext } from "../../../../../context/analise";
import MobailModal from "../../../UI/MobailModal/MobailModal";
import IconQuery from "../../../icons/IconQuery";

function ModalQuery({ controlModal }) {
  const { state, actions } = useContext(AnaliseContext);

  return (
    <MobailModal
      title="Поисковый запрос"
      icon={<IconQuery />}
      controlModal={controlModal}
      isIcon
      isCloseArrow>
      <div className={classes.Wrap}>
        <ul className={classes.List}>
          {state.queries.length > 0 ? (
            state.queries?.map((item, index) => (
              <li
                className={cn(classes.Item, { [classes.Active]: item.id === state.filter?.query })}
                key={index}
                onClick={() => {
                  actions.onChangeFilter("query", item.id);
                  controlModal.onCloseModal();
                }}>
                <p>{item.name}</p>
                <span>{item.frequency}</span>
              </li>
            ))
          ) : (
            <div className={classes.Empty}>Список пуст</div>
          )}
        </ul>
      </div>
    </MobailModal>
  );
}

export default ModalQuery;
