import React from "react";

import mediaListClasses from "./ListMedia.module.scss";

import config from "../../../config/config";
import classes from "../Tile/Tile.module.scss";

const ListMedia = ({ documents, onRemoveFile }) => {
  return (
    <div className={classes.listWrapper}>
      {documents.map((doc) => {
        let url = doc.file.url;
        url = url ? config.SERVER_BASE_URL + "/" + url : "";

        return (
          <div key={doc.file?.name} className={mediaListClasses.mediaFile}>
            <a
              href={url}
              className={mediaListClasses.fileLink}
              target="_blank"
              rel="noopener noreferrer">
              {doc.file?.name}
            </a>
            <span
              className={classes.iconRemoveFile}
              onClick={() =>
                onRemoveFile({
                  path: doc.file.url,
                  fileId: doc.file._id,
                })
              }
            />
          </div>
        );
      })}
    </div>
  );
};

export default ListMedia;
