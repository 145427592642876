export const mockData = {
  tabs: [
    {
      code: "search",
      name: "Поисковая выдача",
    },
    {
      code: "tonality",
      name: "Анализ тональности",
    },
    {
      code: "rating",
      name: "Анализ рейтинга площадок",
    },
  ],
};
