import React from "react";

import classes from "./ConfimationAction.module.scss";
import FinishConfirmationAction from "./FinishConfirmationAction/FinishConfirmationAction";
import InsideConfirmationAction from "./InsideConfirmationAction/InsideConfirmationAction";

export default function ConfirmationAction(props) {
  return (
    <div className={classes.ConfirmationAction} data-margin={props.confirm ? null : "Y"}>
      {props.confirm ? (
        <FinishConfirmationAction
          text={props.textFinish}
          num={props.num}
          dataColorTextArea={props.buttonColor}
        />
      ) : (
        <InsideConfirmationAction
          text={props.text}
          subtext={props.subtext}
          dataSmallTextArea={props.dataSmallTextArea}
          dataColorTextArea={props.dataColorTextArea}
          buttonText={props.buttonText}
          buttonColor={props.buttonColor}
          clickFinish={props.clickFinish}
          onChangeMsg={props.onChangeMsg}
          msg={props.msg}
        />
      )}
    </div>
  );
}
