import React, { useContext } from "react";

import classes from "./ModalFilter.module.scss";

import { AnaliseContext } from "../../../../../context/analise";
import MobailModal from "../../../UI/MobailModal/MobailModal";
import IconChevronDown from "../../../icons/IconChevronDown";
import IconGeo from "../../../icons/IconGeo";
import IconGoogle from "../../../icons/IconGoogle";
import IconSearchSelect from "../../../icons/IconSearchSelect";
import IconYandex from "../../../icons/IconYandex";

const optionDeepLevel = {
  top10: "10",
  top20: "20",
  top30: "30",
};

function ModalFilter({ controlModal, controlModalChildren }) {
  const { state, actions } = useContext(AnaliseContext);
  const { filter } = state;
  const { company, project, searchSystem, region } = filter;

  const isDisabledBtnCancel = company || project || searchSystem || region;

  return (
    <MobailModal title="Фильтр" controlModal={controlModal} isIcon isCloseArrow>
      <div className={classes.Wrap}>
        {state.accessLevel !== 2 && (
          <button
            className={classes.Btn}
            onClick={() => controlModalChildren.onOpenModal("company")}>
            <p>{state.filter.company?.label || <span>Компания</span>}</p>
            <IconChevronDown className={classes.IconChevronDown} />
          </button>
        )}

        <button className={classes.Btn} onClick={() => controlModalChildren.onOpenModal("project")}>
          <p>{state.filter.project?.label || <span>Проект</span>}</p>
          <IconChevronDown className={classes.IconChevronDown} />
        </button>

        <button className={classes.Btn} onClick={() => controlModalChildren.onOpenModal("size")}>
          <p>ТОП: {optionDeepLevel[state.filter.deepLevel]}</p>
          <IconChevronDown className={classes.IconChevronDown} />
        </button>

        <button className={classes.Btn} onClick={() => controlModalChildren.onOpenModal("system")}>
          <div className={classes.Icon}>
            {!state.filter?.searchSystem && <IconSearchSelect />}
            {state.filter?.searchSystem === "google" && <IconGoogle />}
            {state.filter?.searchSystem === "yandex" && <IconYandex />}
          </div>

          {!state.filter?.searchSystem && <span>Поиск. система</span>}

          <p>
            {state.filter?.searchSystem === "google" && "Google"}
            {state.filter?.searchSystem === "yandex" && "Яндекс"}
            {state.filter?.searchSystem === "all" && "Все"}
          </p>

          <IconChevronDown className={classes.IconChevronDown} />
        </button>

        <button className={classes.Btn} onClick={() => controlModalChildren.onOpenModal("region")}>
          <div className={classes.Icon}>
            <IconGeo />
          </div>

          <p>{state.filter.region || <span>Регион</span>}</p>
          <IconChevronDown className={classes.IconChevronDown} />
        </button>

        <button
          className={classes.BtnCancel}
          disabled={!isDisabledBtnCancel}
          onClick={actions.onCancelFilter}>
          Сбросить настройки
        </button>
      </div>
    </MobailModal>
  );
}

export default ModalFilter;
