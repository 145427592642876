import React, { useEffect, useState } from "react";

import classes from "./DefaultTilesBlock.module.scss";

import Tile from "../Tile/Tile";

export const DefaultTilesBlock = ({ onTapUploadFiles, onChange, tiles, onRemoveFile }) => {
  const [tileData, setTileData] = useState([]);

  useEffect(() => {
    if (tiles?.length) {
      setTileData(
        tiles.map((tile) => ({
          id: tile._id,
          name: tile.name,
          documents: tile.documents,
          description: tile.description,
          type: tile.type === 1 ? "documents" : "text",
        })),
      );
    }
  }, [tiles]);

  return (
    <div className={classes.DefaultTiles}>
      {tileData &&
        tileData.length > 0 &&
        tileData.map((el) => {
          return (
            <Tile
              key={el.id}
              onTapUploadFiles={() => onTapUploadFiles(el.id)}
              onChange={(tileType, _, documents, description) =>
                onChange(tileType, el.id, documents, description)
              }
              type={el.type}
              data={el}
              onRemoveFile={onRemoveFile}
            />
          );
        })}
    </div>
  );
};
