import cn from "classnames";
import React, { useEffect, useState } from "react";
import Select from "react-select";

export default function SelectWithCounter(props) {
  const [options, setOptions] = useState([]);
  const [select, setSelect] = useState({});

  const cutMax = (string, length) => {
    return string.length > length ? string.substring(0, length) + "..." : string;
  };

  useEffect(() => {
    const optionsData = [];

    const defaultActive = {
      value: false,
      label: false,
    };

    if (props.optionDefault) {
      optionsData.push({
        value: "",
        label: props.optionDefault,
        count: "Реплики",
        disabled: false,
      });
    }

    if (props.options && props.options.length) {
      for (const option of props.options) {
        optionsData.push({
          value: option.id,
          label: option.name,
          count: option.count ? option.count : "0",
          disabled: !(option.count && parseInt(option.count) > 0),
        });
      }
    }

    if (props.options && !props.options.length && Object.keys(props.options).length) {
      Object.keys(props.options).map((id) => {
        return optionsData.push({
          value: props.options[id].id,
          label: props.options[id].name,
          count: props.options[id].count ? props.options[id].count : "0",
          disabled: !(props.options[id].count && parseInt(props.options[id].count) > 0),
        });
      });
    }

    if (!props.value && optionsData.length) {
      defaultActive.value = optionsData[0].value;
      defaultActive.label = cutMax(optionsData[0].label, 30);
      defaultActive.count = optionsData[0].count ? optionsData[0].count : "0";
      defaultActive.disabled = !(optionsData[0].count && parseInt(optionsData[0].count) > 0);
    } else if (props.value && optionsData.length) {
      optionsData.map((data) => {
        if (data.value === props.value) {
          defaultActive.value = data.value;
          defaultActive.label = cutMax(data.label, 30);
          defaultActive.count = data.count ? data.count : "0";
          defaultActive.disabled = !(data.count && parseInt(data.count) > 0);
        }

        return true;
      });
    }

    if (!defaultActive.value && !defaultActive.label && props.optionDefault) {
      defaultActive.value = "";
      defaultActive.label = cutMax(props.optionDefault, 30);
      defaultActive.count = "0";
      defaultActive.disabled = true;
    }

    setOptions(optionsData);
    setSelect(defaultActive);
  }, [props, props.filter, props.options]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#ffffff" : "#94a2b6",
      padding: "0 30px 0 30px",
      // backgroundColor: state.isSelected ? '#e00c3c' : state.isFocused ? 'rgba(224,12,60,0.37)' : 'white',
      transition: "all .2s ease",
      cursor: "pointer",
      textAlign: "left",
      height: 53,
      display: "flex",
      alignItems: "center",
      fontSize: "13px",
      lineHeight: "20px",
      position: "relative",

      "&:hover": {
        color: "#ffffff",
        backgroundColor: "#e00c3c",
      },

      "&:after": {
        position: "absolute",
        content: '""',
        top: "0px",
        left: "30px",
        right: "30px",
        height: "1px",
        width: "auto",
        transition: "all .2s ease",
        backgroundColor: state.isSelected ? "#E00C3C" : "#f1f1f1",
      },

      "&:hover:after": {
        backgroundColor: "#e00c3c",
      },
    }),

    menu: (provided, state) => ({
      ...provided,
      border: "none",
      margin: 0,
      padding: 0,
      boxShadow: "none",
      borderRadius: 0,
      width: "auto",
      minWidth: "100%",

      "&:after": {
        position: "absolute",
        zIndex: 999,
        content: '""',
        top: "-8px",
        left: "0px",
        height: "8px",
        width: "100%",
        backgroundColor: "#F2F5F9",
        transition: "all .2s ease",
        opacity: 1,
      },

      "@media only screen and (max-width: 1200px)": {
        width: "100%",
      },

      "@media only screen and (min-width: 768px) and (max-width: 1280px)": {
        minWidth: "calc(100% + 100px)",
      },
    }),

    menuList: (provided, state) => ({
      ...provided,
      border: "none",
      margin: 0,
      padding: 0,
      maxHeight: props.maxHeight ?? "400px",
      boxShadow: "0px 10px 24px rgba(139, 134, 174, 0.3)",

      "&::-webkit-scrollbar": {
        width: 6,
        height: 8,
        backgroundColor: "transparent",
      },

      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#D8D8D8",
        borderRadius: 3,
      },
    }),

    control: (provided, state) => ({
      ...provided,
      // borderRadius: '10px',
      width: "100%",
      height: "100%",
      border: "none",
      minHeight: 60,
      backgroundColor: "#F2F5F9",
      display: "flex",
      alignItems: "center",
      outline: "none",
      margin: 0,
      padding: 0,
      cursor: "pointer",
      boxShadow: "none",

      "@media only screen and (min-width: 768px) and (max-width: 1280px)": {
        minHeight: 48,
      },

      "@media only screen and (max-width: 767px)": {
        backgroundColor: "#fff",
      },
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      margin: 0,
      padding: 0,
      height: "100%",
      display: "flex",
      alignItems: "center",
      overflow: "visible",
      paddingLeft: 20,

      "@media only screen and (max-width: 767px)": {
        paddingLeft: 0,
      },
    }),

    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "16px",
      lineHeight: "19px",
      color: "#505D6F",
      fontWeight: "500",
      margin: 0,
      padding: "0",
      opacity: state.isDisabled ? ".5" : "1",
      whiteSpace: "normal",
    }),

    indicatorsContainer: (provided, state) => ({
      ...provided,
    }),

    indicatorContainer: (provided, state) => ({
      ...provided,
      padding: 0,
    }),

    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),

    container: (provided, state) => ({
      ...provided,
      height: 60,
      width: "100%",
      overflow: "visible",
      backgroundColor: "#F2F5F9",
      borderRadius: !state.menuIsOpen ? "0px 0px 0px 10px" : 0,
      "@media only screen and (min-width: 768px) and (max-width: 1280px)": {
        height: 48,
      },
    }),

    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "#ff4763",
      "&:hover": { color: "#2E2382" },
    }),

    placeholder: (provided, state) => ({
      ...provided,
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "19px",
      color: "#94A2B6",
    }),
  };

  const MyOption = (params) => {
    const { innerProps, innerRef } = params;

    return (
      <div
        ref={innerRef}
        {...innerProps}
        className={cn("filter-customOption", {
          selected: params.isSelected,
          hovered: params.isFocused,
          focused: params.isFocused,
          disabled: params.isDisabled,
        })}>
        <div className="filter-customOption__label">{params.data.label}</div>
        {!props.isHideCount && <span>{params.data.count}</span>}
      </div>
    );
  };

  return (
    <Select
      classNamePrefix="select"
      className={`select-style ${props.name === "status" && "replica-status-filter"}`}
      instanceId="select-style"
      value={select}
      // isDisabled={!Object.keys(props.options).length}
      onChange={(active) => {
        const newValue = { ...active, label: cutMax(active.label, 30) };
        setSelect(newValue);

        props.onChange({
          target: {
            value: active.value,
          },
        });
      }}
      options={options}
      isSearchable={false}
      isOptionDisabled={(option) => option.disabled}
      styles={customStyles}
      components={{
        Option: MyOption,
      }}
    />
  );
}
