export const columns = [
  { key: "host", name: "Домен" },
  { key: "url", name: "URL" },
  { key: "scale", name: "Шкала" },
  { key: "mark", name: "Оценка" },
  { key: "header", name: "Заголовок" },
  { key: "description", name: "Описание" },
  { key: "tonality", name: "Тональность" },
];

export const headers = ["Домен", "URL", "Шкала", "Рейтинг", "Заголовок", "Описание", "Тональность"];

export const scales = [
  { name: "-", id: null, count: 1 },
  { name: "5 балльная", id: "scale5", count: 1 },
  { name: "10 балльная", id: "scale10", count: 1 },
];

export const scale5 = [
  { name: 1, id: "scale5-1", count: 1 },
  { name: 2, id: "scale5-2", count: 1 },
  { name: 3, id: "scale5-3", count: 1 },
  { name: 4, id: "scale5-4", count: 1 },
  { name: 5, id: "scale5-5", count: 1 },
];

export const scale10 = [
  { name: 1, id: "scale10-1", count: 1 },
  { name: 2, id: "scale10-2", count: 1 },
  { name: 3, id: "scale10-3", count: 1 },
  { name: 4, id: "scale10-4", count: 1 },
  { name: 5, id: "scale10-5", count: 1 },
  { name: 6, id: "scale10-6", count: 1 },
  { name: 7, id: "scale10-7", count: 1 },
  { name: 8, id: "scale10-8", count: 1 },
  { name: 9, id: "scale10-9", count: 1 },
  { name: 10, id: "scale10-10", count: 1 },
];

export const rating5 = [
  { name: "-", id: null, count: 1 },
  { name: 1, id: "rating-1", count: 1 },
  { name: 2, id: "rating-2", count: 1 },
  { name: 3, id: "rating-3", count: 1 },
  { name: 4, id: "rating-4", count: 1 },
  { name: 5, id: "rating-5", count: 1 },
];
export const rating10 = [
  { name: "-", id: null, count: 1 },
  { name: 1, id: "rating-1", count: 1 },
  { name: 2, id: "rating-2", count: 1 },
  { name: 3, id: "rating-3", count: 1 },
  { name: 4, id: "rating-4", count: 1 },
  { name: 5, id: "rating-5", count: 1 },
  { name: 6, id: "rating-6", count: 1 },
  { name: 7, id: "rating-7", count: 1 },
  { name: 8, id: "rating-8", count: 1 },
  { name: 9, id: "rating-9", count: 1 },
  { name: 10, id: "rating-10", count: 1 },
];


export const OperationStatus = {
  SUCCESS: 'success' as const,
  FAIL: 'fail' as const,
} as const;

export type OperationStatusType = typeof OperationStatus[keyof typeof OperationStatus];

export enum QueriesEnum {
  APPROVE_MANY = "approve-many",
  APPROVE_REPLICA = "approve-replica",
  CHANGE_REPLICA_STATUS = "change-replica-status",
  COMPANIES = "companies",
  CREATE_OR_UPDATE_PROJECT = "post-add-project",
  CREATE_REPLICA = "post-replica",
  DELETE_MANY = "delete-many",
  DELETE_ONE = "delete-one",
  EXPORT_REPLICA = "get-export_v3",
  FETCH_SEARCHING_QUERIES = "searching-queries",
  FETCH_SEARCHING_QUERY_RESULTS_BY_ID = "searching-query-results",
  GET_FILTERED_COMPANIES = "get-companies",
  GET_FILTERED_PROJECTS = "get-projects",
  GET_PROJECT_SETTINGS = "get-project",
  GET_REPLICA_CHILDREN = "get-childrens",
  GET_REPLICA_MESSAGE = "get-message",
  GET_REPLICA_STATUS = "get-status",
  IMPORT_V2 = "import-v2",
  PROJECTS = "projects",
  REJECT_MANY = "reject-many",
  REJECT_REPLICA = "reject-replica",
  REPAIR_REPLICA_HISTORY = "post-history-repair",
  REPLICA_MESSAGE = "replica-message",
  SEND_TELEGRAM_CODE = "send-telegram-code",
  SEND_TELEGRAM_NOTICE = "send-telegram-notice",
  SUBSCRIBE_TO_TELEGRAM = "subscribe-to-telegram",
  UPDATE_AGENT_NAME = "post-agent-name",
  UPDATE_NOTE_SYSTEM = "put-note-system",
  UPDATE_REPLICA_STATUS = "put-status",
  UPDATE_REPLICA_URL = "update-replica-url",
  UPLOAD_REPLICA_SCREENSHOT = "post-screenshot",
}