import React from "react";

function IconTelegram() {
  return (
    <svg width={26} height={22} viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.21785 9.55275C8.92852 6.61286 13.4036 4.67452 15.6431 3.73773C22.0376 1.06278 23.3649 0.597659 24.2315 0.583922C24.5497 0.57633 24.862 0.670473 25.1235 0.85279C25.3065 1.01321 25.4234 1.23642 25.4515 1.47884C25.4981 1.78064 25.5112 2.08672 25.4905 2.39143C25.1431 6.05157 23.644 14.9341 22.8808 19.0318C22.5587 20.7667 21.9244 21.3496 21.3076 21.4065C19.9705 21.5302 18.9555 20.5175 17.6614 19.6638C15.6353 18.3293 14.4895 17.505 12.524 16.194C10.25 14.6887 11.7237 13.8605 13.0197 12.5083C13.3594 12.1531 19.2483 6.76594 19.3635 6.27726C19.3782 6.20551 19.3762 6.13129 19.3576 6.06045C19.339 5.9896 19.3042 5.92409 19.2561 5.86906C19.1862 5.82367 19.1065 5.79573 19.0236 5.78753C18.9408 5.77934 18.8572 5.79114 18.7798 5.82195C18.5768 5.86905 15.3399 8.02 9.06907 12.2748C8.14907 12.9107 7.3169 13.2201 6.57257 13.2031C5.74886 13.1854 4.16782 12.736 2.99276 12.3513C1.55225 11.8803 0.406483 11.6311 0.50603 10.8304C0.55678 10.4117 1.12738 9.98582 2.21785 9.55275Z"
        fill="#0066FF"
      />
    </svg>
  );
}

export default IconTelegram;
