import axios from "axios";
import React, { useContext, useState } from "react";

import classes from "./ModalQueryAdd.module.scss";
import Table from "./Table/Table";

import config from "../../../../../config/config";
import { AnaliseContext } from "../../../../../context/analise";
import MobailModal from "../../../UI/MobailModal/MobailModal";
import IconAdd from "../../../icons/IconAdd";
import IconCancel from "../../../icons/IconCancel";

function ModalQueryAdd({ controlModal }) {
  const { state, actions } = useContext(AnaliseContext);

  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);

  const onSubmitFile = async (e) => {
    const file = e.currentTarget.files[0];

    if (file) {
      setIsLoading(true);

      try {
        const token = localStorage.getItem("token");

        const bodyFormData = new FormData();

        bodyFormData.append("token", token);
        bodyFormData.append("file", file);

        const { data } = await axios.post(
          `${config.SERVER_BASE_URL}/analysis/import-data`,
          bodyFormData,
        );

        if (data?.status === "fail") {
          alert(data?.text?.join("\n"));
          return;
        }

        if (data?.status === "success") {
          setList(data?.data);
        }

        setIsLoading(false);
      } catch (err) {
        console.log("onSubmitFile", err);
        setIsLoading(false);
      }
    }
  };

  const onSave = async (e) => {
    setIsLoading(true);

    try {
      const token = localStorage.getItem("token");

      const { data } = await axios.post(`${config.SERVER_BASE_URL}/analysis/queries`, {
        token: token,
        project: state.filter.project?.value,
        data: JSON.stringify(list),
      });

      setIsLoading(false);

      if (data?.status === "fail") {
        alert(data?.errorText?.split(",")?.join("\n"));
        return;
      }

      if (data?.status === "success") {
        controlModal.onCloseModal();
        actions.onGetData();
      }
    } catch (err) {
      console.log("onSave", err);
      setIsLoading(false);
    }
  };

  return (
    <MobailModal
      title="Добавление поиск. запр."
      icon={<IconAdd />}
      controlModal={controlModal}
      isIcon
      isCloseArrow
      isSmallTitle>
      <div className={classes.Wrap}>
        {/* <button className={classes.BtnHidden}>
                    <IconHidden />
                    Скрыть частотность
                </button> */}

        <Table list={list} setList={setList} />

        {list?.length > 0 && (
          <button className={classes.BtnDelete} onClick={controlModal.onCloseModal}>
            <IconCancel />
            Очистить поисковые запросы
          </button>
        )}

        {list?.length === 0 && (
          <div className={classes.AddBtn}>
            <input
              className={classes.AddInput}
              type="file"
              accept=".csv,.xlsx"
              name={"name"}
              id={"name"}
              onChange={onSubmitFile}
              placeholder={"Добавить поисковые запросы"}
            />

            <label className={classes.AddNewInput} htmlFor={"name"}>
              <span className={classes.AddLabel}>
                <IconAdd />
                {!isLoading ? "Добавить поисковые запросы" : "Загружаем файл"}
              </span>
            </label>
          </div>
        )}

        {list?.length > 0 && (
          <div className={classes.Btns}>
            <button className={classes.BtnSave} onClick={onSave}>
              Сохранить
            </button>

            <button className={classes.BtnCancel} onClick={controlModal.onCloseModal}>
              Отменить
            </button>
          </div>
        )}
      </div>
    </MobailModal>
  );
}

export default ModalQueryAdd;
