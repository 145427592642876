import cn from "classnames";
import React, { useContext } from "react";

import classes from "./ModalSize.module.scss";

import { AnaliseContext } from "../../../../../context/analise";
import MobailModal from "../../../UI/MobailModal/MobailModal";
import IconCheckedBig from "../../../icons/IconCheckedBig";

const arr = [
  {
    value: "top10",
    label: "ТОП 10",
  },
  {
    value: "top20",
    label: "ТОП 20",
  },
  {
    value: "top30",
    label: "ТОП 30",
  },
];

function ModalSize({ controlModalChildren }) {
  const { state, actions } = useContext(AnaliseContext);

  return (
    <MobailModal title="Глубина" controlModal={controlModalChildren} isIcon>
      <div className={classes.Wrap}>
        {arr.map((item, index) => (
          <button
            className={cn(classes.Btn, {
              [classes.Active]: item.value === state.filter?.deepLevel,
            })}
            key={index}
            onClick={() => {
              actions.onChangeFilter("deepLevel", item.value);
              controlModalChildren.onCloseModal();
            }}>
            {item.label}
            <IconCheckedBig />
          </button>
        ))}
      </div>
    </MobailModal>
  );
}

export default ModalSize;
