import React from "react";

import "./UserAddFields.scss";

import Select from "../../../../UI/Select/Select";

const UserAddFields = (props) => {
  return (
    <div className={props.cls}>
      <label htmlFor="">{props.label}:</label>

      <Select
        name={props.name}
        value={props.value}
        options={props.options}
        optionDefault={props.optionDefault}
        onChange={props.fieldChange}
      />
    </div>
  );
};

export default UserAddFields;
