import React, { useContext } from "react";

import classes from "./DesctopVersion.module.scss";
import TabScreenRating from "./TabScreenRating/TabScreenRating";
import TabScreenSearch from "./TabScreenSearch/TabScreenSearch";
import TabScreenTonality from "./TabScreenTonality/TabScreenTonality";
import { mockData } from "./mockData";

import { AnaliseContext } from "../../../context/analise";
import MenuDesktop from "../UI/MenuDesktop/MenuDesktop";
import Tabs from "../UI/Tabs/Tabs";
import TitleRow from "../UI/TitleRow/TitleRow";

const DesctopVersion = () => {
  const { state } = useContext(AnaliseContext);

  return (
    <div className={classes.Wrap}>
      <div className={classes.Menu}>
        <MenuDesktop />
      </div>

      <Tabs tabs={mockData.tabs} />
      <TitleRow />

      <div className={classes.Inner}>
        {state.activeTabScreen === "search" && <TabScreenSearch />}
        {state.activeTabScreen === "tonality" && <TabScreenTonality />}
        {state.activeTabScreen === "rating" && <TabScreenRating />}
      </div>
    </div>
  );
};

export default DesctopVersion;
