import React from "react";

import "./HistoryButton.styles.scss";
import { IHistoryButtonProps } from "./HistoryButton.types";

export const HistoryButton = ({ onClick }: IHistoryButtonProps) => {
  return (
    <button className="search-history-history-button" onClick={onClick}>
      История запросов
    </button>
  );
};
