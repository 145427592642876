import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";

import "./UserList.scss";

import { apiUserList } from "../../../../../api/users";
import config from "../../../../../config/config";
import { PopupContext } from "../../../../../context/popup/popupContext";
import UserAdd from "../UserAdd/UserAdd";
import UserItem from "../UserItem/UserItem";

const UserList = (props) => {
  const popupContextData = useContext(PopupContext);
  const [users, setUsers] = useState([]);
  const [userAdd, setUserAdd] = useState(false);

  const getUsers = useCallback(async () => {
    let id = false;
    let type = false;

    if (props.companyId) {
      id = props.companyId;
      type = "company";
    } else {
      id = props.roleId;
      type = "role";
    }

    popupContextData.setPreloader(true);
    const data = await apiUserList(id, type, props.filter);

    if (data.status === "success") {
      setUsers(Object.values(data.data));
    } else {
      setUsers(undefined);
    }

    popupContextData.setPreloader(false);
  });

  const onToogleIsRoleEditor = async (id, value) => {
    const onlyView = !value;

    const { data } = await axios.put(`${config.SERVER_BASE_URL}/users/only-view/${id}`, {
      token: localStorage.getItem("token"),
      onlyView: onlyView ? "Y" : "N",
    });

    if (data.status === "success") {
      const newArr = users.map((item) => {
        if (item._id === id) {
          return { ...item, onlyView };
        }

        return item;
      });

      setUsers(newArr);
    }
  };

  const onToggleNotification = async (user, value, type) => {
    const notifyByEmail = !value;
    //
    const options = {
      notifyReplicaDelete: user.notifyReplicaDelete ? "Y" : "N",
      notifyReplicaAdd: user.notifyReplicaAdd ? "Y" : "N",
      notifyReplicaStatusChange: user.notifyReplicaStatusChange ? "Y" : "N",
      [type]: notifyByEmail ? "Y" : "N",
    };

    const { data } = await axios.put(`${config.SERVER_BASE_URL}/users/notify/${user._id}`, {
      ...{
        token: localStorage.getItem("token"),
      },
      ...options,
    });

    if (data.status === "success") {
      const newArr = users.map((item) => {
        if (item._id === user._id) {
          return { ...item, [type]: notifyByEmail };
        }

        return item;
      });

      setUsers(newArr);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="admin-company__project-item-content">
      <div className="admin-company__users">
        <div className="admin-company__users-title">ПОЛЬЗОВАТЕЛИ</div>

        <div className="admin-company__users-button-add" onClick={() => setUserAdd(true)} />

        {userAdd ? (
          <UserAdd
            companyId={props.companyId}
            roleId={props.roleId}
            getUsers={getUsers}
            close={() => setUserAdd(false)}
          />
        ) : null}

        {users?.length && (
          <>
            <div className="admin-company__users-top-side">
              <div className="admin-company__users-top-side-cell admin-company__users-top-side-cell_311px">
                Пользователь:
              </div>

              <div className="admin-company__users-top-side-cell">Роль</div>
              <div className="admin-company__users-top-side-cell">Эл. почта:</div>
              <div className="admin-company__users-top-side-cell">Добавление:</div>
              <div className="admin-company__users-top-side-cell">Изменение:</div>
              <div className="admin-company__users-top-side-cell">Удаление:</div>
              <div className="admin-company__users-top-side-cell">Редактор:</div>

              {/*<div className='admin-company__users-top-side-cell admin-company__users-top-side-cell_mobile'>*/}
              {/*    Изменить:*/}
              {/*</div>*/}

              <div className="admin-company__users-top-side-cell">Статус:</div>
            </div>

            <div className="admin-company__users-content">
              {users.map((user, index) => (
                <UserItem
                  key={index}
                  id={user._id}
                  name={user.name}
                  lastName={user.lastName}
                  email={user.email}
                  role={user.role}
                  active={user.active}
                  onlyView={user.onlyView}
                  notifyReplicaDelete={user.notifyReplicaDelete}
                  notifyReplicaAdd={user.notifyReplicaAdd}
                  notifyReplicaStatusChange={user.notifyReplicaStatusChange}
                  getUsers={getUsers}
                  onToogleIsRoleEditor={() => onToogleIsRoleEditor(user._id, user.onlyView)}
                  onToggleNotification={(value, type) => onToggleNotification(user, value, type)}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UserList;
