import axios from "axios";
import cn from "classnames";
import React, { useContext, useRef, useState } from "react";

import "./UploadFile.scss";

import config from "../../../../config/config";
import { PopupContext } from "../../../../context/popup/popupContext";
import Select from "../../../UI/Select/Select";

const UploadFile = (props) => {
  const inputFile = useRef(null);

  const popupContextData = useContext(PopupContext);

  const [inputs, setInputs] = useState({
    company: {
      name: "company",
      value: "",
      options: props.companies.options,
      optionDefault: "Выберите компанию",
    },
    project: {
      name: "project",
      value: "",
      options: [],
      optionDefault: "Выберите проект",
    },
  });

  const [fileName, setFileName] = useState("");
  const [fileNameExists, setfileNameExists] = useState(false);

  const handleReset = () => {
    if (inputFile.current) {
      inputFile.current.value = "";
      inputFile.current.type = "text";
      inputFile.current.type = "file";
    }
  };

  const addFileName = (event) => {
    setFileName(event.target.value.replace("C:\\fakepath\\", ""));
    setfileNameExists(true);
  };

  const uploadFileContainerClasses = cn({
    "search-and-settings__upload-file-container": true,
    "search-and-settings__upload-file-container_selected": fileNameExists,
  });

  const removeFileName = () => {
    handleReset();
    setFileName("");
    setfileNameExists(false);
  };

  const getProjects = async (companyId) => {
    const projects = [];

    if (companyId) {
      popupContextData.setPreloader(true);

      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${config.SERVER_BASE_URL}/replica/projects?token=${token}&companyId=${companyId}`,
      );
      const responseData = response.data;

      if (responseData.status === "success" && responseData.data.length) {
        Object.keys(responseData.data).map((idx) => {
          return projects.push({
            id: responseData.data[idx].id,
            name: responseData.data[idx].name,
          });
        });
      }

      popupContextData.setPreloader(false);
    }

    return projects;
  };

  const selectChange = async (event, name) => {
    const inputsCopy = { ...inputs };
    inputsCopy[name].value = event.target.value;

    if (name === "company") {
      inputsCopy.project.value = "";
      inputsCopy.project.options = await getProjects(event.target.value);
    }

    setInputs(inputsCopy);
  };

  const start = async () => {
    popupContextData.setPreloader(true);

    const token = localStorage.getItem("token");
    const file = document.getElementById("uploadInput").files[0];
    const formData = new FormData();

    formData.append("token", token);
    formData.append("companyId", inputs.company.value);
    formData.append("projectId", inputs.project.value);
    formData.append("fileXls", file);

    const response = await axios.post(`${config.SERVER_BASE_URL}/replica/import-v2`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const responseData = response.data;

    if (responseData.status === "success") {
      props.close();
      props.updateReplicaList();
    } else {
      popupContextData.setPreloader(false);
      alert(responseData.errorText);
    }
  };

  return (
    <>
      <div className="search-and-settings__contain-block">
        <div className="search-and-settings__title-bar search-and-settings__title-bar_upload">
          Загрузка данных
        </div>

        <div className="search-and-settings__contain-block-container">
          <div className="search-and-settings__top-select">
            <Select
              name={inputs.company.name}
              value={inputs.company.value}
              options={inputs.company.options}
              optionDefault={inputs.company.optionDefault}
              onChange={(event) => selectChange(event, inputs.company.name)}
            />
          </div>

          {inputs.company.value ? (
            <div className="search-and-settings__top-select">
              <Select
                name={inputs.project.name}
                value={inputs.project.value}
                options={inputs.project.options}
                optionDefault={inputs.project.optionDefault}
                onChange={(event) => selectChange(event, inputs.project.name)}
              />
            </div>
          ) : null}

          {inputs.company.value && inputs.project.value ? (
            <div className={uploadFileContainerClasses}>
              <div className="search-and-settings__upload-file-blocks">
                <div className="search-and-settings__upload-file-select">
                  <input
                    ref={inputFile}
                    onChange={(event) => addFileName(event)}
                    name="fileXls"
                    className="search-and-settings__upload-file-select-input"
                    type="file"
                    id="uploadInput"
                  />
                  <label
                    htmlFor="uploadInput"
                    className="search-and-settings__upload-file-select-text">
                    Выберите файл для загрузки
                  </label>
                </div>

                <div className="search-and-settings__upload-file-loading-progress">
                  <div className="search-and-settings__upload-file-loading-process-icon" />
                  <div className="search-and-settings__upload-file-loading-process-bar" />
                </div>

                <div className="search-and-settings__upload-file-loading-complete">
                  <div className="search-and-settings__upload-file-loading-complete-icon" />
                  <div className="search-and-settings__upload-file-loading-complete-text">
                    {fileName}
                  </div>
                  <div
                    onClick={removeFileName}
                    className="search-and-settings__upload-file-loading-complete-delete"
                  />
                </div>
              </div>

              <div className="search-and-settings__upload-file-buttons-test">
                <button className="search-and-settings__upload-file-button-upload" onClick={start}>
                  Загрузить
                </button>
                <button
                  className="search-and-settings__upload-file-button-cancel"
                  onClick={props.close}>
                  Отменить
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default UploadFile;
