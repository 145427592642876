import React from "react";

import Input from "../../../../UI/Input/Input";
import Select from "../../../../UI/Select/Select";
import Textarea from "../../../../UI/Textarea/Textarea";

const ReplicaAddCustom = (props) => {
  return (
    <>
      <div className="add-edit-replic__additional-data">
        <div className="add-edit-replic__additional-data-content">
          {Object.keys(props.custom).length
            ? Object.keys(props.custom).map((fieldId, index) => {
                const field = props.custom[fieldId];

                return (
                  <div key={index} className="add-edit-replic__additional-data-unit">
                    <div className="add-edit-replic__additional-data-unit-left-side">
                      <div className="add-edit-replic__additional-data-unit-left-side-text">
                        {field.fieldType === "note" ? (
                          <>
                            Примечание
                            <span className="icon" data-icon={field.label} />
                          </>
                        ) : (
                          field.label
                        )}
                      </div>
                    </div>

                    <div className="add-edit-replic__additional-data-unit-right-side">
                      <div className="add-edit-replic__additional-data-unit-right-side-content">
                        {field.date ? (
                          <div className="add-edit-replic__additional-data-unit-right-side-date">
                            {field.date}
                          </div>
                        ) : null}

                        <div className="add-edit-replic__additional-data-unit-right-side-type-mobile">
                          {field.fieldType === "note" ? (
                            <>
                              Примечание
                              <span className="icon" data-icon={field.label} />
                            </>
                          ) : (
                            field.label
                          )}
                        </div>

                        {field.fieldType === "text" ? (
                          <Textarea
                            name={field.name}
                            value={field.value}
                            onChange={(event) => props.fieldCustomChange(event, field.name)}
                          />
                        ) : null}

                        {field.fieldType === "url" ? (
                          <Input
                            type="text"
                            name={field.name}
                            value={field.value}
                            onChange={(event) => props.fieldCustomChange(event, field.name)}
                          />
                        ) : null}

                        {field.fieldType === "select" ? (
                          <Select
                            name={field.name}
                            value={field.value}
                            onChange={(event) => props.selectCustomChange(event, field.name)}
                            optionDefault="Выберите значение"
                            options={field.options}
                          />
                        ) : null}

                        {field.fieldType === "note" ? (
                          <Textarea
                            name={field.name}
                            value={field.value}
                            onChange={(event) => props.fieldCustomChange(event, field.name)}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })
            : null}

          <div className="add-edit-replic__additional-data-buttons">
            {props.id ? (
              <div className="add-edit-replic__additional-data-button add-edit-replic__additional-data-button_blue">
                <button
                  onClick={(e) => {
                    // props.save(e)
                    props.public(e);
                  }}>
                  Сохранить
                </button>
              </div>
            ) : null}

            {props?.accessLevel !== 2 && (
              <div className="add-edit-replic__additional-data-button add-edit-replic__additional-data-button_red">
                <button onClick={props.public}>Отправить на согласование</button>
              </div>
            )}

            <div className="add-edit-replic__additional-data-button add-edit-replic__additional-data-button_gray">
              <button onClick={props.close}>Отменить</button>
            </div>
          </div>
        </div>
      </div>

      {/* <div className={classes.Control + ' add-edit-replic__additional-data-buttons '}>
                {props.id ? (
                    <div className='add-edit-replic__additional-data-button add-edit-replic__additional-data-button_blue'>
                        <button
                            onClick={(e) => {
                                props.save(e)
                            }}
                        >
                            Сохранить
                        </button>
                    </div>
                ) : null}

                {props?.accessLevel !== 2 && (
                    <div className='add-edit-replic__additional-data-button add-edit-replic__additional-data-button_red'>
                        <button onClick={props.public}>Отправить на согласование</button>
                    </div>
                )}

                <div className='add-edit-replic__additional-data-button add-edit-replic__additional-data-button_gray'>
                    <button onClick={props.close}>Отменить</button>
                </div>
            </div> */}
    </>
  );
};

export default ReplicaAddCustom;
