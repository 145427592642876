export enum ROLE_IDS {
  ADMINISTRATOR = "5f104827f249f12de0d7330a",
  BRAND_MANAGER = "5f104854f249f12de0d7330b",
  ANALYST = "5f10485bf249f12de0d7330c",
  PRODUCTION_MANAGER = "5f104863f249f12de0d7330d",
  PROJECT_MANAGER = "5f10486bf249f12de0d7330e"
}

export enum ROLE_ACCESS_LEVELS {
  ADMINISTRATOR = 1,
  BRAND_MANAGER = 2,
  ANALYST = 3,
  PRODUCTION_MANAGER = 4,
  PROJECT_MANAGER = 5
}
