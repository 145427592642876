import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";

import "./SearchHistoryInput.styles.scss";
import { ISearchHistoryInputProps } from "./SearchHistoryInput.types";

import { IconClear } from "../../../icons/IconClear";
import { IconSearch } from "../../../icons/IconSearch";
import Input from "../../UI/Input/Input";

export const SearchHistoryInput = ({
  value,
  setValue,
  placeholder,
  handleOnFetchingQueries,
}: ISearchHistoryInputProps) => {
  const [isClearClicked, setIsClearClicked] = useState<boolean>(false);
  const [isClearInputByKeyboard, setIsClearInputByKeyboard] = useState<boolean>(false);

  const handleOnKeyPress = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      await handleOnFetchingQueries(1);
    }
  };

  const handleOnChangeInputValue = async (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);

    if (!event.target.value) {
      setIsClearInputByKeyboard(true);
    }
  };

  const handleOnResetValue = async () => {
    setValue("");
    setIsClearClicked(true);
  };

  useEffect(() => {
    (async () => {
      if (!value && isClearClicked) {
        await handleOnFetchingQueries(1);
        setIsClearClicked(false);
      }
    })();
  }, [isClearClicked]);

  useEffect(() => {
    (async () => {
      if (isClearInputByKeyboard) {
        await handleOnFetchingQueries(1);
        setIsClearInputByKeyboard(false);
      }
    })();
  }, [isClearInputByKeyboard]);

  return (
    <div className="search-history-input-wrapper">
      <div className="search-history-input-icon-search">
        <IconSearch color="#2E2382" />
      </div>
      <Input
        value={value}
        onChange={handleOnChangeInputValue}
        onKeyPress={handleOnKeyPress}
        placeholder={placeholder}
        className="search-history-input"
      />
      <div className="search-history-input-icon-clear" onClick={handleOnResetValue}>
        <div className="search-history-input-icon-clear-wrapper">
          {value && <IconClear color="red" />}
        </div>
      </div>
    </div>
  );
};
