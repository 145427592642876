import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { fetchSearchingQueryResultsById } from "../../api/searching-query-results";
import { ISearchingQueryResults } from "../../api/searching-query-results/searching-query-results.types";
import { BackButton } from "../../components/SearchHistory";
import { SearchHistoryResultsTable } from "../../components/SearchHistoryResults";

export const SearchHistoryResults = () => {
  const history = useHistory();
  const params = useParams() as { id: string };

  const [searchingQueryResults, setSearchingQueryResults] = useState<
    ISearchingQueryResults[] | null
  >(null);

  const handleOnClickBackButton = () => {
    history.push("/search/history");
  };

  useEffect(() => {
    (async () => {
      const results = await fetchSearchingQueryResultsById(params.id);

      setSearchingQueryResults(results);
    })();
  }, []);

  if (searchingQueryResults?.length) {
    return (
      <div className="search-history">
        <div className="search-history-wrapper">
          <div>
            <BackButton onClick={handleOnClickBackButton} />
          </div>
          <div className="search-history-title">
            Запрос: {searchingQueryResults[0].searchingQuery.brandName}
          </div>
          <SearchHistoryResultsTable tableRowData={searchingQueryResults} />
        </div>
      </div>
    );
  }

  return null;
};
