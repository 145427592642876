import React, { useState } from "react";

import "./Dropdown.scss";
import { RatingInput } from "./RatingInput/RatingInput";
import SelectDropdown from "./ScaleDropdown";
import { tonality } from "./Search";
import classes from "./Search.module.scss";

import SelectWithCounter from "../../components/UI/SelectWithCounter/SelectWithCounter";

const Row = ({ data, result, rowIndex, onTapUrl, onChangeRating, onChangeTonality, setResult }) => {
  const [scale, setScale] = useState(null);
  const [rating, setRating] = useState("");

  const handleScaleChange = (value) => {
    const arrayToUpdate = result.slice();

    arrayToUpdate[rowIndex].scale = value;
    arrayToUpdate[rowIndex].rating = "-";

    setResult(arrayToUpdate);
    setScale(value);
    setRating("-");
  };

  const handleRatingChange = (event) => {
    let value = event.target.value;

    const regExpScale5 = /^\d{1}(,\d{0,2})?$/;
    const regExpScale10 = /^\d{1,2}(,\d{0,2})?$/;

    const regExp = scale?.id === "scale5" ? regExpScale5 : regExpScale10;

    if (!value || value.match(regExp)) {
      if (scale?.id === "scale5") {
        if (Number(value.replace(/,/, ".")) >= 0 && Number(value.replace(/,/, ".")) <= 5) {
          setRating(value);
          onChangeRating({ name: value, scale: scale?.id }, rowIndex, scale);
        }
      } else {
        if (Number(value.replace(/,/, ".")) >= 0 && Number(value.replace(/,/, ".")) <= 10) {
          setRating(value);
          onChangeRating({ name: value, scale: scale?.id }, rowIndex, scale);
        }
      }
    }
  };

  const handleRatingBlur = () => {
    if (scale?.id === "scale10" && rating[0] === "0" && rating[2] === ",") {
      setRating(rating.slice(1));
      onChangeRating({ name: rating.slice(1), scale: scale?.id }, rowIndex, scale);
    }
  };

  return (
    <tr className={classes.TableRow}>
      {Object.entries(data).map(([key, value]) => {
        if (key === "scale") {
          return (
            <td style={{ verticalAlign: "middle", height: 150 }}>
              <div style={{ display: "flex", width: 150 }}>
                <SelectDropdown onChangeValue={handleScaleChange} />
              </div>
            </td>
          );
        }

        if (key === "rating") {
          return (
            <td style={{ alignContent: "center", width: 50 }}>
              <RatingInput
                value={rating}
                onChange={handleRatingChange}
                disabled={!scale?.id}
                onBlur={handleRatingBlur}
              />
            </td>
          );
        }

        if (key === "tonality") {
          return (
            <td
              style={{ alignContent: "center", padding: "0 10px 0 10px", maxWidth: "70px" }}
              key={`${value}-${Math.random()}`}>
              <SelectWithCounter
                optionDefault={tonality.find((item) => item.id === result[rowIndex].tonality).name}
                isHideCount
                options={tonality.filter((obj) => obj.id !== result[rowIndex].tonality)}
                onChange={(event) => onChangeTonality(event, rowIndex)}
              />
            </td>
          );
        }

        if (key === "url") {
          return (
            <td
              className={classes.TableCell}
              style={{
                alignContent: "center",
                padding: 10,
                maxWidth: 200,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              key={`${value}-${Math.random()}`}>
              <a
                href={value}
                style={{ color: "orange" }}
                onClick={(event) => onTapUrl(event, value)}
                target="_blank"
                rel="noopener noreferrer">
                {value}
              </a>
            </td>
          );
        }
        return (
          <td
            className={classes.TableCell}
            style={{
              alignContent: "center",
              padding: 10,
              maxWidth: 200,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            key={`${value}-${Math.random()}`}>
            {value}
          </td>
        );
      })}{" "}
    </tr>
  );
};

export default Row;
