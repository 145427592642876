import React from "react";

import { tableHeader } from "./TableHeader.data";
import { ITableHeaderData } from "./TableHeader.types";

export const TableHeader = () => {
  return (
    <thead>
      <tr>
        {tableHeader.map((item: ITableHeaderData) => (
          <th key={`search-history-table-${item.id}`}>{item.name}</th>
        ))}
      </tr>
    </thead>
  );
};
