import { ITableHeaderData } from "./TableHeader.types";

export const tableHeader: ITableHeaderData[] = [
  {
    id: 1,
    name: "Дата",
  },
  {
    id: 2,
    name: "Фраза",
  },
  {
    id: 3,
    name: "Система",
  },
  {
    id: 4,
    name: "Регион",
  },
  {
    id: 5,
    name: "Глубина",
  },
  {
    id: 6,
    name: "Действия",
  },
];
