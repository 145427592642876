import { saveAs } from "file-saver";
import moment from "moment";
import React, { SyntheticEvent } from "react";
import { useHistory } from "react-router-dom";
import * as XLSX from "xlsx";

import { ITableRowProps } from "./TableRow.types";

import { fetchSearchingQueryResultsById } from "../../../../api/searching-query-results";
import { IconsCsv } from "../../../../icons/IconCsv";
import { IconRepeat } from "../../../../icons/IconRepeat";
import { IconXls } from "../../../../icons/IconXls";
import { tableHeader } from "../TableHeader/TableHeader.data";

export const TableRow = ({ tableRowData }: ITableRowProps) => {
  const history = useHistory();

  const date = moment(tableRowData.createdDate).format("DD-MM-YYYY");

  const handleOnRepeatRequest = (e: SyntheticEvent) => {
    e.stopPropagation();
    history.push("/search", { state: tableRowData });
  };

  const handleOnOpenResults = () => {
    if (tableRowData.isResults) {
      history.push(`/search/history/${tableRowData._id}`);
    }
  };

  const handleOnSaveToXLS = async (e: SyntheticEvent) => {
    e.stopPropagation();

    const result = await fetchSearchingQueryResultsById(tableRowData._id);

    if (result) {
      const list = [
        {
          host: "Домен",
          url: "URL",
          scale: "Шкала",
          rating: "Рейтинг",
          header: "Заголовок",
          description: "Описание",
          tonality: "Тональность",
        },
      ];

      result.forEach((x) => {
        list.push({
          host: x.host,
          url: x.url,
          scale: x.scale,
          rating: x.rating,
          header: x.header,
          description: x.description,
          tonality: x.tonality,
        });
      });

      const worksheet = XLSX.utils.json_to_sheet(list, { skipHeader: true });
      const workbook = XLSX.utils.book_new();
      worksheet["!cols"] = [
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 70 },
        { wpx: 70 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 100 },
      ];
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
      saveAs(blob, `${tableRowData.brandName}_${tableRowData.regionName}.xlsx`);
    }
  };

  const handleOnSaveToCSV = async (e: SyntheticEvent) => {
    e.stopPropagation();

    const result = await fetchSearchingQueryResultsById(tableRowData._id);

    if (result) {
      const top30 = result.length > 30 ? result.slice(0, 30) : result;

      let value = "";

      value += "Домен,URL,Шкала,Рейтинг,Заголовок,Описание,Тональность\n";

      top30.forEach((x) => {
        value += `"${x.host}",`;
        value += `"${x.url}",`;
        value += `"${x.scale}",`;
        value += `"${x.rating}",`;
        value += `"${x.header}",`;
        value += `"${x.description}",`;
        value += `"${x.tonality}"\n`;
      });

      const blob = new Blob([value], { type: "text/csv" });

      window.open(URL.createObjectURL(blob));
    }
  };

  return (
    <tr onClick={handleOnOpenResults}>
      <td className="white-space" style={{ verticalAlign: "middle" }}>
        <span className="cell-header">{tableHeader[0].name}</span> {date}
      </td>
      <td style={{ verticalAlign: "middle" }}>
        <span className="cell-header">{tableHeader[1].name}</span> {tableRowData.brandName}
      </td>
      <td className="white-space" style={{ verticalAlign: "middle" }}>
        <span className="cell-header">{tableHeader[2].name}</span> {tableRowData.findSystemName}
      </td>
      <td style={{ verticalAlign: "middle" }}>
        <span className="cell-header">{tableHeader[3].name}</span>{" "}
        {tableRowData.regionName.replace(/,/g, ", ")}
      </td>
      <td style={{ verticalAlign: "middle" }}>
        <span className="cell-header">{tableHeader[4].name}</span> {tableRowData.deep}
      </td>
      <td style={{ verticalAlign: "middle" }}>
        <span className="cell-header">{tableHeader[5].name}</span>
        <div style={{ display: "flex", gap: "20px" }}>
          {tableRowData.isResults && (
            <>
              <div onClick={handleOnSaveToXLS}>
                <IconXls />
              </div>
              <div onClick={handleOnSaveToCSV}>
                <IconsCsv />
              </div>
            </>
          )}
          <div onClick={handleOnRepeatRequest}>
            <IconRepeat />
          </div>
        </div>
      </td>
    </tr>
  );
};
