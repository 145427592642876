import cn from "classnames";
import React from "react";

import classes from "./AddRow.module.scss";

function AddRow({ onClick, isMini, className, disabled }) {
  return (
    <div
      className={cn(classes.AddRow, className, {
        [classes.Mini]: isMini,
        [classes.Disabled]: disabled,
      })}
      onClick={onClick}
    />
  );
}

export default AddRow;
