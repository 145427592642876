import cn from "classnames";
import React, { forwardRef } from "react";

import classes from "./TextField.module.scss";

export const TextField = forwardRef(({ border, label, fullWidth, ...rest }, ref) => {
  return (
    <div className={cn(classes.Field, { [classes.FullWidth]: fullWidth })}>
      <span className={classes.FieldTitle}>{label}</span>
      <input
        ref={ref}
        type="text"
        {...rest}
        className={cn(classes.FieldInput, {
          [classes.FieldInputBorderAll]: border === "all",
          [classes.FieldInputBorderLeft]: border === "left",
          [classes.FieldInputBorderRight]: border === "right",
        })}
      />
    </div>
  );
});
