import React from "react";

import { ITableRowProps } from "./TableRow.types";

import { tableHeader } from "../TableHeader/TableHeader.data";

export const TableRow = ({ tableRowData }: ITableRowProps) => {
  return (
    <tr style={{ cursor: "default" }}>
      <td className="white-space">
        <span className="cell-header">{tableHeader[0].name}</span> {tableRowData.host}
      </td>
      <td className="white-space">
        <span className="cell-header">{tableHeader[1].name}</span>
        <a href={tableRowData.url} target="_blank" rel="noreferrer noopener">
          Ссылка
        </a>
      </td>
      <td className="white-space">
        <span className="cell-header">{tableHeader[2].name}</span> {tableRowData.scale || "-"}
      </td>
      <td>
        <span className="cell-header">{tableHeader[3].name}</span> {tableRowData.rating || "-"}
      </td>
      <td>
        <span className="cell-header">{tableHeader[4].name}</span> {tableRowData.header || "-"}
      </td>
      <td>
        <span className="cell-header">{tableHeader[5].name}</span> {tableRowData.description || "-"}
      </td>
      <td className="white-space">
        <span className="cell-header">{tableHeader[6].name}</span> {tableRowData.tonality || "-"}
      </td>
    </tr>
  );
};
