import React, { useContext, useState } from "react";

import "./CompanyDelete.scss";

import { apiCompanyDelete } from "../../../../../api/companies";
import { PopupContext } from "../../../../../context/popup/popupContext";
import Input from "../../../../UI/Input/Input";

const CompanyDelete = (props) => {
  const popupContextData = useContext(PopupContext);
  const [msg, setMsg] = useState("");
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  const companyDelete = async (event, companyId) => {
    event.preventDefault();

    if (!msg || msg.length < 5) {
      alert("Укажите причину удаления");
    } else {
      popupContextData.setPreloader(true);
      const data = await apiCompanyDelete(companyId, msg);

      if (data.status === "fail") {
        popupContextData.setPreloader(false);
        alert(data.errorText);
      } else {
        props.close();
        props.getCompanies();
      }
    }
  };

  return (
    <div className="admin-company__company-manipulation admin-company__company-manipulation_removing">
      <div className="admin-company__company-manipulation-top-side">
        <div className="admin-company__company-manipulation-top-side-name">Удаление компании</div>

        <div
          className="admin-company__company-manipulation-top-side-button"
          onClick={props.close}
        />
      </div>

      {!openDeleteConfirm ? (
        <div className="admin-company__company-manipulation-content">
          <div className="admin-company__company-manipulation-text">
            Вы действительно хотите удалить компанию?
          </div>

          <div className="admin-company__company-manipulation-buttons">
            <div className="admin-company__company-manipulation-button admin-company__company-manipulation-button_mobile-width-71px admin-company__company-manipulation-button_blue">
              <button onClick={props.close}>Нет</button>
            </div>

            <div className="admin-company__company-manipulation-button admin-company__company-manipulation-button_mobile-width-71px admin-company__company-manipulation-button_red">
              <button onClick={() => setOpenDeleteConfirm(true)}>Да</button>
            </div>
          </div>
        </div>
      ) : null}

      {openDeleteConfirm ? (
        <div className="admin-company__company-manipulation-reason-content">
          <div className="admin-company__company-manipulation-reason-text">
            <span>Укажите причину!</span>
            <br /> Без указания причины компания не может быть удалена!
          </div>

          <div className="admin-company__company-manipulation-reason-field">
            <Input
              type="text"
              name="msg"
              value={msg}
              onChange={(event) => setMsg(event.target.value)}
              className="admin-company__company-manipulation-reason-input"
            />

            <button
              className="admin-company__company-manipulation-reason-button"
              onClick={(event) => companyDelete(event, props.id)}>
              Подтвердить удаление
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CompanyDelete;
