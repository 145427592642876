import React from "react";

import "./ProjectSettingsFieldSelect.scss";

import Input from "../../../../UI/Input/Input";
import ProjectSettingsFieldSelectItem from "../ProjectSettingsFieldSelectItem/ProjectSettingsFieldSelectItem";

const ProjectSettingsFieldSelect = (props) => {
  return (
    <div className="search-and-settings__fields-unit">
      <div className="search-and-settings__field-checkbox">
        <input
          type="checkbox"
          id={`in-replica-${props.id}`}
          value="Y"
          onChange={props.changeCheckbox}
          checked={props.viewReplica}
        />

        <label htmlFor={`in-replica-${props.id}`} />
      </div>

      <div className="search-and-settings__field-type">{props.fieldTypeName}</div>

      <div className="search-and-settings__field-value">
        <div className="search-and-settings__field-value-type-mobile">{props.fieldTypeName}</div>

        <div className="search-and-settings__field-input-text">
          <Input
            type="text"
            name={props.name}
            value={props.value}
            placeholder={props.placeholder}
            onChange={props.change}
            disabled={props.disabled}
          />

          <div
            className="search-and-settings__field-button-remove search-and-settings__field-button-remove_alone"
            onClick={props.delete}
          />
        </div>

        {props.options.length ? (
          props.options.map((option, index) => {
            if (option.deleted) {
              return true;
            } else {
              return (
                <ProjectSettingsFieldSelectItem
                  key={index}
                  id={index + 1}
                  name={option.name}
                  value={option.value}
                  selectAddField={props.selectAddField}
                  selectChangeField={(event) => props.selectChangeField(event, props.id, option.id)}
                  selectDeleteField={() => props.selectDeleteField(props.id, option.id)}
                />
              );
            }
          })
        ) : (
          <div className="search-and-settings__field-value_wrapper-btn">
            <button onClick={props.selectAddField}></button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectSettingsFieldSelect;
